import { namedPaymentRoute } from '@/modules/product/features/Payment/route.js';

const transportationDetailsRoutes = {
  path: ':id',
  component: () =>
    import(
      /* webpackChunkName: "product-transportation" */ '@/modules/product/transportation/TransportationProduct.vue'
    ),
  children: [
    {
      path: '',
      name: 'transportation-details',
      component: () =>
        import(
          /* webpackChunkName: "product-transportation" */ '@/modules/product/transportation/TransportationProductDetailsPage.vue'
        ),
    },
    {
      path: 'checkout',
      component: () =>
        import(
          /* webpackChunkName: "product-transportation" */ '@/modules/product/transportation/TransportationProductCheckoutPage.vue'
        ),
      children: [
        {
          path: 'contact',
          name: 'transportation-checkout',
          component: () =>
            import(
              /* webpackChunkName: "product-transportation-flow" */ '@/modules/product/transportation/ContactPage.vue'
            ),
        },
        namedPaymentRoute('transportation-payment', {
          fallbackPathName: 'transportation-details',
          storeModuleNameFn: (id) => `transportation/${id}`,
          discoverMoreCtaText: 'Book another ride',
          requiresPoll: true,
        }),
        {
          path: '',
          redirect: 'transportation-checkout', // default child path
        },
      ],
    },
    {
      path: '*',
      redirect: '/transportation/:id',
    },
  ],
};

const transportationRoute = {
  path: '/transportation',
  props: (route) => ({
    passengers: +route.query.passengers,
    startLocation: route.query['start-location'],
    endLocation: route.query['end-location'],
    startTime: route.query['start-time'],
    polling: !!route.query['polling'],
    minPrice: +route.query['min-price'],
    maxPrice: +route.query['max-price'],
    currency: route.query['currency'],
    vehicleSize: route.query['vehicle-size'],
  }),
  component: () =>
    import(
      /* webpackChunkName: "Transportation" */ '@/modules/product/transportation/TransportationPage.vue'
    ),
  children: [
    {
      path: '',
      name: 'transportationHome',
      meta: {
        showStagesFooter: false,
      },
      component: () =>
        import(
          /* webpackChunkName: "Transportation" */ '@/modules/product/transportation/TransportationLandingPage.vue'
        ),
    },
    {
      path: 'search',
      name: 'transportationSearchResults',
      meta: {
        showStagesFooter: false,
      },
      props: (route) => ({
        passengers: +route.query.passengers,
        startLocation: route.query['start-location'],
        endLocation: route.query['end-location'],
        startTime: route.query['start-time'],
        polling: !!route.query['polling'],
        minPrice: +route.query['min-price'],
        maxPrice: +route.query['max-price'],
        currency: route.query['currency'],
        vehicleSize: route.query['vehicle-size'],
      }),
      component: () =>
        import(
          /* webpackChunkName: "Transportation" */ '@/modules/product/transportation/TransportationResultsPage.vue'
        ),
    },
    transportationDetailsRoutes,
  ],
};

export default transportationRoute;
