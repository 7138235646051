<script>
import { RepoFactory } from '@/core/repositories';

export default {
  name: 'FlightsSearchMixins',
  data() {
    return {
      fligtsSearchLoading: false,
      fligtsSearchResults: null,
      fligtsSearchError: false,
    };
  },
  methods: {
    async searchForFlight({
      flightNumber,
      departureDate,
      withFlightPreorderAvailability,
      searchWithCodeShareFlightNumber,
    }) {
      const flightRepo = RepoFactory.get('flight');
      this.fligtsSearchError = null;
      this.fligtsSearchLoading = true;
      try {
        const response = await flightRepo.getFlights({
          flightNumber,
          departureDate,
          withFlightPreorderAvailability,
          searchWithCodeShareFlightNumber,
        });
        if (response.data.errorCode || !response.data.length) {
          this.fligtsSearchError = this.$t('flights.no_flights_error');
        } else {
          this.fligtsSearchResults = response.data;
        }
      } catch (e) {
        if (!!e.response && !!e.response.data) {
          // translate server error to a generic friendly message
          this.fligtsSearchError = this.$t('flights.no_flights');
        } else {
          this.fligtsSearchError = e.message;
        }
      } finally {
        this.fligtsSearchLoading = false;
      }
    },
  },
};
</script>
