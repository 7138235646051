import * as mutations from './mutation-types';

export const actions = {
  updateGlobalFeature({ commit }, feature) {
    commit(mutations.FEATURE_FLAGS_UPDATE_GLOBAL, { ...feature });
  },
  updateAllFeatures({ commit }, { features }) {
    commit(mutations.FEATURE_FLAGS_UPDATE_ALL, { features });
  },
  enablePersistence({ commit }) {
    commit(mutations.FEATURE_FLAGS_UPDATE_GLOBAL, { persist: true });
  },
};
