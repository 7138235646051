<template>
  <v-container
    fluid
    :style="{
      'max-height': setMaxHeightHeroImage,
    }"
    class="header-wrapper pa-0 pb-3 pb-md-10"
  >
    <v-row no-gutters>
      <v-col cols="12">
        <v-img
          :alt="heroText"
          :src="heroImage"
          :max-height="setMaxHeightHeroImage"
        >
          <v-container class="pa-5 container-title">
            <v-row no-gutters>
              <v-col cols="12">
                <p class="mb-0 hero-text">
                  {{ heroText }}
                </p>
              </v-col>
              <v-col cols="12" class="pt-5 d-flex justify-center">
                <SearchComponent />
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HomePageMixins from '../mixins/HomePageMixins';
import SearchComponent from './SearchComponent';

export default {
  name: 'HeaderComponent',
  mixins: [HomePageMixins],
  components: { SearchComponent },

  computed: {
    heroImage() {
      if (this.headerImage || this.headerImageMobile) {
        return this.$vuetify.breakpoint.lgAndUp
          ? this.headerImage
          : this.headerImageMobile;
      } else return '';
    },

    heroText() {
      return this.headerText;
    },

    setMaxHeightHeroImage() {
      return this.$vuetify.breakpoint.mobile
        ? this.heroImageMobileMaxSize
        : this.heroImageMaxSize;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  max-height: 640px;
  width: 100%;
}
.container-title {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font-size(28, 128.5713, 700);
  color: #fff;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include font-size(40, 120, 700);
  }
}
</style>
