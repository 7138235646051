export const mutations = {
  configsLoaded(state, { configs }) {
    state.configs = { ...state.configs, ...configs, ready: true };
  },

  clearConfigKeys(state, keys) {
    let configCopy = { ...state.configs };
    keys.forEach((key) => {
      delete configCopy[key];
    });
    state.configs = { ...configCopy };
  },

  setEmbedded(state, embedded) {
    state.embedded = embedded;
  },

  setMobileWrapp(state, isMobileWrapp) {
    state.isMobileWrapp = isMobileWrapp;
  },

  traverlerIdLoaded(state, id) {
    state.travelerId = id;
  },

  setDisplayTestDataValue(state, value) {
    state.displayTestData = value;
  },

  setShowFlightPreorderAvailability(state, value) {
    state.showFlightPreorderAvailability = value;
  },

  setSearchFlightByCodeShareNumber(state, value) {
    state.searchFlightByCodeShareNumber = value;
  },

  setShopOnlyMode(state, value) {
    state.shopOnlyMode = value;
  },

  setConciergeV3(state, value) {
    state.conciergeV3 = value;
  },

  setShopV4(state, value) {
    state.isShopV4 = value;
  },

  setUserLocation(state, value) {
    state.userLocation = { ...state.userLocation, ...value };
  },

  setFlightSelectorStatus(state, value) {
    state.isOpenFlightSelector = value;
  },
};
