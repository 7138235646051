export const DINING_SEARCH_LOADED = 'DINING_SEARCH_LOADED';
export const DINING_SEARCH_ERROR = 'DINING_SEARCH_ERROR';
export const DINING_SEARCH_LOADING = 'DINING_SEARCH_LOADING';
export const DINING_SEARCH_CLEAR = 'DINING_SEARCH_CLEAR';
export const DINING_MORE_SEARCH_LOADED = 'DINING_MORE_SEARCH_LOADED';
export const CITY_SEARCH_COMPLETED = 'CITY_SEARCH_COMPLETED';
export const CITY_SEARCH_ERROR = 'CITY_SEARCH_ERROR';
export const CITY_SEARCH_LOADING = 'CITY_SEARCH_LOADING';
export const REVERSE_GEOCODE_ERROR = 'REVERSE_GEOCODE_ERROR';
export const REVERSE_GEOCODE_LOADING = 'REVERSE_GEOCODE_LOADING';
export const REVERSE_GEOCODE_COMPLETED = 'REVERSE_GEOCODE_COMPLETED';
export const DINING_SEARCH_CENTER_UPDATED = 'DINING_SEARCH_CENTER_UPDATED';
