<template>
  <v-container class="title-container py-6" fluid>
    <template v-if="invalidFlightAlert">
      <ToastComponent @toastClosed="clearInvalidFlightAlert" />
    </template>

    <v-row justify="center" no-gutters>
      <template v-if="!isLoading && headerTitle">
        <v-col cols="8" data-cy="concierge-header-title">
          <vue-markdown
            :source="headerTitle"
            :postrender="convertHeaderTitle"
          />
        </v-col>
      </template>
    </v-row>

    <v-row justify="center" align="center">
      <v-col cols="8" data-cy="concierge-header-subtitle">
        <template v-if="isLoading">
          <div class="px-10 loading">
            <v-skeleton-loader type="heading" />
            <v-skeleton-loader type="heading" />
          </div>
        </template>

        <template v-else-if="subtitleHtml">
          <vue-markdown :source="subtitleHtml" :postrender="convertText" />
        </template>
      </v-col>
    </v-row>

    <template v-if="!isLoading && !selectedContextFlight">
      <v-row justify="center">
        <AddFlightCta />
      </v-row>
    </template>
  </v-container>
</template>

<script>
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins.vue';
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';
import AddFlightCta from '@/modules/flights/features/AddFlight/AddFlightCta';
import ToastComponent from '@/core/components/ToastComponent.vue';

export default {
  name: 'ConciergeHeaderTitles',
  mixins: [ConciergeHomeMixins, FlightsMixins],
  components: {
    AddFlightCta,
    ToastComponent,
  },
};
</script>

<style scoped lang="scss">
.title-container {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    min-height: 160px;
  }

  ::v-deep {
    .hero-subtitle {
      color: #595959;
      letter-spacing: 0.1em;
    }
    .hero-title {
      color: var(--v-grey8-base);
      width: 100%;
      text-align: center;
    }
  }
}
::v-deep .v-skeleton-loader__heading {
  width: 100%;
  margin-bottom: 20px;
}
</style>
