<template>
  <v-container fluid class="px-0 mb-3 result-wrapper">
    <v-row class="pb-3 mb-3 px-6 title" no-gutters justify="center">
      <v-col align-self="start" cols="1" @click="$emit('click')">
        <v-icon>mdi-chevron-left</v-icon>
      </v-col>
      <v-col class="text-align-center" cols="11">
        <span>{{ $t('search_results') }}</span>
      </v-col>
    </v-row>
    <div
      class="loader-banner-text"
      v-if="!hasFlights && !isLoadingFlights"
      justify="center"
      align="center"
    >
      <v-img
        alt="No results"
        contain
        height="232px"
        width="200px"
        src="@/assets/noresults.png"
      />
      <p class="mb-0">
        {{ $t('flights.adjust_search_1') }}
      </p>
      <p class="mb-0">{{ $t('flights.adjust_search_2') }}</p>
    </div>
    <div v-else>
      <v-divider />
      <v-row no-gutters justify="center" class="search-params py-2 px-5">
        <v-col cols="11">
          {{ searchInfo.flightNumber }}
          {{ searchInfo.departureDate | dateFormat('MMMM DD, YYYY') }}
        </v-col>
        <v-col @click="$emit('click')" cols="1">
          <v-icon>mdi-pencil</v-icon>
        </v-col>
      </v-row>
      <v-divider />
      <v-row v-if="hasError" no-gutters class="px-6 py-2">
        <v-alert
          class="payment-error"
          :value="hasError"
          type="error"
          outlined
          text
          icon="mdi-close-circle"
          transition="bounceInRight"
        >
          <v-row no-gutters>
            {{ $t('failed') }}
          </v-row>
          <v-row no-gutters>
            {{ errorMessage }}
          </v-row>
        </v-alert>
      </v-row>
      <v-row no-gutters :class="['px-6', hasError ? 'py-0' : 'py-6']">
        <v-col>
          <v-card
            v-for="(flight, i) in flights"
            :key="i"
            outlined
            @click.capture.stop="toggleSelect(flight.id)"
            class="mb-1"
          >
            <v-container class="pb-0">
              <v-row no-gutters justify="center">
                <v-col cols="11">
                  <p class="mb-0 result-title">
                    {{ flight.flightNumber }}:{{ flight.origin.city }} to
                    {{ flight.destination.city }}
                  </p>
                  <p class="mb-0 result-time">
                    {{
                      flight.departureTime
                        | dateFormat('MMMM DD, YYYY - HH:mm A')
                    }}
                  </p>
                </v-col>
                <v-col cols="1">
                  <v-checkbox
                    class="ma-0"
                    :id="flight.id"
                    v-model="currentSelectedFlights"
                    name="currentSelectedFlights"
                    color="primary"
                    :value="flight.id"
                    @click="toggleSelect(flight.id)"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-card-actions>
      <v-row no-gutters class="px-6 btn-wrapper">
        <v-btn @click="handleSubmit" depressed class="primary full-width">
          {{ buttonName }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-container>
</template>

<script>
import FlightsMixins from '../mixins/FlightsMixins.vue';
export default {
  name: 'FlightSearchResults',
  props: {
    searchInfo: {
      type: Object,
    },
  },
  mixins: [FlightsMixins],
  data() {
    return {
      currentSelectedFlights: [],
      selectedError: false,
    };
  },
  methods: {
    toggleSelect(id) {
      if (this.currentSelectedFlights.includes(id)) {
        this.currentSelectedFlights = this.currentSelectedFlights.filter(
          (el) => el !== id
        );
      } else {
        this.selectedError = false;
        this.currentSelectedFlights = [...this.currentSelectedFlights, id];
      }
    },
    handleSubmit() {
      if (!this.filteredSelectedFlights.length) {
        this.selectedError = true;
      } else {
        this.selectedError = false;
        this.$emit('submit', this.filteredSelectedFlights);
      }
    },
  },

  computed: {
    buttonName() {
      return !!this.hasFlights && !this.hasError
        ? this.$t('flights.add_flight')
        : this.$t('modify_search');
    },
    filteredSelectedFlights() {
      let filtered = [];
      if (this.flights && this.flights.length) {
        filtered = this.flights.filter(
          (flight) => this.currentSelectedFlights.indexOf(flight.id) > -1
        );
      }
      return filtered;
    },
    hasError() {
      return (
        this.selectedError ||
        this.hasFlightAddedError ||
        this.hasFlightDuplicateError
      );
    },
    errorMessage() {
      if (this.selectedError) {
        return this.$t('flights.flights_select_error');
      }
      return this.hasFlightAddedError
        ? this.flightAddedError
        : this.flightDuplicateError;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-align-center {
  text-align: center;
}
.title {
  color: #05090f;
  @include font-size(18, 24, 600);
}
.btn-wrapper {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.search-params {
  font-weight: 600;
  font-size: 16px;
  color: var(--v-grey7-base);
}
.result-title {
  font-weight: 600;
  font-size: 14px;
  color: #05090f;
}
.result-time {
  font-weight: normal;
  font-size: 12px;
  color: var(--v-grey7-base);
}
.result-wrapper {
  position: relative;
}
.payment-error {
  width: 100%;
}
</style>
