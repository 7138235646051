import * as mutations from './mutation-types';
import { RepoFactory } from '@/core/repositories';
import { mapboxService } from '@/core/services/MapboxService';

export const actions = {
  async searchBookingContent(
    { commit },
    {
      city,
      searchText,
      latitude,
      longitude,
      skip,
      take,
      minAvailabilityDate,
      maxAvailabilityDate,
      minPrice,
      maxPrice,
      currency,
      subCategories,
      sortOption,
      faceMaskRequired,
      socialDistancingEnforced,
      intensifiedCleaning,
    } = { skip: 0 }
  ) {
    skip = skip || 0;
    const bookingService = RepoFactory.get('booking');
    commit(mutations.BOOKING_CONTENT_SEARCH_ERROR, { error: null });
    commit(mutations.BOOKING_CONTENT_SEARCH_LOADING, true);
    if (skip === 0) {
      commit(mutations.BOOKING_CONTENT_SEARCH_CLEAR_RESULTS);
    }

    try {
      let sortField;
      let sortOrder;
      if (!!sortOption) {
        sortField = sortOption.field;
        sortOrder = sortOption.order;
      }

      const { data } = await bookingService.getBookings({
        city,
        searchText,
        latitude,
        longitude,
        skip,
        take,
        minAvailabilityDate,
        maxAvailabilityDate,
        minPrice,
        maxPrice,
        currency,
        subCategories,
        sortField,
        sortOrder,
        faceMaskRequired,
        socialDistancingEnforced,
        intensifiedCleaning,
      });

      let mutation;
      let mutationArgs;
      if (skip === 0) {
        mutation = mutations.BOOKING_CONTENT_SEARCH_LOADED;
        mutationArgs = {
          city: city,
          items: data.items,
          total: data.total,
          latitude: latitude,
          longitude: longitude,
          searchText: searchText,
          minAvailabilityDate: minAvailabilityDate,
          maxAvailabilityDate: maxAvailabilityDate,
          minPrice: minPrice,
          maxPrice: maxPrice,
          subCategories: subCategories,
          faceMaskRequired: faceMaskRequired,
          socialDistancingEnforced: socialDistancingEnforced,
          intensifiedCleaning: intensifiedCleaning,
        };
      } else {
        mutation = mutations.BOOKING_CONTENT_MORE_SEARCH_LOADED;
        mutationArgs = {
          items: data.items,
        };
      }

      commit(mutation, mutationArgs);
    } catch (error) {
      commit(mutations.BOOKING_CONTENT_SEARCH_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.BOOKING_CONTENT_SEARCH_LOADING, false);
    }
  },

  async loadCategories({ commit }, { purchaseStrategy } = {}) {
    const categoryService = RepoFactory.get('category');
    commit(mutations.CATEGORIES_ERROR, { error: null });
    commit(mutations.CATEGORIES_LOADING, true);
    try {
      const { data } = await categoryService.getCategories({
        purchaseStrategy,
      });
      commit(mutations.CATEGORIES_LOADED, { categories: data });
    } catch (error) {
      commit(mutations.CATEGORIES_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.CATEGORIES_LOADING, false);
    }
  },
  async performCitySearch({ commit }, location) {
    commit(mutations.CITY_SEARCH_ERROR, { error: null });
    commit(mutations.CITY_SEARCH_LOADING, true);
    try {
      const data = await mapboxService.getCities(location);
      commit(mutations.CITY_SEARCH_COMPLETED, {
        result: data,
      });
    } catch (error) {
      commit(mutations.CITY_SEARCH_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.CITY_SEARCH_LOADING, false);
    }
  },

  async performReverseGeocode({ commit }, { lat, lng }) {
    commit(mutations.REVERSE_GEOCODE_ERROR, { error: null });
    commit(mutations.REVERSE_GEOCODE_LOADING, true);
    try {
      const data = await mapboxService.reverseGeocode({ lat: lat, lng: lng });
      commit(mutations.REVERSE_GEOCODE_COMPLETED, { result: data });
    } catch (error) {
      commit(mutations.REVERSE_GEOCODE_ERROR, { error: error.toString() });
    } finally {
      commit(mutations.REVERSE_GEOCODE_LOADING, false);
    }
  },
};
