import { RepoFactory } from '@/core/repositories';
import * as mutations from './mutation-types';
import localStorageUtils from '@/core/utils/localstorage';

export const actions = {
  async loadConciergeModuleItems(
    { commit },
    { flight, date, city, stage, shopV4FF }
  ) {
    commit(mutations.CONCIERGE_MODULE_CONTENT_LOADING, true);
    commit(mutations.CONCIERGE_MODULE_CONTENT_ERROR, { error: null });
    const conRepoKey = 'conciergeV2';
    const conciergeRepo = RepoFactory.get(conRepoKey);
    try {
      const response = await conciergeRepo.getConciergeModuleItems({
        flight,
        date,
        city,
        stage,
        shopV4FF,
      });

      if (!!response && !!response.data) {
        commit(mutations.CONCIERGE_MODULE_CONTENT_LOADED, response.data);

        if (
          !!response.data.information &&
          !!response.data.information.person &&
          response.data.information.person.identity_id != '123'
        ) {
          localStorageUtils.setDeviceId(
            response.data.information.person.identity_id
          );
        }
      } else {
        commit(mutations.CONCIERGE_MODULE_CONTENT_ERROR, {
          error: {
            message: `No content found for this flight: ${flightNumber}`,
            status: null,
          },
        });
      }
      commit(mutations.CONCIERGE_MODULE_CONTENT_LOADING, false);
    } catch (e) {
      let errorObject = {
        message: e.toString(),
        status: e && e.response ? e.response.status : null,
      };
      if (!errorObject.message.includes('CanceledError')) {
        commit(mutations.CONCIERGE_MODULE_CONTENT_ERROR, {
          error: errorObject,
        });
        commit(mutations.CONCIERGE_MODULE_CONTENT_LOADING, false);
      }
    }
  },

  async loadConciergeStages(
    { commit },
    { flight, departureDate, iataCode, shopV4FF }
  ) {
    commit(mutations.CONCIERGE_STAGES_LOADING, true);
    commit(mutations.CONCIERGE_STAGES_ERROR, { error: null });
    const conRepoKey = 'conciergeV2';
    const conciergeRepo = RepoFactory.get(conRepoKey);
    try {
      const response = await conciergeRepo.getConciergeStages({
        flight,
        departureDate,
        iataCode,
        shopV4FF,
      });
      if (!!response && !!response.data) {
        commit(mutations.CONCIERGE_STAGES_LOADED, {
          stages: response.data,
        });
      } else {
        commit(mutations.CONCIERGE_STAGES_ERROR, {
          error: `No stages list was found for this flight: ${flightNumber}`,
        });
      }
    } catch (e) {
      let errorObject = {
        message: e.toString(),
        status: e && e.response ? e.response.status : null,
      };
      commit(mutations.CONCIERGE_STAGES_ERROR, { error: errorObject });
    } finally {
      commit(mutations.CONCIERGE_STAGES_LOADING, false);
    }
  },

  updateStage({ commit }, stage) {
    commit(mutations.UPDATE_STAGE, stage);
  },
};
