export const getters = {
  hasFlights: (state) =>
    !!state.flights &&
    !state.flights.isLoading &&
    !!state.flights.value &&
    !!state.flights.value.length,
  flights: (state) => state.flights.value,
  invalidFlights: (state) => state.invalidFlights,
  flightSearchResults: (state) => state.flights,
  hasUserFlights: (state) =>
    !!state &&
    !!state.userFlights &&
    !!state.userFlights.value &&
    !!state.userFlights.value.length,
  userFlights: (state) => state.userFlights.value,
  hasUserFlightError: (state) => !!state.userFlights.error,
  userFlightsIds: (state) =>
    getters.hasUserFlights(state)
      ? state.userFlights.value.map((f) => f.id)
      : null,
  hasFlightBookings: (state) =>
    !!state.flightBookings.result && !!state.flightBookings.result.length,
  hasContextFlights: (state) => state.contextFlights.length > 0,
  hasSelectedContextFlight: (state) => !!state.selectedContextFlight,
  hasAddedFlight: (state) =>
    !!state.addedFlights &&
    !!state.addedFlights.flights &&
    !!state.addedFlights.flights.length &&
    state.addedFlights.error === null,
  hasDeletedFlight: (state) =>
    state.flightDeletion &&
    state.flightDeletion.deleted &&
    state.flightDeletion.error === null,
  hasDuplicateError: (state) =>
    state.duplicateFlight && state.duplicateFlight.value,
  showAddFlight: (state) => state.showAddFlight,
  codeShareAlert: (state) => state.codeShareAlert,
  codeShareInfo: (state) => state.codeShareInfo,
};
