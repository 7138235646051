import {
  RIDES_LOADING,
  RIDES_LOADED,
  RIDES_ERROR,
  RIDES_CLEAR,
  GT_LOCATION_FROM_SEARCH_COMPLETED,
  GT_LOCATION_FROM_SEARCH_LOADING,
  GT_LOCATION_FROM_SEARCH_ERROR,
  GT_LOCATION_TO_SEARCH_COMPLETED,
  GT_LOCATION_TO_SEARCH_LOADING,
  GT_LOCATION_TO_SEARCH_ERROR,
  USER_TO_REVERSE_GEOCODE_ERROR,
  USER_TO_REVERSE_GEOCODE_LOADING,
  USER_TO_REVERSE_GEOCODE_COMPLETED,
  USER_FROM_REVERSE_GEOCODE_ERROR,
  USER_FROM_REVERSE_GEOCODE_LOADING,
  USER_FROM_REVERSE_GEOCODE_COMPLETED,
  SET_SEARCH_TIMESTAMP,
} from './mutation-types';

export const mutations = {
  [RIDES_CLEAR](state) {
    state.transportBookingContent = {
      ...state.transportBookingContent,
      value: [],
      total: 0,
    };
  },
  [RIDES_LOADING](state, payload = true) {
    state.transportBookingContent = {
      ...state.transportBookingContent,
      isLoading: payload,
    };
  },
  [RIDES_LOADED](
    state,
    {
      rides,
      total,
      startLocation,
      endLocation,
      startTime,
      passengers,
      polling,
      minPrice,
      maxPrice,
      currency,
      vehicleSize,
    } = {
      rides: [],
      total: 0,
      startLocation: '',
      endLocation: '',
      startTime: '',
      passengers: 0,
      polling: null,
      minPrice: 0,
      maxPrice: 0,
      currency: '',
      vehicleSize: [],
    }
  ) {
    state.transportBookingContent = {
      ...state.transportBookingContent,
      value: rides,
      startLocation,
      endLocation,
      startTime,
      passengers,
      polling,
      minPrice,
      maxPrice,
      currency,
      total,
      vehicleSize,
    };
  },
  [RIDES_ERROR](state, { error } = { error: 'Error: Unknown Error' }) {
    state.transportBookingContent = {
      ...state.transportBookingContent,
      error: error,
    };
  },
  [GT_LOCATION_FROM_SEARCH_COMPLETED](state, { result } = { result: {} }) {
    state.fromSearch = { ...state.fromSearch, result: { ...result } };
  },
  [GT_LOCATION_FROM_SEARCH_LOADING](state, payload = true) {
    state.fromSearch = { ...state.fromSearch, isLoading: payload };
  },
  [GT_LOCATION_FROM_SEARCH_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.fromSearch = {
      ...state.fromSearch,
      result: null,
      error: error,
    };
  },
  [GT_LOCATION_TO_SEARCH_COMPLETED](state, { result } = { result: {} }) {
    state.toSearch = { ...state.toSearch, result: { ...result } };
  },
  [GT_LOCATION_TO_SEARCH_LOADING](state, payload = true) {
    state.toSearch = { ...state.toSearch, isLoading: payload };
  },
  [GT_LOCATION_TO_SEARCH_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.toSearch = {
      ...state.toSearch,
      result: null,
      error: error,
    };
  },
  [USER_FROM_REVERSE_GEOCODE_COMPLETED](state, { result } = { result: {} }) {
    state.fromSearch = {
      ...state.fromSearch,
      userLocation: { ...result },
    };
  },
  [USER_FROM_REVERSE_GEOCODE_LOADING](state, payload = true) {
    state.fromSearch = { ...state.fromSearch, isLoading: payload };
  },
  [USER_FROM_REVERSE_GEOCODE_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.fromSearch = {
      ...state.fromSearch,
      result: null,
      error: error,
    };
  },
  [USER_TO_REVERSE_GEOCODE_COMPLETED](state, { result } = { result: {} }) {
    state.toSearch = { ...state.toSearch, userLocation: { ...result } };
  },
  [USER_TO_REVERSE_GEOCODE_LOADING](state, payload = true) {
    state.toSearch = { ...state.toSearch, isLoading: payload };
  },
  [USER_TO_REVERSE_GEOCODE_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.toSearch = {
      ...state.toSearch,
      result: null,
      error: error,
    };
  },
  [SET_SEARCH_TIMESTAMP](state) {
    state.searchTimestamp = new Date().getTime();
  },
};
