<template>
  <v-dialog
    v-model="dialog"
    max-width="30rem"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    data-cy="flight-pre-order-warning-modal"
  >
    <v-card class="justify-center align-center d-flex flex-column popup py-5">
      <v-card-actions class="justify-end mb-16">
        <v-btn
          @click="closeModal"
          depressed
          class="cta-btn-x"
          color="white"
          small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card
        outlined
        class="py-12 mx-16"
        width="auto"
        :max-width="$vuetify.breakpoint.smAndDown ? '70%' : '100%'"
      >
        <p class="size16-weight400 mx-16 text-center">
          {{ arrivalCityTitle }}
          <v-icon size="14px" color="#000">mdi-arrow-right</v-icon>
          {{ departureCityTitle }}
        </p>
        <p class="size14-weight400 grey-text mb-1 text-center">
          {{ flight.flightNumber }} ·
          {{ flightDateAndTime }}
        </p>
      </v-card>
      <p class="size18-weight500 mt-7 text-center">
        {{ reasonMessage }}
      </p>
      <p class="size16-weight400 mx-10 text-center">
        {{ tryAgainMessage }}
      </p>
      <v-card-actions class="justify-center mb-16">
        <v-btn
          @click="closeModal"
          depressed
          :color="$vuetify.theme.themes.light.primary.base"
          class="cta-btn px-16 py-5"
        >
          {{ $t('flights.select_another') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';

export default {
  name: 'AddFlightPreOrderWarningModal',
  mixins: [FlightsMixins],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    flight: {
      type: Object,
      default: () => ({
        destination: {
          city: '',
          iata: '',
        },
        origin: {
          city: '',
          iata: '',
        },
        departureTime: new Date(),
        flightNumber: 1,
      }),
    },
  },

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    departureCityTitle() {
      return `${this.flight?.destination?.city} ${this.flight?.destination?.iata}`;
    },

    arrivalCityTitle() {
      return `${this.flight?.origin?.city} ${this.flight?.origin?.iata}`;
    },

    flightDateAndTime() {
      if (!this.flight || !this.flight?.origin) {
        return '';
      }
      return moment
        .parseZone(this.flight.departureTime)
        .format('MMM DD, YYYY · HH:mm');
    },

    reasonMessage() {
      if (this.isPreorderUnavailable(this.flight))
        return this.$t('flights.reason_preorder_unavailable');
      else if (this.hasPreorderEnded(this.flight))
        return this.$t('flights.reason_preorder_ended');
      else if (this.isPreorderAvailableSoon(this.flight))
        return this.$t('flights.reason_preorder_available_soon');
      else return '';
    },

    closingHours() {
      return Math.floor(
        this.flight.storeCatalogClosingMinutesBeforeFlight / 60
      );
    },

    tryAgainMessage() {
      if (this.isPreorderUnavailable(this.flight))
        return this.$t('flights.try_again_preorder_unavailable');
      else if (this.hasPreorderEnded(this.flight))
        return this.closingHours > 0
          ? this.$tc('flights.try_again_preorder_ended', this.closingHours, {
              hours: this.closingHours,
            })
          : this.$tc('flights.try_again_preorder_ended');
      else if (this.isPreorderAvailableSoon(this.flight))
        return this.$t('flights.try_again_preorder_available_soon');
      else return '';
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },

  watch: {
    open: {
      immediate: true,
      handler(open) {
        this.dialog = open;
      },
    },

    dialog: {
      handler(dialog) {
        if (!dialog) this.$emit('close');
      },
    },
  },
};
</script>

<style scoped lang="scss">
.cta-btn {
  color: white;
  @include font-size(16, 150, 500);

  &-x {
    color: black;
    position: absolute;
    right: 5px;
    top: 15px;
  }

  &-x:hover:before {
    opacity: 0 !important;
  }
}

.grey-text {
  color: var(--v-grey7-base);
}

.popup {
  position: relative;
}
</style>
