<template>
  <v-footer absolute padless class="app-footer">
    <v-container class="app-footer-links" fluid>
      <v-row justify="center" no-gutters>
        <v-col cols="12" md="11" lg="10">
          <v-row justify="space-between">
            <v-col class="pa-0">
              <a
                class="footer-links"
                :href="termsAndConditionsUrl"
                target="_blank"
              >
                {{ $t('shell.terms') }}
              </a>
              <a class="footer-links" :href="privacyPolicyUrl" target="_blank">
                {{ $t('shell.privacy') }}
              </a>
              <a
                class="footer-links"
                :href="helpAndFeedbackUrl"
                target="_blank"
              >
                {{ $t('shell.support') }}
              </a>
            </v-col>
            <v-col cols="auto" class="pa-0">
              <span class="footer-logo">
                {{ $t('shell.powered_by') }}
                <a
                  class="ml-0 footer-links"
                  href="https://www.guestlogix.com"
                  target="_blank"
                >
                  Guestlogix
                </a>
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'ConciergeFooter',
  mixins: [ConfigsMixins],
};
</script>

<style scoped lang="scss">
.app-footer {
  background: rgba(0, 0, 0, 0.4);
}

.app-footer-links {
  color: #fff;

  @media (max-width: map-get($grid-breakpoints, md)) {
    font-size: 12px;
  }

  .footer-links {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
  }
}

.footer-logo {
  color: #fff;
}
</style>
