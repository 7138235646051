export const getters = {
  hasInformationModuleContent: (state) =>
    !!state.informationModuleContent &&
    !state.informationModuleContent.isLoading &&
    !!state.informationModuleContent.value,
  hasInformationStagesError: (state) =>
    !!state.articles && Boolean(state.articles.error),
  hasArticleProducts: (state) =>
    !!state.articleProducts &&
    !state.articleProducts.isLoading &&
    !!state.articleProducts.value.groups,
  hasCitySearch: (state) =>
    !state.citySearch.isLoading && state.citySearch.result !== null,
};
