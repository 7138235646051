import {
  FEATURE_FLAGS_UPDATE_GLOBAL,
  FEATURE_FLAGS_UPDATE_ALL,
} from './mutation-types';

export const mutations = {
  [FEATURE_FLAGS_UPDATE_GLOBAL](state, feature) {
    state.features = {
      ...state.features,
      global: {
        ...state.features.global,
        ...feature,
      },
    };
  },
  [FEATURE_FLAGS_UPDATE_ALL](state, { features }) {
    state.features = {
      ...state.features,
      global: {
        ...state.features.global,
        ...features.global,
      },
    };
  },
};
