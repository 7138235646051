import {
  DETAILS_LOADED,
  DETAILS_LOADING,
  DETAILS_ERROR,
  USERFORM_DATERANGE_SET,
  USERFORM_DATERANGE_REMOVE,
  USERFORM_PASSES_SET,
  USERFORM_US_DEST_CONFIRM_SET,
  USERFORM_CONTACTS_SET,
} from '@/modules/product/advica/store/mutation-types';

export const mutations = {
  [DETAILS_LOADED](state, { product } = { product: {} }) {
    state.details = { ...state.details, value: { ...product } };
  },
  [DETAILS_LOADING](state, payload = true) {
    state.details = { ...state.details, isLoading: payload };
  },
  [DETAILS_ERROR](state, { error } = { error: 'Error: Unknown error' }) {
    state.details = {
      ...state.details,
      value: null,
      error: error,
    };
  },
  [USERFORM_DATERANGE_SET](state, dateRange) {
    state.userForm.selectedDateRange = [...dateRange];
  },
  [USERFORM_DATERANGE_REMOVE](state) {
    state.userForm.selectedDateRange = null;
  },
  [USERFORM_PASSES_SET](state, passes) {
    state.userForm.selectedPasses = [...passes];
  },
  [USERFORM_US_DEST_CONFIRM_SET](state, isConfirmed) {
    state.userForm.USDestinationConfirmed = isConfirmed;
  },
  [USERFORM_CONTACTS_SET](state, contacts) {
    state.userForm.contacts = [...contacts];
  },
};
