<template>
  <v-card width="420" rounded class="flights-selection-card">
    <template v-if="!isAddFlightShowing">
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col
            cols="12"
            class="
              mb-0
              pl-5
              pb-0
              mt-4
              d-flex
              justify-space-between
              align-center
            "
          >
            <span class="page-title">
              {{ $t('flights.my_flights') }}
              <template v-if="showRemoveFlightFF">
                <span @click="$emit('onEditClick')" class="edit-link px-4">
                  {{ $t('edit') }}
                </span>
              </template>
            </span>

            <template v-if="$vuetify.breakpoint.smAndDown">
              <v-icon
                size="25"
                color="var(--v-grey7-base)"
                @click="$emit('onClose')"
                class="close-icon mr-4"
              >
                mdi-close
              </v-icon>
            </template>
          </v-col>

          <v-col
            cols="12"
            class="px-0 flights-selection-card-list pt-0 px-5"
            data-cy="my-flights-container-list"
            ref="list"
          >
            <FlightListItem
              v-for="(flight, index) in contextFlights"
              :key="index"
              :divider="false"
              :flight="flight"
              :isActive="isActiveFlight(flight)"
              :class="{
                'active-flight': isActiveFlight(flight),
              }"
              @submitFlight="onClick(flight)"
            />
          </v-col>

          <v-col cols="12" class="add-button-wrapper px-5 mt-5 mb-3">
            <v-btn
              class="add-button py-6"
              block
              depressed
              text
              :color="$vuetify.theme.themes.light.primary.base"
              @click="$emit('addFlight:change', true)"
            >
              <v-icon>mdi-plus</v-icon>
              {{ $t('flights.add_a_flight') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-else>
      <v-row no-gutters class="pt-5 px-4 pb-4">
        <template v-if="$vuetify.breakpoint.smAndDown">
          <v-col
            cols="12"
            class="mb-5 d-flex justify-space-between align-center"
          >
            <span class="page-title">{{ $t('flights.add_flight') }}</span>
            <v-icon
              size="25"
              color="var(--v-grey7-base)"
              @click="$emit('onClose')"
              class="close-icon"
            >
              mdi-close
            </v-icon>
          </v-col>
        </template>

        <AddFlightContainer
          @onReturn="$emit('addFlight:change', false)"
          @onClose="$emit('onClose')"
          :has-return-button="true"
          analytics-origin="menu"
        />
      </v-row>
    </template>
  </v-card>
</template>

<script>
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import AddFlightContainer from '@/modules/flights/features/AddFlight/AddFlightContainer';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import FlightListItem from '@/modules/flights/features/FlightsList/FlightListItem';
import moment from 'moment';

export default {
  name: 'FlightsSelectionCard',
  mixins: [SelectedContextFlightMixins, FlightsMixins, GoogleTagManagerMixins],
  components: { FlightListItem, AddFlightContainer },

  props: {
    isAddFlightShowing: {
      type: Boolean,
      default: false,
    },

    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      flightsList: null,
      showRemoveFlightFF: false,
    };
  },

  created() {
    this.checkRemoveFlightFF();
  },

  methods: {
    isActiveFlight(flight) {
      return flight.id === this.selectedContextFlight?.id;
    },

    onClick(flight) {
      const formattedFlight = {
        flight_number: flight.flightNumber,
        departure_date: moment(flight.departureTime).format('MMM DD, YYYY'),
      };
      this.pushSwitchFlight(formattedFlight);
      this.setSelectedFlight(flight);
      this.$emit('select', flight);
    },

    async checkRemoveFlightFF() {
      this.showRemoveFlightFF = await this.$ldclient.variation(
        'remove-flight',
        false,
        true
      );
      this.$ldclient.$on('change:remove-flight', (value) => {
        this.showRemoveFlightFF = value;
      });
    },

    scrollToActive() {
      if (this.$refs.list)
        this.$refs.list.scrollTo(
          0,
          this.$refs.list.getElementsByClassName('active-flight')[0].offsetTop +
            this.$refs.list.getElementsByClassName('active-flight')[0]
              .clientHeight -
            this.$refs.list.clientHeight
        );
    },

    waitTillRendered() {
      let interval;
      interval = setInterval(() => {
        if (this.$refs.list && this.$refs.list?.clientHeight) {
          clearInterval(interval);
          this.scrollToActive();
        }
      }, 50);
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) this.waitTillRendered();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.flights-selection-card {
  overflow-y: hidden;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    max-height: min(580px, calc(90vh - 120px));
  }

  &-list {
    overflow-y: auto;
    cursor: initial;
    max-height: calc(100vh - 140px);

    @media (min-width: map-get($grid-breakpoints, sm)) {
      max-height: min(440px, calc(90vh - 260px));
    }
  }
}

.add-flight {
  color: var(--v-primary-base);
  font-size: 16px;
  font-weight: 400;
}

.add-button {
  background: rgba(0, 0, 0, 0);
  border: 1px dashed var(--v-grey5-base);
  @include font-size(16, 150, 500);

  &-wrapper {
    display: flex;
    justify-content: center;
  }
}

.edit-link {
  color: var(--v-primary-base);
  @include font-size(14, 150, 400);
  cursor: pointer;
}

.close-icon {
  @include font-size(25, 98, 400);
}

.page-title {
  @include font-size(18, 150, 600);
}
</style>
