export const getters = {
  hasDetails: (state) =>
    !state.details.isLoading && state.details.value !== null,
  adultsPassesCount: (state) => {
    const adultsPasses = state.userForm.selectedPasses.filter(
      (pass) => pass.id === 'adult'
    );
    return adultsPasses.length > 0 ? adultsPasses[0].value : 0;
  },
  childrenPassesCount: (state) => {
    const childrenPasses = state.userForm.selectedPasses.filter(
      (pass) => pass.id === 'child'
    );
    return childrenPasses.length > 0 ? childrenPasses[0].value : 0;
  },
  getAllPasses: (state) => {
    return state.userForm.selectedPasses;
  },
  getAllContacts: (state) => {
    return state.userForm.contacts;
  },
};
