// noinspection ES6MissingAwait

import * as mutations from './mutation-types';
import { RepoFactory } from '@/core/repositories';

export const actions = {
  async loadCatalogGroups(
    { commit },
    { city, longitude, latitude, flightIds, travelerId } = {}
  ) {
    const catalogGroupService = RepoFactory.get('catalogGroup');
    commit(mutations.CATALOG_GROUPS_ERROR, { error: null });
    commit(mutations.CATALOG_GROUPS_LOADING, true);
    try {
      const filterData = (data) => {
        if (!data) {
          throw 'Undefined catalogGroups response';
        }
        if (!data.groups) {
          throw "Missing 'groups' object";
        }
        // List of compatible booking item types
        // Can add more types to the list as they become compatible
        let allowedTypes = ['Item', 'WebItem'];

        let filteredData = data.groups.filter((x) =>
          allowedTypes.includes(x.type)
        );

        return { groups: filteredData };
      };

      const { data } = await catalogGroupService.getCatalogGroups({
        city,
        latitude,
        longitude,
        flightIds,
        travelerId,
      });
      commit(mutations.CATALOG_GROUPS_LOADED, {
        groups: filterData(data),
        city,
        latitude,
        longitude,
      });
    } catch (error) {
      commit(mutations.CATALOG_GROUPS_ERROR, {
        error: error.toString(),
        errorResponse: error.response,
      });
    } finally {
      commit(mutations.CATALOG_GROUPS_LOADING, false);
    }
  },
  async loadNoFlightCatalogGroups({ commit }, cities = []) {
    const catalogGroupService = RepoFactory.get('catalogGroup');
    commit(mutations.NO_FLIGHT_CATALOG_GROUPS_LOADING);
    const preferCategories = [
      'Top Rated',
      'Attractions',
      'Multi-day Tours',
      'Unique Experiences',
    ];

    cities.forEach(async (city) => {
      try {
        const { data } = await catalogGroupService.getCatalogGroups({
          city: city.query.city,
          longitude: city.query.lng,
          latitude: city.query.lat,
        });

        const itemsArr = [];

        preferCategories.forEach((category) => {
          const group = data.groups.find(({ title }) => title === category);
          if (group) {
            itemsArr.push(group);
          }
        });
        commit(mutations.NO_FLIGHT_CATALOG_GROUPS_LOADED, {
          city: city.title,
          data: itemsArr.length ? itemsArr[0] : null,
        });
      } catch (error) {
        commit(mutations.NO_FLIGHT_CATALOG_GROUPS_ERROR, {
          error: error.toString(),
          errorResponse: error.response,
          city: city.title,
        });
      }
    });
  },
};
