import * as mutations from './mutation-types';
import { RepoFactory } from '@/core/repositories';

export const actions = {
  async loadCustomSearch(
    { commit },
    {
      text,
      purchaseStrategy,
      flightId,
      city,
      country,
      longitude,
      latitude,
      travelerId,
      sortField,
      sortOrder,
      skip,
      take,
    } = {}
  ) {
    skip = skip || 0;
    take = take || 20;
    const customSearchService = RepoFactory.get('customSearch');
    commit(mutations.CUSTOM_SEARCH_ERROR, { error: null });
    commit(mutations.CUSTOM_SEARCH_LOADING, true);
    try {
      const { data } = await customSearchService.getCustomSearch({
        text,
        purchaseStrategy,
        flightId,
        city,
        country,
        travelerId,
        latitude,
        longitude,
        sortField,
        sortOrder,
        skip,
        take,
      });
      if (skip === 0) {
        commit(mutations.CUSTOM_SEARCH_LOADED, {
          items: data.items,
          total: data.total,
        });
      } else {
        commit(mutations.CUSTOM_MORE_SEARCH_LOADED, { items: data.items });
      }
    } catch (error) {
      commit(mutations.CUSTOM_SEARCH_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.CUSTOM_SEARCH_LOADING, false);
    }
  },
};
