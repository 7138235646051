const ConciergeLandingPage = () => import('./ConciergeLandingPage');
const ConciergeHomeComponent3 = () =>
  import('../concierge-v3/features/ConciergeHomeComponent');
const conciergeRoute = {
  path: '/concierge',
  component: ConciergeLandingPage,
  children: [
    {
      path: '',
      name: 'concierge',
      component: ConciergeHomeComponent3,
      meta: {
        showStagesFooter: true,
      },
    },
    {
      path: ':stageId',
      name: 'concierge-stage',
      component: ConciergeHomeComponent3,
      meta: {
        showStagesFooter: true,
      },
    },
  ],
};

export default conciergeRoute;
