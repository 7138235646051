import * as mutations from './mutation-types';
import { RepoFactory } from '@/core/repositories';
import { mapboxService } from '@/core/services/MapboxService';

export const actions = {
  async searchDining(
    { commit },
    {
      city,
      latitude,
      longitude,
      radius,
      minRating,
      minPriceScale,
      maxPriceScale,
      placeTypes,
      tags,
      skip,
      take,
    } = {
      skip: 0,
      placeTypes: ['Restaurant', 'Cafe', 'Bakery', 'Brewery', 'Dessert', 'Bar'],
    }
  ) {
    skip = skip || 0;
    placeTypes = placeTypes || [
      'Restaurant',
      'Cafe',
      'Bakery',
      'Brewery',
      'Dessert',
      'Bar',
    ];
    const placeService = RepoFactory.get('place');
    commit(mutations.DINING_SEARCH_ERROR, { error: null });
    commit(mutations.DINING_SEARCH_LOADING, true);
    try {
      const { data } = await placeService.getPlaces({
        latitude,
        longitude,
        radius,
        placeTypes,
        minRating,
        minPriceScale,
        maxPriceScale,
        tags,
        skip,
        take,
      });
      if (skip === 0) {
        commit(mutations.DINING_SEARCH_LOADED, {
          tags: tags,
          minRating,
          minPriceScale,
          maxPriceScale,
          radius,
          items: data.items,
          total: data.total,
          lat: data.parameters ? data.parameters.latitude : latitude,
          lng: data.parameters ? data.parameters.longitude : longitude,
          city,
        });
      } else {
        commit(mutations.DINING_MORE_SEARCH_LOADED, { items: data.items });
      }
    } catch (error) {
      commit(mutations.DINING_SEARCH_ERROR, {
        error: error.toString(),
        errorResponse: error.response,
      });
    } finally {
      commit(mutations.DINING_SEARCH_LOADING, false);
    }
  },

  async performCitySearch({ commit }, location) {
    commit(mutations.CITY_SEARCH_ERROR, { error: null });
    commit(mutations.CITY_SEARCH_LOADING, true);
    try {
      const data = await mapboxService.getCities(location);
      commit(mutations.CITY_SEARCH_COMPLETED, {
        result: data,
      });
    } catch (error) {
      commit(mutations.CITY_SEARCH_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.CITY_SEARCH_LOADING, false);
    }
  },

  async performReverseGeocode({ commit }, { lat, lng }) {
    commit(mutations.REVERSE_GEOCODE_ERROR, { error: null });
    commit(mutations.REVERSE_GEOCODE_LOADING, true);
    try {
      const data = await mapboxService.reverseGeocode({ lat: lat, lng: lng });
      commit(mutations.REVERSE_GEOCODE_COMPLETED, { result: data });
    } catch (error) {
      commit(mutations.REVERSE_GEOCODE_ERROR, { error: error.toString() });
    } finally {
      commit(mutations.REVERSE_GEOCODE_LOADING, false);
    }
  },
  updateCenterCoordinates({ commit }, coordinates) {
    commit(mutations.DINING_SEARCH_CENTER_UPDATED, coordinates);
  },

  clearDinnerSearch({ commit }) {
    commit(mutations.DINING_SEARCH_CLEAR);
  },
};
