<template>
  <v-container
    fluid
    class="pb-0 pt-3 px-0"
    data-cy="my-flights-container-list-rows"
  >
    <AddFlightPreOrderWarningModal
      :open="isModalOpen"
      :flight="flight"
      @close="closePreOrderWarningModal"
    />
    <v-row no-gutters>
      <v-col cols="10" class="mt-5 mt-sm-2 flight-info">
        <span
          data-cy="my-flights-container-list-flight-city"
          :class="['flight-info-city', { isActive }]"
        >
          {{ getArrivalCityTitle }}
          <v-icon size="14px" color="#000">mdi-arrow-right</v-icon>
          {{ getDepartureCityTitle }}
        </span>
        <p
          :class="['pt-2 mb-2 flight-info-more', { isActive }]"
          data-cy="my-flights-container-list-flight-spec"
        >
          {{ flight.flightNumber }} ·
          {{ formatFlightDateAndTime }}
        </p>

        <template
          v-if="
            showFlightPreOrder &&
              hasPreorderAvailabilityStatusNotification(flight)
          "
        >
          <p
            data-cy="my-flights-container-list-pre-order-status"
            class="flight-info-pre-order"
          >
            <template
              v-if="
                isPreorderUnavailable(flight) ||
                  currentPreOrderStates.unavailable
              "
            >
              <span>
                {{ $t('flights.title_preorder_unavailable') }}
              </span>
              <v-icon
                @click="openPreOrderWarningModal()"
                data-cy="open-pre-order-modal"
                class="ml-3"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <template
              v-else-if="
                hasPreorderEnded(flight) || currentPreOrderStates.ended
              "
            >
              <span>
                {{ $t('flights.title_preorder_ended') }}
              </span>
              <v-icon
                @click="openPreOrderWarningModal()"
                data-cy="open-pre-order-modal"
                class="ml-3"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <template
              v-else-if="
                isPreorderAvailableSoon(flight) ||
                  currentPreOrderStates.availableSoon
              "
            >
              <span>
                {{ $t('flights.title_preorder_available_soon') }}
              </span>
              <v-icon
                @click="openPreOrderWarningModal()"
                data-cy="open-pre-order-modal"
                class="ml-3"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
          </p>
        </template>
      </v-col>
      <v-col cols="2" class="d-flex justify-end align-center">
        <template v-if="isActive">
          <v-icon
            size="30"
            color="primary"
            class="mr-3"
            data-cy="flight-selected-icon"
          >
            mdi-check-circle-outline
          </v-icon>
        </template>

        <template v-else>
          <v-btn
            data-cy="select-flight-button"
            tabindex="0"
            text
            color="primary"
            @click="getSelectButtonClickHandler()"
            :class="[
              'mr-2 pr-0 select-flight',
              { 'disabled-btn': isSelectFlightDisabled },
            ]"
          >
            {{ $t('select') }}
          </v-btn>
        </template>
      </v-col>
    </v-row>
    <template v-if="divider">
      <v-divider />
    </template>
  </v-container>
</template>

<script>
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import AddFlightPreOrderWarningModal from '@/modules/flights/features/AddFlight/AddFlightPreOrderWarningModal';
import moment from 'moment';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'FlightListItem',
  mixins: [FlightsMixins, ConfigsMixins],
  components: { AddFlightPreOrderWarningModal },

  props: {
    flight: {
      type: Object,
      default: () => ({
        flightNumber: '',
        origin: {
          city: '',
          iata: '',
        },

        destination: {
          city: '',
          iata: '',
        },
      }),
    },

    divider: {
      type: Boolean,
      default: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isModalOpen: false,
      timer: '',
      currentPreOrderEndedStatus: null,
      currentPreOrderAvailableSoonStatus: null,
      currentPreOrderUnavailableStatus: null,
    };
  },

  mounted() {
    if (!!this.shopOnlyMode)
      this.timer = setInterval(this.checkPreOrderStatus, 5000);
  },

  computed: {
    currentPreOrderStates() {
      return {
        ended: this.currentPreOrderEndedStatus || null,
        unavailable: this.currentPreOrderUnavailableStatus || null,
        availableSoon: this.currentPreOrderAvailableSoonStatus || null,
      };
    },

    isSelectFlightDisabled() {
      return (
        this.showFlightPreOrder &&
        this.hasPreorderAvailabilityStatusNotification(this.flight)
      );
    },

    getDepartureCityTitle() {
      return `${this.flight?.destination?.city} ${this.flight?.destination?.iata}`;
    },

    getArrivalCityTitle() {
      return `${this.flight?.origin?.city} ${this.flight?.origin?.iata}`;
    },

    formatFlightDateAndTime() {
      return moment
        .parseZone(this.flight.departureTime)
        .format('MMM DD, YYYY · HH:mm');
    },
  },

  beforeDestroy() {
    this.cancelAutoUpdate();
  },

  methods: {
    openPreOrderWarningModal() {
      this.isModalOpen = true;
    },

    closePreOrderWarningModal() {
      this.isModalOpen = false;
    },

    getSelectButtonClickHandler() {
      this.showFlightPreOrder &&
      this.hasPreorderAvailabilityStatusNotification(this.flight) &&
      !this.$store.state.isShopV4
        ? this.openPreOrderWarningModal(this.flight)
        : this.$emit('submitFlight', this.flight);
    },

    cancelAutoUpdate() {
      clearInterval(this.timer);
    },

    checkPreOrderStatus() {
      this.currentPreOrderUnavailableStatus = this.isPreorderUnavailable(
        this.flight
      );
      this.currentPreOrderEndedStatus = this.hasPreorderEnded(this.flight);
      this.currentPreOrderAvailableSoonStatus = this.isPreorderAvailableSoon(
        this.flight
      );
      this.hasPreorderAvailabilityStatusNotification(this.flight);
    },
  },

  watch: {
    currentPreOrderStates: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.$forceUpdate();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.flight-info {
  color: var(--v-grey8-base);
  @include font-size(16, 118.75);

  &-more {
    @include font-size(14, 150);
    color: var(--v-grey7-base);

    &.isActive {
      @include font-size(14, 121.4287, 700);

      @media (min-width: map-get($grid-breakpoints, sm)) {
        @include font-size(16, 150, 700);
      }
    }
  }

  &-city {
    @include font-size(16, 150, 400);

    &.isActive {
      @include font-size(16, 150, 700);
    }
  }

  &-pre-order {
    @include font-size(14, 17, 600);
    color: #cd2026;

    i,
    button {
      font-size: 14px;
      color: #cd2026;
    }
  }
}

.disabled-btn {
  cursor: default;
  color: rgba(128, 128, 128, 0.7) !important;
}

::v-deep .v-btn ::v-deep .select-flight .v-ripple__container {
  display: none;
}

::v-deep .select-flight {
  @include font-size(14, 150, 600);

  &:before {
    background-color: transparent;
  }

  &:hover::before {
    opacity: 0;
  }
}
</style>
