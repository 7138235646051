import { RepoFactory } from '@/core/repositories';
import * as mutations from './mutation-types';

export const actions = {
  async sendConfirmation({ commit }, { orderId }) {
    const orderRepo = RepoFactory.get('order');
    commit(mutations.ORDER_CONFIRMATION_EMAIL_ERROR, { error: null });
    commit(mutations.ORDER_CONFIRMATION_EMAIL_LOADING, true);
    try {
      await orderRepo.sendConfirmationEmail({ orderId });
      commit(mutations.ORDER_CONFIRMATION_LOADED, true);
    } catch (e) {
      commit(mutations.ORDER_CONFIRMATION_EMAIL_ERROR, { error: e.toString() });
    } finally {
      commit(mutations.ORDER_CONFIRMATION_EMAIL_LOADING, false);
    }
  },
  async loadPastOrders({ commit }, { travelerId }) {
    const travelerService = await RepoFactory.get('traveler');
    commit(mutations.ORDERS_ERROR, { error: null });
    commit(mutations.ORDERS_LOADING, true);
    try {
      const { data } = await travelerService.getPastOrders(travelerId);
      commit(mutations.ORDERS_LOADED, { orders: data['result'] });
    } catch (error) {
      commit(mutations.ORDERS_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.ORDERS_LOADING, false);
    }
  },
};
