const AddFlightsPage = () => import('./AddFlightsPage');
const FlightNotFoundPage = () => import('@/modules/exception/FlightNotFound');
const FlightLandingPage = () => import('./FlightLandingPage');

const FlightsRoute = {
  path: '',
  name: 'flights',
  component: FlightLandingPage,
  meta: {
    showBackgroundHeroImage: true,
    showFadedFooter: true,
    hideAppBar: true,
  },
};

const flightQueryGuard = (to, from, next) => {
  const { query } = to;
  if (query.flightNumber && query.flightDate) {
    next();
  } else {
    next(from);
  }
};

const flightsQueryGuard = (to, from, next) => {
  const { flightNumber, flightDate } = to.query;

  if (flightNumber == null || flightDate == null) {
    next(from);
  }

  if (Array.isArray(flightNumber) && Array.isArray(flightDate)) {
    if (flightNumber.length === flightDate.length) next();
    else {
      next(from);
    }
  } else if (!Array.isArray(flightNumber) && !Array.isArray(flightDate)) {
    next();
  } else {
    next(from);
  }
};

const AddFlightRoute = {
  path: '/addFlight',
  name: 'add-flight',
  component: AddFlightsPage,
  beforeEnter: flightQueryGuard,
};

const AddFlightsRoute = {
  path: '/addFlights',
  name: 'add-flights',
  component: AddFlightsPage,
  beforeEnter: flightsQueryGuard,
};

const FlightNotFoundRoute = {
  path: '/flightNotFound',
  name: 'add-flight-not-found',
  component: FlightNotFoundPage,
  beforeEnter: flightQueryGuard,
  props: (route) => ({
    flightNumber: route.query.flightNumber,
    flightDate: route.query.flightDate,
  }),
};

export { AddFlightRoute, AddFlightsRoute };

export default {
  path: '/flights',
  meta: {
    moduleName: 'flights',
  },
  component: () => import('./FlightsRootPage'),
  children: [
    FlightsRoute,
    AddFlightRoute,
    FlightNotFoundRoute,
    AddFlightsRoute,
  ],
};
