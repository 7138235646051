<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <vue-headful :title="landingPageTitle" />
        <HomeComponent />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vueHeadful from 'vue-headful';
import DiscoveryPageMixins from '../discovery/mixins/DiscoveryPageMixins.vue';
import HomePageMixins from './mixins/HomePageMixins.vue';
import TravelerMixins from '@/modules/common/mixins/TravelerMixins';
import HomeComponent from './features/HomeComponent.vue';
import FlightRedirectMixins from '@/modules/flights/mixins/FlightRedirectMixins';

export default {
  name: 'HomePage',
  components: { HomeComponent, vueHeadful },
  mixins: [
    DiscoveryPageMixins,
    HomePageMixins,
    TravelerMixins,
    FlightRedirectMixins,
  ],

  props: {
    flightIds: {
      type: Array,
      default: () => [],
    },

    flightNumber: {
      type: String,
      default: null,
    },

    flightDate: {
      type: String,
      default: null,
    },

    hasErrorInLoadingFlight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      savedFlights: [],
    };
  },

  watch: {
    flightIds: {
      immediate: true,
      handler(val) {
        if (val && !this.selectedContextFlight) {
          this.loadCatalogs(val);
        }
        this.loadUserFlightBookings();
      },
    },

    hasCatalogGroupsError(val) {
      if (!!val) {
        this.redirectToErrorPage();
      }
    },

    hasErrorInLoadingFlight(val) {
      if (val) {
        this.loadCatalogs();
      }
    },

    hasContextFlights(val) {
      if (!!val) {
        const contextFlightIds = [this.selectedContextFlight.id];
        this.loadCatalogs(contextFlightIds);
      } else {
        this.loadCatalogs();
      }
      this.loadUserFlightBookings();
    },
  },

  methods: {
    loadCatalogs(flightIds = null, travelerId = this.travelerId) {
      this.loadCatalogGroups({
        flightIds,
        travelerId,
      });
    },

    loadUserFlightBookings(travelerId = this.travelerId) {
      if (travelerId) {
        this.loadFlightBookings(travelerId);
      }
    },

    redirectToErrorPage() {
      const notFoundError =
        this.catalogGroupsError && this.catalogGroupsError.status >= 400;
      const internalServerError =
        this.catalogGroupsError && this.catalogGroupsError.status >= 500;

      if (internalServerError) this.$router.replace('/500');
      else if (notFoundError) this.$router.replace('/404');
    },
  },

  computed: {
    hasSavedFlights() {
      return this.hasFlightBookings || !!this.savedFlights.length;
    },
  },
};
</script>
