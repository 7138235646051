export const state = () => ({
  diningSearch: {
    error: null,
    errorResponse: null,
    isLoading: false,
    items: null,
    total: null,
    lng: null,
    lat: null,
    city: null,
  },
  citySearch: {
    result: null,
    error: null,
    isLoading: false,
  },
  reverseGeocode: {
    result: null,
    error: null,
    isLoading: false,
  },
  updateCenterCoordinates: {
    value: null,
  },
});
