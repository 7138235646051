import {
  BOOKING_CONTENT_SEARCH_LOADING,
  BOOKING_CONTENT_SEARCH_LOADED,
  BOOKING_CONTENT_MORE_SEARCH_LOADED,
  BOOKING_CONTENT_SEARCH_ERROR,
  BOOKING_CONTENT_SEARCH_CLEAR_RESULTS,
  CATEGORIES_LOADED,
  CATEGORIES_ERROR,
  CATEGORIES_LOADING,
  CITY_SEARCH_COMPLETED,
  CITY_SEARCH_LOADING,
  CITY_SEARCH_ERROR,
  REVERSE_GEOCODE_ERROR,
  REVERSE_GEOCODE_LOADING,
  REVERSE_GEOCODE_COMPLETED,
} from './mutation-types';

export const mutations = {
  [BOOKING_CONTENT_SEARCH_LOADING](state, payload = true) {
    state.bookingContent = {
      ...state.bookingContent,
      isLoading: payload,
    };
  },
  [BOOKING_CONTENT_SEARCH_LOADED](
    state,
    {
      city,
      items,
      total,
      latitude,
      longitude,
      searchText,
      minAvailabilityDate,
      maxAvailabilityDate,
      minPrice,
      maxPrice,
      subCategories,
      faceMaskRequired,
      socialDistancingEnforced,
      intensifiedCleaning,
    } = {
      city: '',
      items: [],
      total: 0,
      latitude: 0,
      longitude: 0,
      searchText: '',
      minAvailabilityDate: '',
      maxAvailabilityDate: '',
      minPrice: '',
      maxPrice: '',
      subCategories: [],
      faceMaskRequired: false,
      socialDistancingEnforced: false,
      intensifiedCleaning: false,
    }
  ) {
    state.bookingContent = {
      ...state.bookingContent,
      city: city,
      latitude: latitude,
      longitude: longitude,
      searchText: searchText,
      minAvailabilityDate: minAvailabilityDate,
      maxAvailabilityDate: maxAvailabilityDate,
      minPrice: minPrice,
      maxPrice: maxPrice,
      subCategories: subCategories,
      items: items,
      total: total,
      error: null,
      faceMaskRequired: faceMaskRequired,
      socialDistancingEnforced: socialDistancingEnforced,
      intensifiedCleaning: intensifiedCleaning,
    };
  },
  [BOOKING_CONTENT_MORE_SEARCH_LOADED](state, { items } = { items: [] }) {
    state.bookingContent = {
      ...state.bookingContent,
      items: [...state.bookingContent.items, ...items],
    };
  },
  [BOOKING_CONTENT_SEARCH_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.bookingContent = {
      ...state.bookingContent,
      error: error,
    };
  },
  [BOOKING_CONTENT_SEARCH_CLEAR_RESULTS](state) {
    state.bookingContent = {
      ...state.bookingContent,
      city: null,
      items: null,
      total: null,
      searchText: null,
      minAvailabilityDate: null,
      maxAvailabilityDate: null,
      minPrice: null,
      maxPrice: null,
      subCategories: null,
      faceMaskRequired: false,
      socialDistancingEnforced: false,
      intensifiedCleaning: false,
    };
  },
  [CATEGORIES_LOADED](state, { categories } = { categories: [] }) {
    state.allCategories = {
      ...state.allCategories,
      items: [...categories],
    };
  },
  [CATEGORIES_LOADING](state, payload = true) {
    state.allCategories = {
      ...state.allCategories,
      isLoading: payload,
    };
  },
  [CATEGORIES_ERROR](state, { error } = { error: 'Error: Unknown Error' }) {
    state.allCategories = {
      ...state.allCategories,
      value: null,
      error: error,
    };
  },
  [CITY_SEARCH_COMPLETED](state, { result } = { result: {} }) {
    state.citySearch = { ...state.citySearch, result: { ...result } };
  },
  [CITY_SEARCH_LOADING](state, payload = true) {
    state.citySearch = { ...state.citySearch, isLoading: payload };
  },
  [CITY_SEARCH_ERROR](state, { error } = { error: 'Error: Unknown error' }) {
    state.citySearch = {
      ...state.citySearch,
      result: null,
      error: error,
    };
  },
  [REVERSE_GEOCODE_COMPLETED](state, { result } = { result: {} }) {
    state.reverseGeocode = { ...state.reverseGeocode, result: { ...result } };
  },
  [REVERSE_GEOCODE_LOADING](state, payload = true) {
    state.reverseGeocode = { ...state.reverseGeocode, isLoading: payload };
  },
  [REVERSE_GEOCODE_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.reverseGeocode = {
      ...state.reverseGeocode,
      result: null,
      error: error,
    };
  },
};
