export const getters = {
  hasRides: (state) =>
    !state.transportBookingContent.isLoading &&
    !!state.transportBookingContent.value &&
    !!state.transportBookingContent.value.length,
  searchResults: (state) => state.transportBookingContent,
  hasFromSearchResult: (state) =>
    !state.fromSearch.isLoading && state.fromSearch.result !== null,
  hasToSearchResult: (state) =>
    !state.toSearch.isLoading && state.toSearch.result !== null,
  hasFromUserLocationResult: (state) =>
    !state.fromSearch.isLoading && state.fromSearch.userLocation !== null,
  hasToUserLocationResult: (state) =>
    !state.toSearch.isLoading && state.toSearch.userLocation !== null,
  gtSearchQuery: (state) => {
    const {
      startLocation,
      endLocation,
      startTime,
      passengers,
      polling,
      minPrice,
      maxPrice,
      currency,
      vehicleSize,
    } = state.transportBookingContent;
    return {
      'start-location': startLocation,
      'end-location': endLocation,
      'start-time': startTime,
      passengers: passengers,
      polling: polling,
      'min-price': minPrice,
      'max-price': maxPrice,
      currency: currency,
      'vehicle-size': vehicleSize,
    };
  },
};
