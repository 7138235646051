import * as mutationTypes from './mutation-types';

export const mutations = {
  [mutationTypes.SAVED_PAYMENT_METHODS_LOADED](
    state,
    { cards } = { cards: [] }
  ) {
    state.savedPaymentMethods = { ...state.savedPaymentMethods, cards };
  },
  [mutationTypes.SAVED_PAYMENT_METHODS_LOADING](state, payload = true) {
    state.savedPaymentMethods = {
      ...state.savedPaymentMethods,
      isLoading: payload,
    };
  },
  [mutationTypes.SAVED_PAYMENT_METHODS_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.savedPaymentMethods = {
      ...state.savedPaymentMethods,
      error: error,
    };
  },
};
