export const state = () => ({
  details: {
    value: null,
    error: null,
    isLoading: false,
  },
  userForm: {
    selectedDateRange: null,
    selectedPasses: [],
    USDestinationConfirmed: false,
    contacts: [],
  },
});
