export const BOOKING_CONTENT_SEARCH_LOADING = 'BOOKING_CONTENT_SEARCH_LOADING';
export const BOOKING_CONTENT_SEARCH_LOADED = 'BOOKING_CONTENT_SEARCH_LOADED';
export const BOOKING_CONTENT_MORE_SEARCH_LOADED =
  'BOOKING_CONTENT_MORE_SEARCH_LOADED';
export const BOOKING_CONTENT_SEARCH_ERROR = 'BOOKING_CONTENT_SEARCH_ERROR';
export const BOOKING_CONTENT_SEARCH_CLEAR_RESULTS =
  'BOOKING_CONTENT_SEARCH_CLEAR_RESULTS';
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';
export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const CITY_SEARCH_COMPLETED = 'CITY_SEARCH_COMPLETED';
export const CITY_SEARCH_ERROR = 'CITY_SEARCH_ERROR';
export const CITY_SEARCH_LOADING = 'CITY_SEARCH_LOADING';
export const REVERSE_GEOCODE_ERROR = 'REVERSE_GEOCODE_ERROR';
export const REVERSE_GEOCODE_LOADING = 'REVERSE_GEOCODE_LOADING';
export const REVERSE_GEOCODE_COMPLETED = 'REVERSE_GEOCODE_COMPLETED';
