<template>
  <v-row no-gutters class="mx-n3">
    <CategoryComponent
      :catalogType="catalog.type"
      :isFeatured="catalog.featured"
      :title="catalog.title"
      :items="catalog.items"
      :seeAllQueryParams="getSeeAllSearchParams(catalog)"
      displayPage="displayPage"
      :showSeeAllBtn="true"
    />
  </v-row>
</template>

<script>
import CategoryComponent from './CategoryComponent';

export default {
  name: 'CategoryGroupContainer',
  components: {
    CategoryComponent,
  },
  props: {
    catalog: {
      type: Object,
      default: () => {},
    },

    displayPage: {
      type: String,
      default: '',
    },

    showSeeAllBtn: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getSeeAllSearchParams(catalog) {
      return !this.showSeeAllBtn
        ? null
        : !!catalog.groupSearch
        ? catalog.groupSearch.searchParams
        : null;
    },
  },
};
</script>
