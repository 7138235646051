export const SET_SEARCH_TIMESTAMP = 'SET_SEARCH_TIMESTAMP';
export const RIDES_LOADING = 'RIDES_LOADING';
export const RIDES_LOADED = 'RIDES_LOADED';
export const RIDES_ERROR = 'RIDES_ERROR';
export const RIDES_CLEAR = 'RIDES_CLEAR';
export const GT_LOCATION_FROM_SEARCH_LOADING =
  'GT_LOCATION_FROM_SEARCH_LOADING';
export const GT_LOCATION_FROM_SEARCH_COMPLETED =
  'GT_LOCATION_FROM_SEARCH_COMPLETED';
export const GT_LOCATION_FROM_SEARCH_ERROR = 'GT_LOCATION_FROM_SEARCH_ERROR';
export const GT_LOCATION_TO_SEARCH_LOADING = 'GT_LOCATION_TO_SEARCH_LOADING';
export const GT_LOCATION_TO_SEARCH_COMPLETED =
  'GT_LOCATION_TO_SEARCH_COMPLETED';
export const GT_LOCATION_TO_SEARCH_ERROR = 'GT_LOCATION_TO_SEARCH_ERROR';
export const USER_TO_REVERSE_GEOCODE_ERROR = 'USER_TO_REVERSE_GEOCODE_ERROR';
export const USER_TO_REVERSE_GEOCODE_LOADING =
  'USER_TO_REVERSE_GEOCODE_LOADING';
export const USER_TO_REVERSE_GEOCODE_COMPLETED =
  'USER_TO_REVERSE_GEOCODE_COMPLETED';
export const USER_FROM_REVERSE_GEOCODE_ERROR =
  'USER_FROM_REVERSE_GEOCODE_ERROR';
export const USER_FROM_REVERSE_GEOCODE_LOADING =
  'USER_FROM_REVERSE_GEOCODE_LOADING';
export const USER_FROM_REVERSE_GEOCODE_COMPLETED =
  'USER_FROM_REVERSE_GEOCODE_COMPLETED';
