<template>
  <v-autocomplete
    class="search-box"
    dense
    solo
    outlined
    clearable
    autofocus
    v-model="model"
    :items="items"
    no-filter
    :loading="citySearch.isLoading"
    :search-input.sync="search"
    color="secondary"
    prepend-inner-icon="mdi-magnify"
    append-icon=""
    :placeholder="$t('home.search_placeholder')"
    return-object
    no-data-text="No Cities Found"
  >
    <template v-slot:prepend-item>
      <v-list-item
        class="item-text"
        ripple
        @click="getUserLocationAndRedirect()"
      >
        <v-list-item-action>
          <v-icon color="secondary">mdi-near-me</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="item-text">{{
            $t('home.current_location')
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template slot="item" slot-scope="{ item }">
      <v-list-item-action>
        <v-icon color="secondary">mdi-map-marker</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="item-text"
          >{{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import HomePageMixins from '../mixins/HomePageMixins';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import { mapboxService } from '@/core/services/MapboxService';

export default {
  name: 'SearchComponent',
  mixins: [HomePageMixins, SelectedContextFlightMixins],

  data() {
    return {
      model: null,
      search: null,
      timer: null,
      pinnedItem: {
        text: null,
        city: null,
        longLat: null,
      },
    };
  },

  methods: {
    searchCityDebounced(cityName) {
      // cancel pending call
      clearTimeout(this.timer);

      // delay new call 400ms
      this.timer = setTimeout(() => {
        this.performCitySearch(cityName);
      }, 400);
    },

    getUserLocationAndRedirect() {
      this.$getLocation().then(
        (coordinates) => {
          this.performReverseGeocode({
            lat: coordinates.lat,
            lng: coordinates.lng,
          });
        },
        (rejected) => {
          //Just to catch the rejection so chrome console doesn't complain
        }
      );
    },

    async createFlightLocationItem(city) {
      try {
        const response = await mapboxService.getCities(city);

        if (response.features && response.features.length > 0) {
          const flightLocation = { ...response.features[0] };
          this.pinnedItem = {
            text: flightLocation.place_name,
            city: flightLocation.text,
            longLat: flightLocation.center,
          };
        }
      } catch (e) {
        this.pinnedItem = {};
      }
    },
  },

  mounted() {
    if (this.flightDestination) {
      setTimeout(() => {
        this.createFlightLocationItem(this.flightDestination.city);
      }, 300);
    }
  },

  watch: {
    search(val) {
      if (!val) {
        return;
      }
      this.searchCityDebounced(val);
    },

    model(val, oldVal) {
      if (val !== oldVal && val) {
        this.$router.push({
          name: 'discoveryWithCity',
          query: {
            city: val.city,
            lng: val.longLat[0],
            lat: val.longLat[1],
          },
        });
      }
    },

    reverseGeocodeResult(val, oldVal) {
      if (!!val && val !== oldVal) {
        for (let place of val.features) {
          if (place.place_type[0] == 'place') {
            this.$router.push({
              name: 'discoveryWithCity',
              query: {
                city: place.text,
                lng: place.center[0],
                lat: place.center[1],
              },
            });
          }
        }
      }
    },

    selectedContextFlight(val) {
      if (!val) {
        return;
      }
      this.createFlightLocationItem(this.flightDestination.city);
    },
  },

  computed: {
    items() {
      let items = [];
      if (!this.hasCitySearchResult || !this.search) {
        items = [];
      } else {
        items = this.citySearch.result.features.map((result) => ({
          city: result.text,
          longLat: result.center,
          text: result.place_name,
        }));
      }
      if (this.selectedContextFlight && this.pinnedItem.text) {
        items = [this.pinnedItem, ...items];
      }
      return items;
    },

    reverseGeocodeResult() {
      return this.reverseGeocode.result;
    },

    flightDestination() {
      return this.selectedContextFlight
        ? this.selectedContextFlight.destination
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  width: 100%;
  max-width: 480px;
}

.item-text {
  font-size: 14px !important;
}
</style>
