export const getters = {
  configsReady: (state) => {
    return state.configs && state.configs.ready;
  },
  termsAndConditionsUrl: (state) => {
    return state.configs && state.configs.terms_and_conditions
      ? state.configs.terms_and_conditions
      : '';
  },
  helpAndFeedbackUrl: (state) => {
    return state.configs && state.configs.help_and_feedback
      ? state.configs.help_and_feedback
      : '';
  },
  privacyPolicyUrl: (state) => {
    return state.configs && state.configs.privacy_policy
      ? state.configs.privacy_policy
      : '';
  },
  isEmbedded: (state) => state.embedded,
  isMobileWrapp: (state) => state.isMobileWrapp,
  travelerId: (state) => state.travelerId,
  displayTestData: (state) => state.displayTestData,
  showFlightPreorderAvailability: (state) =>
    state.showFlightPreorderAvailability,
  searchFlightByCodeShareNumber: (state) => state.searchFlightByCodeShareNumber,
  shopOnlyMode: (state) => state.shopOnlyMode,
  checkConciergeV3: (state) => state.conciergeV3,
  isShopV4: (state) => state.isShopV4,
};
