<template>
  <PageSection
    class="footer-concierge-stages"
    data-cy="footer-concierge-stages"
  >
    <template #title>
      {{ $t('shell.on_diff_trip') }}
    </template>
    <template #subtitle>
      {{ $t('shell.plan_trip_any_stage') }}
    </template>
    <template #default>
      <v-row
        justify="center"
        dense
        v-for="(stage, i) in stagesDisplayList"
        :key="stage.title + i"
      >
        <v-col class="stage" cols="12" sm="6" md="4">
          <v-btn
            :data-cy="`stage-select-${stage.title.replace(/\s+/g, '')}`"
            :data-test="`is-selected-${i === activeStage}`"
            block
            class="stage-card"
            :height="76"
            @click="goToStage(i)"
            :color="i === activeStage ? 'var(--v-primary-base)' : 'white'"
            :class="{ highlight: i === activeStage }"
          >
            <div class="stage-card-content d-flex flex-column">
              <span class="mr-auto font-weight-medium">{{ stage.title }}</span>
              <span class="mr-auto font-weight-light">{{ stage.desc }}</span>
              <span class="ml-auto mt-auto chevron">&rsaquo;</span>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </PageSection>
</template>

<script>
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';
import PageSection from '@/core/components/PageSection';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';

export default {
  name: 'FooterConciergeStages',
  components: { PageSection },
  mixins: [ConciergeHomeMixins, FlightsMixins],

  data() {
    return {
      activeStage: 0,
      stagesDisplayList: [
        {
          title: this.$t('shell.planning'),
          desc: this.$t('shell.planning_desc'),
        },
        {
          title: this.$t('shell.departure'),
          desc: this.$t('shell.departure_desc'),
        },
        {
          title: this.$t('shell.on_board'),
          desc: this.$t('shell.on_board_desc'),
        },
        {
          title: this.$t('shell.arrival'),
          desc: this.$t('shell.arrival_desc'),
        },
      ],
    };
  },

  created() {
    if (!this.hasStages) {
      this.loadStages(
        this.carrierCodeAndFlightNumber,
        this.formattedDepartureDate,
        this.originIataCode
      );
    }
  },

  computed: {
    currentStages() {
      return this.stages || [];
    },
  },

  watch: {
    ['$i18n.locale'](lang) {
      this.stagesDisplayList = [
        {
          title: this.$t('shell.planning'),
          desc: this.$t('shell.planning_desc'),
        },
        {
          title: this.$t('shell.departure'),
          desc: this.$t('shell.departure_desc'),
        },
        {
          title: this.$t('shell.on_board'),
          desc: this.$t('shell.on_board_desc'),
        },
        {
          title: this.$t('shell.arrival'),
          desc: this.$t('shell.arrival_desc'),
        },
      ];
    },

    selectedContextFlight: {
      async handler() {
        if (!!this.$route.query.stage) {
          this.updateStage(null);
          this.loadStages(
            this.carrierCodeAndFlightNumber,
            this.formattedDepartureDate,
            this.originIataCode
          );
        }
      },
    },

    currentStage: {
      immediate: true,
      handler(currentStage) {
        this.updateActiveStage(currentStage);
      },
    },
  },

  methods: {
    updateActiveStage(stage) {
      this.activeStage = stage;
    },

    async goToStage(index) {
      const foundStage = this.currentStages.find(
        (stage) => stage.stage === index
      );
      if (foundStage && this.currentStage !== foundStage.stage) {
        this.updateStage(foundStage);
      }

      window.scrollTo({ top: 0, behavior: 'instant' });
    },
  },
};
</script>

<style scoped lang="scss">
.footer-concierge-stages {
  margin-bottom: 100px;
  padding-top: 124px;
  padding-bottom: 124px;
  background-color: var(--v-grey1-base);

  ::v-deep.stage-card {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    margin: 0 0 10px;
    padding: 0 5px;
    color: var(--v-primary-base);

    &.highlight {
      color: white;
    }

    .v-btn__content {
      width: 100%;
      height: 100%;
    }

    &-content {
      width: 100%;
      height: 100%;
      padding: 10px;

      .chevron {
        font-size: large;
      }
    }
  }
}
</style>
