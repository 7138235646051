export const ROUTE_NAMES = {
  SHOP_LANDING: 'shop-landing',
  SHOP_OFFERING_DETAIL: 'shop-offering-detail',
  SHOP_FLIGHT_REDIRECT_LANDING: 'shop-flight-redirect-landing',
  SHOP_FLIGHT_REDIRECT_LISTING: 'shop-flight-redirect-listing',
  SHOP_CART: 'shop-cart',
  SHOP: 'shop',
  SHOP_CHECKOUT: 'shop-checkout',
  SHOP_CHECKOUT_PAYMENT: 'shop-checkout-payment',
  SHOP_CONFIRMATION: 'shop-confirmation',
  SHOP_CATEGRIES_LIST: 'shop-categories-list',
  SHOP_CATEGRIES_SEARCH: 'shop-categories-search',
  SHOP_PRODUCT_DETAILS: 'shop-product-details',
};

const shopRoutes = [
  {
    path: '/shop/flight/:flightNumber/:originCode/:year/:month/:day',
    name: ROUTE_NAMES.SHOP_FLIGHT_REDIRECT_LANDING,
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Shop" */ '@/modules/product/shop/ShopFlightRedirectPage.vue'
      ),
  },
  {
    path: '/offering/flight/:flightNumber/:originCode/:year/:month/:day',
    redirect: '/shop/flight/:flightNumber/:originCode/:year/:month/:day',
  },
  {
    path: '/offering/:id',
    redirect: '/shop/:id/tag_group',
  },
  {
    path: '/shop',
    name: ROUTE_NAMES.SHOP,
    component: () =>
      import(
        /* webpackChunkName: "Shop" */ '@/modules/product/shop/ShopFlightRedirectPage.vue'
      ),
  },
  {
    path: '/offering/:id/listing/:categoryId/offering-item/:offeringId',
    redirect: '/shop/:id/tag_group/offering/:offeringId',
  },
  {
    path: '/shop/:id',
    component: () =>
      import(/* webpackChunkName: "Shop" */ '@/modules/product/shop/Shop.vue'),

    children: [
      {
        path: 'offering/:offeringId',
        name: ROUTE_NAMES.SHOP_PRODUCT_DETAILS,
        component: () =>
          import(
            /* webpackChunkName: "Shop" */ '@/modules/product/shop/features/ShopProductContainer'
          ),
      },
      {
        path: '',
        redirect: '/404',
      },
      {
        path: 'tag_group',
        component: () =>
          import(
            /* webpackChunkName: "Shop" */ '@/modules/product/shop/ShopLandingPage.vue'
          ),
        children: [
          {
            path: '',
            name: ROUTE_NAMES.SHOP_LANDING,
            component: () =>
              import(
                /* webpackChunkName: "Shop" */ '@/modules/product/shop/ShopCategoriesListPage'
              ),
          },
          {
            path: 'search',
            name: ROUTE_NAMES.SHOP_CATEGRIES_SEARCH,
            component: () =>
              import(
                /* webpackChunkName: "Shop" */ '@/modules/product/shop/CategoriesSearchPage'
              ),
          },
          {
            path: 'offering/:offeringId',
            name: ROUTE_NAMES.SHOP_OFFERING_DETAIL,
            meta: {
              showModal: true,
            },
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Shop" */ '@/modules/product/shop/features/OfferingDetail.vue'
              ),
          },
        ],
      },
      {
        path: 'v3',
        component: () =>
          import(
            /* webpackChunkName: "Shop" */ '@/modules/product/shop/ShopCheckoutPage.vue'
          ),

        children: [
          {
            path: 'checkout',
            component: () =>
              import(
                /* webpackChunkName: "Shop" */ '@/modules/product/shop/features/ShopCheckout/ShopCheckout.vue'
              ),
            meta: {
              disableFlightsSelector: true,
            },
            children: [
              {
                path: '',
                name: ROUTE_NAMES.SHOP_CHECKOUT,
                component: () =>
                  import(
                    /* webpackChunkName: "Shop" */ '@/modules/product/shop/features/ShopCheckout/ShopCheckoutForm.vue'
                  ),
              },
              {
                path: 'payment',
                name: ROUTE_NAMES.SHOP_CHECKOUT_PAYMENT,
                component: () =>
                  import(
                    /* webpackChunkName: "Shop" */ '@/modules/product/shop/features/ShopCheckout/ShopCheckoutPayment.vue'
                  ),
              },
            ],
          },
          {
            path: 'confirmation',
            name: ROUTE_NAMES.SHOP_CONFIRMATION,
            component: () =>
              import(
                /* webpackChunkName: "Shop" */ '@/modules/product/shop/features/ShopCheckoutConfirmation/ShopCheckoutConfirmation'
              ),
            meta: {
              disableFlightsSelector: true,
            },
          },
          {
            path: '*',
            redirect: { name: ROUTE_NAMES.SHOP_LANDING },
          },
        ],
      },
      {
        path: '*',
        redirect: '/shop/:id/tag_group',
      },
    ],
  },
];

export default shopRoutes;
