const WelcomeLandingPage = () => import('./WelcomeLandingPage');
const WelcomeHomeComponent = () => import('./features/WelcomeHomeComponent');
const welcomeRoute = {
  path: '/welcome',
  component: WelcomeLandingPage,
  children: [
    {
      path: '',
      name: 'welcome',
      component: WelcomeHomeComponent,
    },
  ],
};

export default welcomeRoute;
