import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';
import { getters } from './getters';
import SearchStoreModule from '../search/index';

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: state,
  mutations,
  actions,
  getters,
  modules: {
    search: SearchStoreModule,
  },
};

export { actions, mutations, getters };
