import {
  CATALOG_GROUPS_ERROR,
  CATALOG_GROUPS_LOADED,
  CATALOG_GROUPS_LOADING,
  NO_FLIGHT_CATALOG_GROUPS_ERROR,
  NO_FLIGHT_CATALOG_GROUPS_LOADED,
  NO_FLIGHT_CATALOG_GROUPS_LOADING,
} from './mutation-types';

export const mutations = {
  [CATALOG_GROUPS_LOADED](
    state,
    { groups, city, latitude, longitude } = {
      groups: {},
      city: null,
      longitude: null,
      latitude: null,
    }
  ) {
    state.catalogGroups = {
      ...state.catalogGroups,
      value: { ...groups },
      city,
      latitude,
      longitude,
    };
  },
  [CATALOG_GROUPS_LOADING](state, payload = true) {
    state.catalogGroups = {
      ...state.catalogGroups,
      isLoading: payload,
    };
  },
  [CATALOG_GROUPS_ERROR](
    state,
    { error, errorResponse } = {
      error: 'Error: Unknown error',
      errorResponse: 'Error: Unknown error',
    }
  ) {
    state.catalogGroups = {
      ...state.catalogGroups,
      value: null,
      error: error,
      errorResponse: errorResponse,
    };
  },
  [NO_FLIGHT_CATALOG_GROUPS_LOADING](state) {
    state.noFlightCatalogGroups = [];
  },
  [NO_FLIGHT_CATALOG_GROUPS_LOADED](state, { data, city }) {
    state.noFlightCatalogGroups.push({ city, data });
  },
  [NO_FLIGHT_CATALOG_GROUPS_ERROR](
    state,
    { city, error, errorResponse } = {
      error: 'Error: Unknown error',
      errorResponse: 'Error: Unknown error',
    }
  ) {
    state.noFlightCatalogGroups.push({
      city,
      error,
      errorResponse,
      data: [],
    });
  },
};
