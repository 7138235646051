import * as mutationTypes from './mutation-types';
import { v4 as uuidv4 } from 'uuid';

export const mutations = {
  [mutationTypes.ADD_ITEM_TO_CART](
    state,
    { shopId, item, orderId = uuidv4() }
  ) {
    let newCartITems = [];

    // assign a unique order item id
    item.orderId = orderId;

    if (state.shopCarts[shopId]) {
      newCartITems = [...state.shopCarts[shopId], item];
    } else {
      newCartITems = [item];
    }

    state.shopCarts = {
      ...state.shopCarts,
      [shopId]: newCartITems,
    };
  },
  [mutationTypes.UPDATE_ITEM_IN_CART](state, { shopId, item, orderId }) {
    if (state.shopCarts[shopId]) {
      const cartItems = [...state.shopCarts[shopId]];
      const itemIndex = cartItems.findIndex(
        (cartItem) => cartItem.orderId === orderId
      );
      if (itemIndex > -1) {
        item.orderId = orderId;
        cartItems[itemIndex] = item;
        state.shopCarts = {
          ...state.shopCarts,
          [shopId]: cartItems,
        };
      }
    }
  },
  [mutationTypes.REMOVE_ITEM_FROM_CART](state, { shopId, orderId }) {
    if (state.shopCarts[shopId]) {
      const cartItems = [...state.shopCarts[shopId]];
      const updatedCart = cartItems.filter((item) => {
        return item.orderId !== orderId;
      });

      state.shopCarts = {
        ...state.shopCarts,
        [shopId]: updatedCart,
      };
    }
  },
  [mutationTypes.CLEAR_CART](state, { shopId }) {
    if (state.shopCarts[shopId]) {
      state.shopCarts = {
        ...state.shopCarts,
        [shopId]: [],
      };
    }
  },
};
