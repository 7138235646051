<template>
  <v-sheet
    v-if="isOpen"
    class="cookiebanner font-weight-medium"
    color="lighten-1"
    elevation="5"
  >
    <div
      class="pa-6 d-flex align-center justify-center justify-sm-space-around flex-column flex-sm-row"
    >
      <p class="message">
        {{ $t('notifications.privacy_policy_message')
        }}<a class="privacy-text" :href="privacyPolicyUrl" target="_blank">{{
          $t('notifications.privacy_policy')
        }}</a>
      </p>
      <v-btn
        large
        color="primary"
        @click="accept"
        data-cy="cookie-policy-accept"
        max-width="fit-content"
        >{{ acceptText }}</v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  name: 'CookieBanner',
  props: {
    acceptText: {
      type: String,
      default: 'Accept',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    if (typeof this.getGDPRStatus() == 'undefined') {
      this.isOpen = true;
    }
  },
  computed: {
    privacyPolicyUrl() {
      return this.$store.getters['privacyPolicyUrl'];
    },
  },
  methods: {
    getGDPRStatus() {
      return Cookies.get('cookieUseConsent');
    },
    accept() {
      this.isOpen = false;
      Cookies.set('cookieUseConsent', 'accepted', { expires: 365 });
    },
  },
};
</script>

<style lang="scss" scoped>
.cookiebanner {
  margin: 1em;
  border: 1px solid #e8e6e1;
  border-radius: 8px;
  z-index: 999;
  position: fixed;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: auto;
  width: 90vw;

  @media (min-width: map-get($grid-breakpoints, md)) {
    z-index: 6;
  }
}
.message {
  font-size: 14px;
  width: 100%;
  padding: 0 10px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    width: 60%;
    padding: 0;
    margin-bottom: 0;
  }
}
.privacy-text {
  color: #27241d;
  font-weight: bold;
  text-decoration: none;
}
</style>
