<template>
  <v-container fluid class="px-6 py-0">
    <v-row class="pt-2 pb-10 title" no-gutters justify="center">
      <v-col @click="goToForm" align-self="start" cols="1"
        ><v-icon>mdi-chevron-left</v-icon></v-col
      >
      <v-col class="text-align-center" cols="11"
        ><span>{{ $t('flights.airlines_title') }}</span></v-col
      >
    </v-row>
    <v-row class="pb-6" no-gutters>
      <v-text-field
        outlined
        autofocus
        hide-details
        flat
        dense
        v-model="queryModel"
        prepend-inner-icon="mdi-magnify"
        :placeholder="$t('search')"
      ></v-text-field>
    </v-row>
    <AirlineOptions
      :allAirlines="sortedAirlines"
      @select="selectAirline"
      :queryKeyword="queryModel"
    >
    </AirlineOptions>
  </v-container>
</template>

<script>
import AirlineOptions from './AirlineOptions';
import airlineList from '@/modules/flights/airlines.js';

export default {
  name: 'FlightAirlineSearch',
  components: { AirlineOptions },
  props: {
    currentAirline: String,
  },
  data() {
    return {
      queryModel: '',
      airlineOptions: airlineList.airlines,
    };
  },

  methods: {
    selectAirline(info) {
      this.$emit('update', info);
      this.goToForm();
    },

    goToForm() {
      this.$emit('hideOptions');
    },

    sortAirlines(airlines) {
      const airlinePartnerIndex = airlines.findIndex(
        (airline) =>
          airline.name.toLowerCase() === this.currentAirline.toLowerCase()
      );
      if (airlinePartnerIndex > 0) {
        let currentAirline = airlines.splice(airlinePartnerIndex, 1)[0];
        airlines.unshift(currentAirline);
      }
      return airlines;
    },
  },

  computed: {
    sortedAirlines() {
      return this.sortAirlines(this.airlineOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  @include font-size(20, 24, 600);
}

.text-align-center {
  text-align: center;
}

.fill-height {
  height: 100%;
}
</style>
