import {
  CUSTOM_SEARCH_LOADED,
  CUSTOM_SEARCH_ERROR,
  CUSTOM_SEARCH_LOADING,
  CUSTOM_MORE_SEARCH_LOADED,
} from './mutation-types';

export const mutations = {
  [CUSTOM_SEARCH_LOADED](state, { items, total } = { items: [], total: 0 }) {
    state.customSearch = {
      ...state.customSearch,
      items: items,
      total: total,
    };
  },
  [CUSTOM_MORE_SEARCH_LOADED](state, { items } = { items: [] }) {
    state.customSearch = {
      ...state.customSearch,
      items: [...state.customSearch.items, ...items],
    };
  },
  [CUSTOM_SEARCH_LOADING](state, payload = true) {
    state.customSearch = {
      ...state.customSearch,
      isLoading: payload,
    };
  },
  [CUSTOM_SEARCH_ERROR](state, { error } = { error: 'Error: Unknown error' }) {
    state.customSearch = {
      ...state.customSearch,
      error: error,
    };
  },
};
