export const getters = {
  hasResentConfirmation: (state) =>
    !!state.emailConfirmation &&
    !state.emailConfirmation.isLoading &&
    state.emailConfirmation.value,
  hasConfirmationError: (state) =>
    !!state.emailConfirmation && state.emailConfirmation.error !== null,
  hasPastOrders: (state) =>
    !!state.pastOrders &&
    !state.pastOrders.isLoading &&
    !!state.pastOrders.orders &&
    !!state.pastOrders.orders.length,
  pastOrders: (state) => state.pastOrders.orders,
};
