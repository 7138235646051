export const getLangSync = (lang = 'en') => {
  let messages = {};
  const context = require.context(
    '@',
    true,
    /locales\/[A-Za-z0-9-_,\s]+\.json$/i
  );

  context.keys().forEach((key) => {
    const re = new RegExp(`${lang}.json`, 'i');
    const matched = key.match(re);
    if (matched && matched.length > 0) {
      messages = { ...messages, ...context(key) };
    }
  });
  return messages;
};
