<script>
import * as Sentry from '@sentry/vue';
import FlightMixins from '@/modules/flights/mixins/FlightsMixins';
import EnvUtils from '@/core/utils/environment';
import { appendFlightInfoToURL } from '@/core/utils/flightShopHelpers';

export default {
  name: 'ConciergeHomeMixins',
  mixins: [FlightMixins],

  created() {
    this.checkFFs();
  },

  data() {
    return {
      codeShareSnackbar: false,
      showTagsFF: false,
    };
  },

  methods: {
    checkFFs() {
      this.checkTagsFF();
    },

    async loadConciergeOrDiscover(stageId) {
      if (this.selectedContextFlight) {
        if (
          this.selectedContextFlight.isCodeShare &&
          this.selectedContextFlight.codeShares.length
        ) {
          this.codeShareSnackbar = true;
        }
        await this.loadConciergePageWithFlightInContext(stageId);
      } else {
        await this.loadConciergePageWIthNoFlightInContext();
      }
    },

    loadConciergePageWithFlightInContext(stageId = null) {
      const promises = [
        this.loadConciergeContent(
          this.carrierCodeAndFlightNumber,
          this.formattedDepartureDate,
          this.originIataCode,
          stageId || this.$route.params.stageId
        ),
      ];

      return Promise.all(promises);
    },

    loadConciergePageWIthNoFlightInContext() {
      return this.loadConciergeContent();
    },

    async checkTagsFF() {
      this.showTagsFF = await this.$ldclient.variation(
        'show-concierge-tags',
        false,
        true
      );
      this.$ldclient.$on('change:show-concierge-tags', (value) => {
        this.showTagsFF = value;
      });
    },

    async loadConciergeContent(flight, date, city, stage) {
      const shopV4FF = this.$store.state.isShopV4;

      const dispatchLoad = () =>
        this.$store.dispatch(`concierge/loadConciergeModuleItems`, {
          flight,
          date,
          city,
          stage,
          shopV4FF,
        });

      return dispatchLoad();
    },

    loadStages(flight, departureDate, iataCode) {
      const dispatchLoad = () => {
        const shopV4FF = this.$store.state.isShopV4;

        this.$store.dispatch('concierge/loadConciergeStages', {
          flight,
          departureDate: departureDate,
          iataCode: iataCode,
          shopV4FF,
        });
      };

      return dispatchLoad();
    },

    updateStage(stage) {
      return this.$store.dispatch('concierge/updateStage', stage);
    },

    makeCTAPathResourceUrl(resourceUrlString) {
      if (this.isShopUrl(resourceUrlString)) {
        return this.makeShopV3CtaUrl(resourceUrlString);
      } else return this.makeResourceUrl(resourceUrlString);
    },

    makeResourceUrl(resourceUrlString) {
      if (Boolean(resourceUrlString)) {
        if (resourceUrlString.startsWith('/')) {
          return this.prependPathPrefixOnPath(resourceUrlString);
        } else {
          let resourceUrl;
          try {
            resourceUrl = this.prependPathPrefixOnUrl(resourceUrl);
          } catch (e) {
            Sentry.captureMessage(
              'Trying to navigate to a malformed URL concierge hook CTAPath. Please check configurations.',
              {
                extra: {
                  resourceUrl: resourceUrlString,
                },
              }
            );
            return resourceUrlString;
          }
          return this.privateIsResourceOnLocalUsingDevOrSameOrigin(resourceUrl);
        }
      } else {
        Sentry.captureMessage(
          'Trying to navigate to empty concierge hook CTAPath. Please check configurations.'
        );
        return '';
      }
    },

    isShopOfferingUrl(resourceUrlString) {
      // to make this code better, use a regexp to determine that the string is formatted like /shop/sto_ ... /ite_
      return (
        resourceUrlString.includes('sto_') && resourceUrlString.includes('ite_')
      );
    },

    isShopUrl(resourceUrlString) {
      // to make this code better, use a regexp to determine that the string is formatted like /shop/sto_ ... /ite_
      return (
        resourceUrlString.includes('sto_') ||
        resourceUrlString.includes('/shop')
      );
    },

    makeShopV3CtaUrl(resourceUrlString) {
      resourceUrlString = this.pathPrefix(resourceUrlString);
      let resourceUrl = new URL(resourceUrlString, location.origin);
      if (this.isShopUrl(resourceUrlString)) {
        resourceUrl = new URL(
          resourceUrlString,
          EnvUtils.getNuxtBaseHostnameWithProtocol()
        );

        const selectedContextFlight = this.$store.state.flights
          .selectedContextFlight;
        if (selectedContextFlight) {
          resourceUrl = appendFlightInfoToURL(
            resourceUrl,
            selectedContextFlight
          );
        }

        return resourceUrl.href;
      }

      return this.privateIsResourceOnLocalUsingDevOrSameOrigin(resourceUrl);
    },

    makeShopV3Url(resourceUrlString) {
      if (!this.isShopUrl(resourceUrlString)) {
        return new URL(resourceUrlString);
      }

      const offeringPath = '/offering';
      let offeringIdIndexOnString = resourceUrlString.indexOf('/ite_');
      let url;
      if (offeringIdIndexOnString > -1) {
        if (resourceUrlString.includes('/offering')) {
          url = new URL(resourceUrlString);
        } else {
          url = new URL(
            resourceUrlString.slice(0, offeringIdIndexOnString) +
              offeringPath +
              resourceUrlString.slice(offeringIdIndexOnString)
          );
        }
        // Safari doesn't support lookbehind operator '?<'
        // url = new URL(
        //   resourceUrlString.replace(
        //     new RegExp(`(?<=^.{${offeringIdIndexOnString}})`),
        //     offeringPath
        //   )
        // );
      } else {
        url = new URL(resourceUrlString);
        const pathNames = url.pathname.split('/');
        let updatedPathname = '';
        pathNames.forEach((path, i) => {
          if (
            i === pathNames.length - 1 &&
            !resourceUrlString.includes('catalog/')
          ) {
            updatedPathname += 'catalog/';
          }
          updatedPathname += path + '/';
        });
        url.pathname = updatedPathname;
      }

      url.host = EnvUtils.getNuxtBaseHostname();
      url.protocol = window.location.protocol;
      return url;
    },

    prependPathPrefixOnUrl(newUrl) {
      newUrl.pathname = this.prependPathPrefixOnPath(newUrl.pathname);
      return newUrl;
    },

    prependPathPrefixOnPath(path) {
      if (path.startsWith('/shop')) {
        if (!path.includes('/sto_')) {
          const selectedContextFlight = this.$store.state.flights
            .selectedContextFlight;

          if (selectedContextFlight) {
            let resourceUrl = new URL(path, location.origin);
            const selectedContextFlight = this.$store.state.flights
              .selectedContextFlight;
            resourceUrl = appendFlightInfoToURL(
              resourceUrl,
              selectedContextFlight
            );
            path = resourceUrl.pathname + resourceUrl.search;
          }
        }

        return this.pathPrefix(path);
      }
      return path;
    },

    pathPrefix(path) {
      try {
        return this.$pathPrefix(path);
      } catch (e) {
        return path;
      }
    },

    redirectOrGoToPath(path) {
      if (path && path.startsWith('/') && !this.$isNuxtPath(path)) {
        this.$router.push({ path });
      } else {
        window.location.href = path;
      }
    },

    replaceNewLines(text) {
      return text.replace(/(\\r)*\\n/g, '<br>');
    },

    convertHeroTitleTextV3(text) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return text.replace(/<p>/g, '<p class="text-item">');
      } else {
        return text
          .replace(/p>/g, 'h1>')
          .replace('<h1>', '<h1 class="text-item">');
      }
    },

    convertHeroSubtitleTextV3(text) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return text
          .replace(/p>/g, 'h1>')
          .replace('<h1>', '<h1 class="text-item">');
      } else {
        return text
          .replace(/p>/g, 'h2>')
          .replace('<h2>', '<h2 class="text-item">');
      }
    },

    convertText(text) {
      return text
        .replace(/p>/g, 'h1>')
        .replace('<h1>', '<h1 class="size48-weight300 hero-title">');
    },

    convertHeaderTitle(text) {
      return text.replace(
        /<p>/g,
        '<p class="size20-weight400 hero-title text-uppercase mb-0">'
      );
    },

    convertFeaturedItemTitle(text) {
      return text
        .replace(/p>/g, 'h3>')
        .replace('<h3>', '<h3 class="text-item">');
    },

    convertTextHookBtn(text) {
      return text.replace('<p>', '<p class="mb-0">');
    },

    convertDivider(text) {
      return text
        .replace(/p>/g, 'h3>')
        .replace(
          '<h3>',
          this.$vuetify.breakpoint.smAndDown
            ? '<h3 class="size18-weight500">'
            : '<h3 class="size24-weight500">'
        );
    },

    trimTitles(title, counter) {
      return title.length > counter ? `${title.slice(0, counter)}...` : title;
    },

    privateIsRunningOnLocalhostAndResourceOriginIsDevEnv(resourceUrl) {
      return this.isLocalhost && resourceUrl.origin.includes('web.dev');
    },

    privateIsResourceOriginSameAsLocationOrigin(resourceUrl) {
      return resourceUrl.origin === location.origin;
    },

    privateIsResourceOnLocalUsingDevOrSameOrigin(resourceUrl) {
      if (
        this.privateIsRunningOnLocalhostAndResourceOriginIsDevEnv(
          resourceUrl
        ) ||
        this.privateIsResourceOriginSameAsLocationOrigin(resourceUrl)
      ) {
        return resourceUrl.pathname + resourceUrl.search;
      } else {
        return resourceUrl.href;
      }
    },
  },

  computed: {
    subtitleHtml() {
      return !!this.headerSubtitle
        ? this.replaceNewLines(this.headerSubtitle)
        : '';
    },

    hasConciergeContent() {
      return this.$store.getters[`concierge/hasConciergeModuleContent`];
    },

    hasConciergeModuleContentError() {
      return this.$store.getters[`concierge/hasConciergeModuleContentError`];
    },

    hasConciergeStagesError() {
      return this.$store.getters[`concierge/hasConciergeStagesError`];
    },

    isLocalhost() {
      return location.hostname === 'localhost';
    },

    conciergeStages() {
      return this.$store.state['concierge'].conciergeStages;
    },

    hasStages() {
      return Boolean(this.stages);
    },

    stages() {
      if (!!this.conciergeStages) return this.conciergeStages.stages;
      else return null;
    },

    conciergeHookContent() {
      return this.$store.state['concierge'].conciergeModuleContent;
    },

    headerImage() {
      if (!!this.hasConciergeContent) {
        return this.conciergeHookContent.value.header_image;
      } else return null;
    },

    headerImageMobile() {
      if (!!this.hasConciergeContent) {
        return this.conciergeHookContent.value.header_image_mobile;
      } else return null;
    },

    conciergeGroups() {
      if (!!this.hasConciergeContent) {
        return this.conciergeHookContent.value.groups;
      } else return null;
    },

    conciergeHooks() {
      if (!!this.hasConciergeContent) {
        let hooksArrays = this.conciergeHookContent.value.groups.map(
          (group) => [
            ...group.hooks.map((hook) => ({ ...hook, groupId: group.id })),
          ]
        );
        return [].concat.apply([], hooksArrays);
      } else return null;
    },

    conciergeCategoriesError() {
      if (!!this.hasConciergeModuleContentError)
        return this.conciergeHookContent.error;
      else return null;
    },

    conciergeStagesError() {
      if (!!this.hasConciergeStagesError) return this.conciergeStages.error;
      else return null;
    },

    isLoading() {
      return (
        (!!this.conciergeHookContent && this.conciergeHookContent.isLoading) ||
        this.conciergeCategoriesError !== null
      );
    },

    currentStage() {
      if (!!this.hasConciergeContent) {
        return this.conciergeHookContent.value.stage;
      }
      return null;
    },

    selectedStage() {
      return this.$store.state['concierge'].selectedStage;
    },

    selectedStageId() {
      return this.selectedStage.id;
    },

    conciergeV3() {
      return this.$store.state['conciergeV3'];
    },

    headerTitle() {
      if (!!this.hasConciergeContent) {
        return this.conciergeHookContent.value.title ?? '';
      }
      return null;
    },

    headerSubtitle() {
      if (!!this.hasConciergeContent) {
        return this.conciergeHookContent.value.subtitle;
      }
      return null;
    },

    headerCTAText() {
      if (!!this.hasConciergeContent) {
        return this.conciergeHookContent.value.call_to_action_text;
      }
      return '';
    },

    flightWidgetInformation() {
      if (!!this.hasConciergeContent) {
        return this.conciergeHookContent.value.information;
      }
      return null;
    },
  },
};
</script>
