export const state = () => ({
  emailConfirmation: {
    value: false,
    isLoading: false,
    error: null,
  },
  pastOrders: {
    orders: null,
    error: null,
    isLoading: false,
  },
});
