import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { authAxios } from '@/core/repositories';
import { getLangSync } from '@/plugins/i18nBundleLoader';

Vue.use(VueI18n);

const loadedLanguages = [];

function setI18nLanguage(lang) {
  i18n.locale = lang;
  authAxios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: { en: getLangSync('en') },
});

export async function loadLanguageAsync(lang) {
  // If the same language
  if (loadedLanguages.length > 0 && i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  let languageMessages = {};
  // If the language hasn't been loaded yet
  const context = require.context(
    '@',
    true,
    /locales\/[A-Za-z0-9-_,\s]+\.json$/i,
    'lazy'
  );
  const langPromises = [];
  context.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      if (locale === lang) {
        langPromises.push(context(key));
      }
    }
  });

  const localeFiles = await Promise.all(langPromises);
  localeFiles.forEach((file) => {
    languageMessages = { ...languageMessages, ...file };
  });
  i18n.setLocaleMessage(lang, languageMessages);
  return Promise.resolve(setI18nLanguage(lang));
}
