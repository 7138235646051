<template>
  <v-container class="py-0 px-md-0">
    <template v-if="catalogGroups.isLoading">
      <v-row v-for="rowIndex in 3" :key="`${rowIndex}-the-cool-row`">
        <v-col
          v-for="colIndex in skeletonLoaderSize"
          :key="`${colIndex}-the-awesome-row`"
        >
          <v-skeleton-loader type="card" :loading="true" :tile="false" />
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-row no-gutters>
        <v-col
          cols="12"
          v-for="(catalog, index) in displayedCatalogs"
          :key="index"
          class="home-category"
        >
          <template v-if="isFeaturedWebItemCategory(catalog)">
            <CategoryGroupContainer
              :catalog="catalog"
              displayPage="Landing Page"
              showSeeAllBtn
            />
          </template>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import DiscoveryPageMixins from '../../discovery/mixins/DiscoveryPageMixins';
import CategoryGroupContainer from '@/modules/home/features/category/CategoryGroupContainer';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';

export default {
  name: 'category-list',
  mixins: [DiscoveryPageMixins, SelectedContextFlightMixins],
  components: { CategoryGroupContainer },

  computed: {
    skeletonLoaderSize() {
      let pageSize = 3;
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 1;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        pageSize = 4;
      }
      return pageSize;
    },
  },

  methods: {
    isFeaturedWebItemCategory(catalog) {
      return catalog.type == 'Item';
    },
  },

  watch: {
    selectedContextFlight: {
      immediate: true,
      handler(val) {
        if (val) {
          let categoryProps = {
            flightIds: val.id,
            latitude: val.destination.latitude,
            longitude: val.destination.longitude,
          };
          this.loadCatalogGroups(categoryProps);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.home-category {
  margin-top: 84px;
}
</style>
