import store from '../../store';
const WebItemsPage = () =>
  import(/* webpackChunkName: "WebItems" */ '@/modules/web-items/WebItemsPage');
const webItems = {
  path: '/webItems',
  component: WebItemsPage,
  props: (route) => ({ query: route.query.q }),
  beforeEnter: (to, from, next) => {
    store.dispatch('webItems/loadCustomSearch', {
      text: to.query.text,
      city: to.query.city,
      purchaseStrategy: to.query.purchaseStrategy,
      flightId: to.query.flightId,
      country: to.query.country,
      latitude: to.query.latitude,
      longitude: to.query.longitude,
    });

    return next();
  },
};

export default webItems;
