<template>
  <v-container fluid class="py-0">
    <v-row no-gutters>
      <v-col cols="12" class="category">
        <span class="category-name">
          {{ title }}
          {{ titleCityName }}
        </span>

        <template v-if="shouldShowSeeAllBtn">
          <router-link
            class="category-link"
            :to="{
              name: 'discoveryCategoryResults',
              query: linkQuery,
            }"
          >
            {{ $t('common.see_more') }}
          </router-link>
        </template>
      </v-col>

      <v-col cols="12">
        <CarouselComponent
          class="pa-0"
          :items="items"
          :displayPage="displayPage"
          :title="title"
          :catalogType="catalogType"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CarouselComponent from './CarouselComponent';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';

export default {
  name: 'category',
  components: { CarouselComponent },
  mixins: [SelectedContextFlightMixins],
  props: {
    displayPage: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    catalogType: {
      type: String,
      default: 'Item',
      validator: (value) =>
        ['Item', 'WebItem', 'QueryItem'].indexOf(value) !== -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    seeAllQueryParams: {
      type: Object,
      default: () => {},
    },
    showSeeAllBtn: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    linkQuery() {
      return {
        city: this.getCityName,
        lat: this.getCityLat,
        lng: this.getCityLng,
        title: this.title,
        'sub-categories': this.seeAllParamSubCategories,
        'start-date': this.seeAllParamAvailabilityStart,
        'end-date': this.seeAllParamAvailabilityEnd,
      };
    },

    getCityName() {
      return !!this.seeAllQueryParams ? this.seeAllQueryParams.city : '';
    },

    getCityLat() {
      return !!this.seeAllQueryParams ? this.seeAllQueryParams.latitude : '';
    },

    getCityLng() {
      return !!this.seeAllQueryParams ? this.seeAllQueryParams.longitude : '';
    },

    titleCityName() {
      if (this.selectedContextFlight) {
        return (
          this.$t('common.in') +
          ' ' +
          this.selectedContextFlight.destination.city
        );
      }
      return '';
    },

    shouldShowSeeAllBtn() {
      return (
        this.showSeeAllBtn &&
        !!this.seeAllParamSubCategories &&
        !!this.seeAllParamAvailabilityStart &&
        !!this.seeAllParamAvailabilityEnd
      );
    },

    seeAllParamSubCategories() {
      return !!this.seeAllQueryParams &&
        !!this.seeAllQueryParams.subCategories &&
        !!this.seeAllQueryParams.subCategories.length
        ? this.seeAllQueryParams.subCategories
        : null;
    },

    seeAllParamAvailabilityStart() {
      return !!this.seeAllQueryParams
        ? this.seeAllQueryParams.availabilityStart
        : null;
    },

    seeAllParamAvailabilityEnd() {
      return !!this.seeAllQueryParams
        ? this.seeAllQueryParams.availabilityEnd
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  display: flex;
  align-items: center;

  @media (max-width: map-get($grid-breakpoints, md)) {
    justify-content: space-between;
  }

  &-name {
    color: var(--v-grey8-base);
    @include font-size(30, 120, 400);

    @media (min-width: map-get($grid-breakpoints, md)) {
      font-size: 24px;
    }
  }

  &-link {
    color: var(--v-primary-base);
    margin-left: 22px;
    text-decoration: none;
    white-space: nowrap;

    @include font-size(16, 150);
  }
}
</style>
