<script>
import OrderStoreModule from '../store/index';
export default {
  name: 'OrderMixins',
  methods: {
    loadPastOrders(travelerId) {
      this.$store.dispatch('orders/loadPastOrders', { travelerId });
    },
    sendOrderConfirmation({ orderId }) {
      this.$store.dispatch(`orders/sendConfirmation`, {
        orderId,
      });
    },
  },
  computed: {
    pastOrders() {
      return this.$store.getters['orders/pastOrders'];
    },
    hasPastOrders() {
      return this.$store.getters['orders/hasPastOrders'];
    },
    hasResentConfirmation() {
      return this.$store.getters['orders/hasResentConfirmation'];
    },
    hasConfirmationError() {
      return this.$store.getters['orders/hasConfirmationError'];
    },
  },
};
</script>
