<template>
  <v-container class="flight-selection pa-0">
    <v-row no-gutters justify="start" class="pl-2">
      <v-col cols="12">
        <template v-if="$vuetify.breakpoint.smAndDown">
          <FlightsSelectionDialogV2 :disabled="disableSelection" />
        </template>

        <template v-else>
          <FlightsSelectionMenuV2 :disabled="disableSelection" />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FlightsSelectionMenuV2 from '@/modules/flights/features/FlightSelection/FlightsSelectionMenuV2';
import FlightsSelectionDialogV2 from '@/modules/flights/features/FlightSelection/FlightsSelectionDialogV2';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';

export default {
  name: 'FlightSelectionV2',
  components: {
    FlightsSelectionDialogV2,
    FlightsSelectionMenuV2,
  },
  mixins: [FlightsMixins],

  computed: {
    disableSelection() {
      return Boolean(
        this.$route.matched.some((route) => route.meta?.disableFlightsSelector)
      );
    },

    title() {
      return this.hasArrived
        ? this.$t('flights.arrived')
        : this.hasDeparted
        ? this.$t('flights.landing_in')
        : this.$t('flights.departing_in');
    },
  },
};
</script>

<style lang="scss" scoped>
.flight-selection span.title {
  letter-spacing: 2px !important;
  color: var(--v-grey7-base);

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    font-size: 12px !important;
  }
}
</style>
