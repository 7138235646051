import Vue from 'vue';

import { initialize } from 'launchdarkly-js-client-sdk';

let instance;

export const getInstance = () => instance;

export const useLaunchDarkly = () => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        ldclient: null,
        ready: false,
        isReadyPromise: null,
        readyResolver: null,
        readyRejector: null,
      };
    },
    created() {
      this.isReadyPromise = new Promise((resolve, reject) => {
        this.readyResolver = resolve;
        this.readyRejector = reject;
      });
    },
    methods: {
      clearResolverPromise() {
        this.readyResolver = null;
        this.readyRejector = null;
        this.isReadyPromise = null;
      },
      initialize(clientId, user, options) {
        this.ldclient = initialize(clientId, user, (options = options));
        this.ldclient.on('ready', () => {
          this.ready = true;
          this.readyResolver();
          this.clearResolverPromise();
          this.$emit('ready');
        });
      },
      async variation(flagKey, defaultValue, subscribe = false) {
        let variant = defaultValue;
        if (this.ready) {
          variant = this.ldclient.variation(flagKey, defaultValue);
        } else if (this.isReadyPromise) {
          await this.isReadyPromise;
          variant = this.ldclient.variation(flagKey, defaultValue);
        } else {
          /* eslint-disable no-console */
          console.warn(
            'Attempting to call ldclient.variation when client not ready'
          );
          console.warn('Returning default value');
        }

        if (subscribe) {
          this.ldclient.on(`change:${flagKey}`, (value, previous) => {
            this.$emit(`change:${flagKey}`, value, previous);
          });
        }
        return variant;
      },
    },
  });
  return instance;
};

export const LaunchDarklyPlugin = {
  install(Vue, options) {
    Vue.prototype.$ldclient = useLaunchDarkly(options);
  },
};
