import * as mutationTypes from './mutation-types';

export const actions = {
  addItemToCart({ commit }, { shopId, item }) {
    return commit(mutationTypes.ADD_ITEM_TO_CART, { shopId, item });
  },
  updateItemInCart({ commit }, { item, orderId, shopId }) {
    return commit(mutationTypes.UPDATE_ITEM_IN_CART, { item, orderId, shopId });
  },
  removeItemFromCart({ commit }, { orderId, shopId }) {
    return commit(mutationTypes.REMOVE_ITEM_FROM_CART, { shopId, orderId });
  },
  clearCart({ commit }, { shopId }) {
    return commit(mutationTypes.CLEAR_CART, { shopId });
  },
};
