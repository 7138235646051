import store from '../../store';
import arraysUtils from '@/core/utils/arrays';

const discoveryRoute = {
  path: '/discover',
  props: (route) => ({
    query: route.query.q,
  }),
  component: () =>
    import(
      /* webpackChunkName: "Discovery" */ '@/modules/discovery/DiscoveryPage.vue'
    ),
  children: [
    {
      path: '',
      name: 'discoverNoFlight',
      component: () =>
        import(
          /*webpackChunkName: "NoFlightDiscover"*/ '@/modules/discovery/NoFlightDiscoverPage'
        ),
      beforeEnter: (to, from, next) => {
        if (!store.getters['flights/hasSelectedContextFlight']) {
          return next();
        } else {
          next({
            name: 'discoveryWithCity',
            query: {
              city:
                store.state.flights.selectedContextFlight?.destination?.city,
              lat:
                store.state.flights.selectedContextFlight?.destination
                  ?.latitude,
              lng:
                store.state.flights.selectedContextFlight?.destination
                  ?.longitude,
            },
          });
        }
      },
    },
    {
      path: 'city',
      name: 'discoveryWithCity',
      meta: {
        showStagesFooter: false,
      },
      props: (route) => {
        return {
          flightIds: store.getters['flights/userFlightsIds'],
          flightNumber: route.query.flightNumber,
          city: route.query.city,
          lng: route.query.lng,
          lat: route.query.lat,
          flightDate: route.query.flightDate,
          hasErrorInLoadingFlight: store.getters['flights/hasUserFlightError'],
        };
      },
      component: () =>
        import(
          /* webpackChunkName: "CityDiscovery" */ '@/modules/discovery/CityDiscoverPage.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (!!to.query.city && !!to.query.lat && !!to.query.lng) {
          if (!!to.query.flightNumber && !!to.query.flightDate) {
            store.dispatch('flights/loadUserFlightByNumberAndDate', {
              flightNumber: to.query.flightNumber,
              departureDate: to.query.flightDate,
            });
          }
          return next();
        } else {
          next('/discover');
        }
      },
    },
    {
      path: 'search',
      name: 'discoverySearchResults',
      meta: {
        showStagesFooter: false,
      },
      props: (route) => ({
        query: route.query.q,
        lat: +route.query.lat,
        lng: +route.query.lng,
        city: route.query.city,
        availabilityStart: route.query['start-date'],
        availabilityEnd: route.query['end-date'],
        minPrice: +route.query['min-price'],
        maxPrice: +route.query['max-price'],
        subCategories: arraysUtils.setAsArray(route.query['sub-categories']),
      }),
      component: () =>
        import(
          /* webpackChunkName: "Results" */ '@/modules/discovery/ResultsPage.vue'
        ),
    },
    {
      path: 'category',
      name: 'discoveryCategoryResults',
      meta: {
        showStagesFooter: false,
      },
      props: (route) => ({
        city: route.query['city'],
        categoryTitle: route.query['title'],
        subCategories: arraysUtils.setAsArray(route.query['sub-categories']),
        availabilityStart: route.query['start-date'],
        availabilityEnd: route.query['end-date'],
        minPrice: +route.query['min-price'],
        maxPrice: +route.query['max-price'],
        lat: +route.query.lat,
        lng: +route.query.lng,
      }),
      beforeEnter: (to, from, next) => {
        if (
          (!!to.query['title'] &&
            !!to.query['sub-categories'] &&
            !!to.query['sub-categories'].length) ||
          (!!to.query.lat &&
            !isNaN(to.query.lat) &&
            !!to.query.lng &&
            !isNaN(to.query.lng))
        ) {
          return next();
        } else {
          next(false);
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "Results" */ '@/modules/discovery/ResultsPage.vue'
        ),
    },
  ],
};

export default discoveryRoute;
