<template>
  <v-navigation-drawer
    ref="drawer"
    :width="getDrawerWidth"
    v-model="isDrawerOpen"
    right
    app
    temporary
    touchless
  >
    <v-list nav dense>
      <v-list-item-group v-model="group" active-class="active-item">
        <v-row class="ma-0 justify-space-between pb-8 fill-height align-center">
          <v-col class="logo-icon align-center fill-height pa-0" cols="auto">
            <router-link :to="{ name: 'concierge' }">
              <v-img
                data-cy="navigation-drawer-airline-logo"
                alt="logo"
                contain
                :src="logo"
                transition="scale-transition"
                max-height="60"
                :max-width="getIconWidth"
                position="center center"
              />
            </router-link>
          </v-col>
          <v-col
            data-cy="navigation-drawer-close-button"
            cols="2"
            @click="close"
            class="px-0 d-flex justify-end"
          >
            <v-icon class="close-icon">mdi-close</v-icon>
          </v-col>
        </v-row>

        <div
          v-for="hamburgerItem in hamburgerMenuItems"
          :key="hamburgerItem.title"
        >
          <template v-if="hamburgerItem.links.length > 1">
            <div
              data-cy="navigation-drawer-list-category"
              class="pl-11 py-3 navigation-drawer-list-category grey--text"
            >
              {{ hamburgerItem.title }}
            </div>
          </template>

          <v-list-item
            data-cy="navigation-drawer-list-item"
            v-for="menuItem in hamburgerItem.links"
            :key="menuItem.id"
            :class="hamburgerItem.links.length === 1 ? 'mt-1' : 'mt-0'"
            :target="
              !!menuItem.link && !!menuItem.link.href && !!menuItem.enable
                ? '_blank'
                : ''
            "
            :href="
              !!menuItem.external && !!menuItem.link && !!menuItem.link.to
                ? menuItem.link.to
                : !!menuItem.link && !!menuItem.link.href && !!menuItem.enable
                ? menuItem.link.href
                : ''
            "
            :to="
              !!menuItem.external === false &&
              !!menuItem.link &&
              !!menuItem.link.to &&
              !!menuItem.enable
                ? menuItem.link.to
                : ''
            "
          >
            <template v-if="!!menuItem.icon && menuItem.icon.startsWith('mdi')">
              <v-icon :disabled="!menuItem.enable" color="black" size="20px">
                {{ menuItem.icon }}
              </v-icon>
            </template>

            <template v-else>
              <v-img
                :disabled="!menuItem.enable"
                contain
                height="20px"
                width="20px"
                :src="menuItem.icon"
              />
            </template>

            <template v-if="!menuItem.enable">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title
                    v-on="on"
                    v-bind="attrs"
                    class="pl-4 size16-weight400 text--disabled"
                  >
                    {{ menuItem.text }}
                    <v-icon size="16" class="ml-1">
                      mdi-help-circle-outline
                    </v-icon>
                  </v-list-item-title>
                </template>
                <span>{{ menuItem.disableTooltip }}</span>
              </v-tooltip>
            </template>

            <template v-else>
              <v-list-item-title class="pl-4 size16-weight400">
                {{ menuItem.text }}

                <template
                  v-if="
                    !!menuItem.link && !!menuItem.link.href && !!menuItem.enable
                  "
                >
                  <v-icon size="16" class="ml-1"> mdi-open-in-new </v-icon>
                </template>
              </v-list-item-title>
            </template>
          </v-list-item>
          <v-divider class="mx-2" />
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import NavigationMenuConfigMixins from '@/core/mixins/NavigationMenuConfigMixins';

export default {
  name: 'NavigationDrawer',
  mixins: [NavigationMenuConfigMixins],

  data: () => ({
    group: null,
    isDrawerOpen: false,
  }),

  watch: {
    group() {
      this.isDrawerOpen = false;
    },
  },

  mounted() {
    window.addEventListener('keydown', this.keyDownHandler);
  },

  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
  },

  computed: {
    getIconWidth() {
      if (this.$vuetify.breakpoint.xs) return 80;
      else if (this.$vuetify.breakpoint.sm) return 120;
      else if (this.$vuetify.breakpoint.md) return 150;
      else return 200;
    },
    getDrawerWidth() {
      if (this.$vuetify.breakpoint.lgAndUp) return 400;
      else if (this.$vuetify.breakpoint.md) return 300;
      else return this.$vuetify.breakpoint.width;
    },
  },

  methods: {
    keyDownHandler(event) {
      if (event.code === 'Escape') {
        this.isDrawerOpen = false;
      }
    },

    open() {
      this.isDrawerOpen = true;
    },

    close() {
      this.$refs.drawer.isActive = false;
    },
  },
};
</script>

<style scoped lang="scss">
.active-item {
  background: transparent;
  color: var(--v-primary-base);

  &:before {
    display: none;
  }
}
.hand-pointer {
  cursor: pointer;
}

.close-icon {
  cursor: pointer;
  padding: 0 10px 0 0;
  border-radius: 50%;
  margin-top: 3px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    padding: 2px;
  }

  &:hover {
    background: #f6f6f6;
  }
}

.navigation-drawer-list-category {
  @include font-size(12, 150, 600);
}
</style>
