<script>
import { RepoFactory } from '@/core/repositories';
import moment from 'moment';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';
import arraysUtils from '@/core/utils/arrays';
import * as Sentry from '@sentry/vue';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';
import * as FlightShopHelper from '@/core/utils/flightShopHelpers';

export default {
  name: 'FlightsMixins',
  mixins: [ConfigsMixins, GoogleTagManagerMixins, HotJarMixins],

  data() {
    return {
      flightError: null,
      airlinesCode: {
        EK: 'Emirates',
        FZ: 'flydubai',
      },
    };
  },

  methods: {
    setFlightAnalyticsData(flights) {
      if (!!flights) {
        return flights.map((flight) => {
          return {
            flight_number: flight.flightNumber,
            origin: flight?.origin?.iata,
            destination: flight?.destination?.iata,
            departure_date: moment(flight.departureTime).format('MMM DD, YYYY'),
          };
        });
      } else return null;
    },

    isFlightInContext(flight) {
      return this.contextFlights.some(
        (ctxFlight) => ctxFlight.id === flight.id
      );
    },

    loadFlightBookings(travelerId, clearContext = true) {
      this.$store.dispatch(`flights/loadFlightBookings`, {
        travelerId,
        clearContext,
      });
    },

    setSelectedFlight(flight) {
      this.$store.dispatch(`flights/setSelectedFlight`, flight);
    },

    clearSelectedFlight() {
      this.$store.dispatch(`flights/clearSelectedFlight`);
    },

    loadFlights({
      flightNumber,
      departureDate,
      withFlightPreorderAvailability,
      searchWithCodeShareFlightNumber,
    }) {
      this.$store.dispatch(`flights/loadFlights`, {
        flightNumber,
        departureDate,
        withFlightPreorderAvailability,
        searchWithCodeShareFlightNumber,
      });
    },

    clearFlights() {
      this.$store.dispatch(`flights/clearFlights`);
    },

    clearRecentlyAddedFlights() {
      this.$store.dispatch(`flights/clearRecentlyAddedFlights`);
    },

    addUserFlightBookings(travelerId, flights) {
      this.$store.dispatch(`flights/addFlightBookings`, {
        travelerId,
        flights,
      });
    },

    addFlightsToContext(flights) {
      return this.$store.dispatch(`flights/appendFlightsToContext`, flights);
    },

    removeQuery() {
      let query = Object.assign({}, this.$route.query);
      delete query['flightNumber'];
      delete query['flightDate'];
      this.$router.replace({ query }).catch((err) => {});
    },

    cleanContextFlights() {
      let split = this.flightsCleanupCriteria.split(' ');
      let timespan = split[0];
      let timeunit = split[1];
      this.$store.dispatch(`flights/cleanFlightList`, { timespan, timeunit });
    },

    selectNextContextFlightFlight() {
      this.$store.dispatch(`flights/selectNextContextFlight`);
    },

    updateContextFlightsStoreStatuses() {
      return this.$store.dispatch('flights/updateContextFlightsStoreStatuses');
    },

    clearFlightCreateError() {
      this.$store.dispatch(`flights/updateFlightCreationError`);
    },

    setFlightDuplicateError() {
      this.$store.dispatch(`flights/setFlightDuplicateError`);
    },

    clearFlightDuplicateError() {
      this.$store.dispatch(`flights/clearFlightDuplicateError`);
    },

    deleteUserFlightBookings(travelerId, flightId) {
      this.$store.dispatch(`flights/deleteFlightBooking`, {
        travelerId,
        flightId,
      });
    },

    resetFlightDeletion() {
      this.$store.dispatch(`flights/clearFlightDeletion`);
    },

    removeFlightsFromContext(flights) {
      this.$store.dispatch(`flights/removeFlightsFromContext`, flights);
    },

    setShowAddFlight(value) {
      this.$store.dispatch('flights/setShowAddFlight', value);
    },

    hasPreorderAvailabilityStatusNotification(flight) {
      return (
        this.isPreorderAvailableSoon(flight) ||
        this.isPreorderUnavailable(flight) ||
        this.hasPreorderEnded(flight)
      );
    },

    isCurrentFlightAvailableForPreorder() {
      return !!FlightShopHelper.isCurrentFlightAvailableForPreorder(
        this.selectedContextFlight
      );
    },

    isPreorderAvailableSoon(flight) {
      return !!FlightShopHelper.isPreorderAvailableSoon(flight);
    },

    isPreorderUnavailable(flight) {
      return !!FlightShopHelper.isPreorderUnavailable(flight);
    },

    async loadQueryFlights(flightNumbers, dates) {
      const flightRepo = RepoFactory.get('flight');
      try {
        const flights = flightNumbers.map((flightNumber, i) => ({
          flightNumber,
          departureDate: dates[i],
          withFlightPreorderAvailability: this.showFlightPreOrder,
          searchWithCodeShareFlightNumber: this.searchFlightByCodeShareNumber,
        }));
        const flightsResponses = await flightRepo.getMultiFlights(flights);

        const flightInfo = flightsResponses.filter(
          (flightRes) => !flightRes?.error
        );

        const allInvalidFlights = flightsResponses.reduce(
          (invalidFlights, thisFlight, index) => {
            if (thisFlight?.error) {
              this.flightError =
                thisFlight.error || this.$t('flights.no_flight_info_copy');
              Sentry.captureMessage(this.flightError);
              return [
                ...invalidFlights,
                { flight: flights[index], error: thisFlight.error },
              ];
            } else {
              return invalidFlights;
            }
          },
          []
        );

        await this.$store.dispatch(
          `flights/setInvalidFlights`,
          allInvalidFlights
        );

        if (!!flightInfo && flightInfo.length > 0) {
          return { flights: flightInfo };
        } else {
          return {
            error: this.$t('flights.error_no_flight_info', {
              flightNumber: this.flightNumber,
            }),
          };
        }
      } catch (error) {
        return { error: this.$t('flights.failed_to_get_flight_info') };
      }
    },

    async addQueryFlightsToContext(queryFlights, queryDates) {
      const { flights } = await this.loadQueryFlights(queryFlights, queryDates);
      if (!!flights && flights.length) {
        this.pushFlightAnalytics(flights);
        await this.addFlightsToContext(flights);
        await this.setQueryContextFlights();
      }
      this.removeQuery();
    },

    setQueryContextFlights() {
      if (
        this.recentlyAddedFlights.length < 2 &&
        this.isFlightInContext(this.recentlyAddedFlights[0])
      ) {
        this.setContextFlight(this.recentlyAddedFlights);
      } else {
        this.setContextFlight(this.contextFlights);
      }
    },

    pushFlightAnalytics(flights) {
      this.flightAnalytics = this.setFlightAnalyticsData(flights);
      this.pushAddFlight(this.flightAnalytics);
      this.pushHotJarAnalytics('add_flight');
    },

    hasPreorderEnded(flight) {
      return FlightShopHelper.hasPreorderEnded(flight);
    },

    setInvalidFlightAlert() {
      this.$store.dispatch('flights/setInvalidFlightAlert', true);
    },

    clearInvalidFlightAlert() {
      this.$store.dispatch('flights/clearInvalidFlightAlert');
      this.$store.dispatch('flights/setInvalidFlights', []);
    },

    setContextFlight(flightsToBeAdded) {
      let flightsToBeAddedCopy = [...flightsToBeAdded];
      if (flightsToBeAdded.length) {
        const now = moment().local();
        if (this.shopOnlyMode) {
          flightsToBeAddedCopy = flightsToBeAddedCopy.filter(
            (flight) => flight.isStoreCatalogAvailableNow
          );
        }
        const sortedFlights = flightsToBeAddedCopy.sort((a, b) => {
          let aDate = moment(a.departureTime)
            .utcOffset(a?.origin.utcOffsetHours, true)
            .local();
          let bDate = moment(b.departureTime)
            .utcOffset(b?.origin.utcOffsetHours, true)
            .local();
          return aDate >
            now.utcOffset(a?.origin.utcOffsetHours, true).local() &&
            bDate > now.utcOffset(a?.origin.utcOffsetHours, true).local()
            ? aDate - bDate
            : bDate - aDate;
        });
        if (sortedFlights.length) {
          if (sortedFlights[0]) {
            this.setSelectedFlight(sortedFlights[0]);
          } else {
            this.setSelectedFlight(sortedFlights[sortedFlights.length - 1]);
          }
        } else {
          this.clearSelectedFlight();
        }
      } else {
        this.clearSelectedFlight();
      }
    },
  },

  computed: {
    isOpenFlightSelector() {
      return this.$store.state.isOpenFlightSelector;
    },

    airlineId() {
      return this.configs['app-id'] || null;
    },
    queryFlightNumbers() {
      return arraysUtils.setAsArray(this.$route.query.flightNumber);
    },
    queryFlightDates() {
      return arraysUtils.setAsArray(this.$route.query.flightDate);
    },
    carrierCodeAndFlightNumber() {
      if (this.selectedContextFlight?.flightNumber) {
        const patternMatch = this.selectedContextFlight.flightNumber.match(
          /([A-Z]{2}|[A-Z]\d|\d[A-Z])([1-9]{1}[0-9]{0,3}|[0-9]{0,3}[1-9]{1})/
        );

        return {
          carrier: patternMatch[1],
          number: patternMatch[2],
        };
      } else return null;
    },

    codeShareDetails() {
      if (this.fligtsSearchResults) {
        const codeShareAirline = this.fligtsSearchResults[0]?.codeShares[0]
          ?.carrierCode;
        const airline = this.fligtsSearchResults[0].flightNumber.replace(
          /\d+/g,
          ''
        );
        return {
          codeShareAirlineName: this.airlinesCode[codeShareAirline],
          airlineName: this.airlinesCode[airline],
        };
      } else {
        return {};
      }
    },

    isCodeShareFlight() {
      if (this.recentlyAddedFlights && this.recentlyAddedFlights.length) {
        return this.recentlyAddedFlights.some(
          (flight) => flight.isCodeShare && flight.codeShares.length
        );
      }
      return false;
    },

    formattedDepartureDate() {
      if (this.selectedContextFlight?.departureTime) {
        return moment(
          this.selectedContextFlight.departureTime,
          'YYYY-MM-DD'
        ).format('YYYY/MM/DD');
      } else return null;
    },

    originIataCode() {
      if (this.selectedContextFlight?.origin) {
        return this.selectedContextFlight.origin.iata;
      } else return null;
    },

    hasFlights() {
      return this.$store.getters['flights/hasFlights'];
    },

    isLoadingFlights() {
      return !this.hasFlights && this.flightSearchResults?.isLoading;
    },

    isLoadingFlightsBooking() {
      return !this.hasFlightBookings && this.flightBookings.isLoading;
    },

    flights() {
      return this.$store.getters['flights/flights'];
    },

    flightBookings() {
      return this.$store.state['flights'].flightBookings;
    },

    hasFlightsError() {
      return Boolean(this.flightSearchResults?.error !== null);
    },

    hasFlightBookings() {
      return this.$store.getters[`flights/hasFlightBookings`];
    },

    hasContextFlights() {
      return this.$store.getters['flights/hasContextFlights'];
    },

    hasSelectedContextFlight() {
      return this.$store.getters['flights/hasSelectedContextFlight'];
    },

    selectedContextFlight() {
      return this.$store.state['flights'].selectedContextFlight;
    },

    contextFlights() {
      return this.$store.state['flights'].contextFlights;
    },

    recentlyAddedFlights() {
      return this.$store.state['flights'].recentlyAddedFlights;
    },

    sortedContextFlights() {
      const tmpContextFlights = this.contextFlights.filter(
        (obj) => !(obj && Object.keys(obj).length === 0)
      );

      return tmpContextFlights.slice().sort(
        (a, b) =>
          moment(a.departureTime)
            .utcOffset(a?.origin.utcOffsetHours, true)
            .local() -
          moment(b.departureTime)
            .utcOffset(b?.origin.utcOffsetHours, true)
            .local()
      );
    },

    nextValidFlight() {
      if (!!this.sortedContextFlights && this.sortedContextFlights.length) {
        const nextFlight = this.sortedContextFlights.find(
          (flight) => new Date(flight.departureTime) > new Date()
        );
        return (
          nextFlight ||
          this.sortedContextFlights[this.sortedContextFlights.length - 1]
        );
      } else return null;
    },

    flightSearchResultsError() {
      return this.flightSearchResults?.error;
    },

    flightSearchResults() {
      return this.$store.getters[`flights/flightSearchResults`];
    },

    userFlightsAdded() {
      return this.$store.state[`flights`].addedFlights;
    },

    hasAddedFlight() {
      return this.$store.getters[`flights/hasAddedFlight`];
    },

    hasDeletedFlight() {
      return this.$store.getters[`flights/hasDeletedFlight`];
    },

    hasFlightAddedError() {
      return !!this.userFlightsAdded && !!this.userFlightsAdded.error;
    },

    flightAddedError() {
      return this.userFlightsAdded.error;
    },

    hasFlightDuplicateError() {
      return this.$store.getters[`flights/hasDuplicateError`];
    },

    flightDuplicateError() {
      return this.$store.state[`flights`].duplicateFlight.error;
    },

    pastFlights() {
      return this.$store.state['flights'].pastFlights;
    },

    upcomingFlights() {
      return this.$store.state['flights'].upcomingFlights;
    },

    nextContextFlight() {
      if (!this.hasContextFlights) return null;

      let flights = [...this.contextFlights];

      let upcomingFilter = (flight) => {
        const now = moment.utc();
        const flightDeparture = moment(flight.departureTime)
          .utc(true)
          .subtract(flight?.origin.utcOffsetHours, 'hours');
        let hoursToFlight = flightDeparture.diff(now, 'hours');
        return hoursToFlight > 0;
      };

      let flightsCompare = (flightA, flightB) => {
        const flightATime = moment(flightA.departureTime)
          .utc(true)
          .subtract(flightA?.origin.utcOffsetHours, 'hours');
        const flightBTime = moment(flightB.departureTime)
          .utc(true)
          .subtract(flightB?.origin.utcOffsetHours, 'hours');
        return flightATime.diff(flightBTime, 'hours');
      };

      let filteredContextFlights = flights.filter(upcomingFilter);
      let sortedUpcomingFlights = filteredContextFlights.sort(flightsCompare);

      if (sortedUpcomingFlights.length > 0) {
        return sortedUpcomingFlights[0];
      } else return null;
    },

    showFlightPreOrder() {
      return this.$store.getters.showFlightPreorderAvailability;
    },

    searchFlightByCodeShareNumber() {
      return this.$store.getters.searchFlightByCodeShareNumber;
    },

    showAddFlight() {
      return this.$store.getters['flights/showAddFlight'];
    },

    invalidFlightAlert() {
      return this.$store.state['flights'].invalidFlightAlert;
    },

    invalidFlights() {
      return this.$store.getters['flights/invalidFlights'];
    },

    codeShareAlert() {
      return this.$store.getters['flights/codeShareAlert'];
    },

    codeShareInfo() {
      return this.$store.getters['flights/codeShareInfo'];
    },
  },
};
</script>
