export const getters = {
  hasDestinationContent: (state) =>
    !state.destinationContent.isLoading &&
    state.destinationContent.items !== null,
  hasSearchResults: (state) =>
    state.bookingContent.items && state.bookingContent.items.length > 0,
  searchResults: (state) => state.bookingContent,
  hasCategories: (state) =>
    !state.allCategories.isLoading && state.allCategories.value !== null,
  allCategories: (state) => state.allCategories,
  hasCitySearch: (state) =>
    !state.citySearch.isLoading && state.citySearch.result !== null,
  reverseGeocode: (state) => state.reverseGeocode,
  citySearch: (state) => state.citySearch,
};
