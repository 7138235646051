<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <v-dialog
          @click:outside="closeModal"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '30%'"
          v-model="isOpen"
        >
          <v-card class="pa-4">
            <AddFlightContainer @onClose="closeModal" />
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddFlightContainer from '@/modules/flights/features/AddFlight/AddFlightContainer';

export default {
  name: 'AddFlightModal',
  components: {
    AddFlightContainer,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeModal() {
      this.$emit('closeMenu');
      if (this.$route.query && this.$route.query.addFlightModal) {
        let query = Object.assign({}, this.$route.query);
        delete query['addFlightModal'];
        this.$router.replace({ query }).catch((err) => {});
      }
    },
  },
};
</script>
