<script>
import HomePageStoreModule from '../store/index.js';
export default {
  name: 'HomePageMixins',
  methods: {
    performCitySearch(cityName) {
      this.$store.dispatch(`home/performCitySearch`, cityName);
    },
    performReverseGeocode({ lat, lng }) {
      this.$store.dispatch(`home/performReverseGeocode`, {
        lat: lat,
        lng: lng,
      });
    },
  },
  computed: {
    citySearch() {
      return this.$store.state['home'].citySearch;
    },
    hasCitySearchResult() {
      return this.$store.getters[`home/hasCitySearchResult`];
    },
    headerImage() {
      return this.$store.getters[`home/heroImage`];
    },
    headerImageMobile() {
      return this.$store.getters[`home/heroImageMobile`];
    },
    headerText() {
      return this.$store.getters[`home/heroText`];
    },
    reverseGeocode() {
      return this.$store.state['home'].reverseGeocode;
    },
    landingPageTitle() {
      return this.$store.getters[`home/landingPageTitle`];
    },
    flightTriggerText() {
      return this.$store.getters[`home/flightTriggerText`];
    },
    flightTriggerImage() {
      return this.$store.getters[`home/flightTriggerImage`];
    },
    heroImageMaxSize() {
      return this.$store.getters['home/heroImageMaxSize'];
    },
    heroImageMobileMaxSize() {
      return this.$store.getters['home/heroImageMobileMaxSize'];
    },
  },
};
</script>
