import Vue from 'vue';
let instance;

/** Returns the current instance of the library */
export const getInstance = () => instance;

export const useStripe = () => {
  if (instance) return instance;
  instance = new Vue({
    data: {
      lib: null,
      cardElement: null,
      paymentMethod: null,
      apiKey: null,
      locale: 'en',
    },
    methods: {
      updateLocale(locale) {
        this.locale = locale || 'en';
        if (this.apiKey) {
          this.lib = Stripe(this.apiKey, {
            locale,
          });
        }
      },
      init(apiKey) {
        this.apiKey = apiKey;
        this.lib = Stripe(apiKey, {
          locale: this.locale,
        });
      },
      saveCardElement(card) {
        this.cardElement = card;
      },
      savePaymentMethod(paymentMethod) {
        this.paymentMethod = paymentMethod;
      },
    },
  });
  return instance;
};

export const StripePlugin = {
  install(Vue, options) {
    Vue.prototype.$stripe = useStripe(options);
  },
};
