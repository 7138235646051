import {
  CITY_SEARCH_ERROR,
  CITY_SEARCH_LOADING,
  CITY_SEARCH_COMPLETED,
  REVERSE_GEOCODE_ERROR,
  REVERSE_GEOCODE_LOADING,
  REVERSE_GEOCODE_COMPLETED,
} from './mutation-types';

export const mutations = {
  [CITY_SEARCH_COMPLETED](state, { result } = { result: {} }) {
    state.citySearch = { ...state.citySearch, result: { ...result } };
  },
  [CITY_SEARCH_LOADING](state, payload = true) {
    state.citySearch = { ...state.citySearch, isLoading: payload };
  },
  [CITY_SEARCH_ERROR](state, { error } = { error: 'Error: Unknown error' }) {
    state.citySearch = {
      ...state.citySearch,
      result: null,
      error: error,
    };
  },

  [REVERSE_GEOCODE_COMPLETED](state, { result } = { result: {} }) {
    state.reverseGeocode = { ...state.reverseGeocode, result: { ...result } };
  },
  [REVERSE_GEOCODE_LOADING](state, payload = true) {
    state.reverseGeocode = { ...state.reverseGeocode, isLoading: payload };
  },
  [REVERSE_GEOCODE_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.reverseGeocode = {
      ...state.reverseGeocode,
      result: null,
      error: error,
    };
  },
};
