import Vue from 'vue';

import * as MutationTypes from './mutation-types';

export const mutations = {
  [MutationTypes.REDIRECT_PATH_FROM_CONFIRMATION_SET](
    state,
    { redirectPathFromConfirmation } = {}
  ) {
    state.navigation = {
      ...state.navigation,
      redirectPathFromConfirmation: { ...redirectPathFromConfirmation },
    };
  },
  [MutationTypes.ORDER_PREPARE](state, { id }) {
    if (!!id) {
      // since adding a new key:value entery to existing obj
      // need to use Vue.set to register for reactivity
      Vue.set(state.orders, id, {
        orderRequest: null,
        productId: null,
        creation: {
          isCreating: false,
          response: null,
          error: null,
          status: 'Uninitialized',
        },
        checkout: {
          response: null,
          clientConfirmationKey: null,
          isCheckingOut: false,
          error: null,
          fatalError: false,
          maxpollRetry: 10,
        },
      });
    }
  },
  [MutationTypes.ORDER_CREATING](state, { id, isCreating = true } = {}) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].creation = {
        ...state.orders[id].creation,
        isCreating,
      };
    }
  },
  [MutationTypes.ORDER_CREATION_ERROR](
    state,
    { error = 'Error: Unknown error', id } = {}
  ) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].creation = {
        ...state.orders[id].creation,
        error,
        status: 'Error',
      };
    }
  },
  [MutationTypes.ORDER_CREATED](state, { id, response } = { response: {} }) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].creation = {
        ...state.orders[id].creation,
        response: { ...response },
        error: null,
        status: 'Created',
      };
    }
  },
  [MutationTypes.ORDER_CREATION_CLEAR](state, { id }) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].creation = {
        isCreating: false,
        response: null,
        error: null,
        status: 'Uninitialized',
      };
    }
  },
  [MutationTypes.ORDER_CHECK_OUT_CLEAR](state, { id }) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].checkout = {
        isCreating: false,
        response: null,
        error: null,
        status: 'Uninitialized',
        clientConfirmationKey: null,
        isCheckingOut: false,
      };
    }
  },
  [MutationTypes.ORDER_CHECKING_OUT](state, { id, isCheckingOut = true } = {}) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].checkout = {
        ...state.orders[id].checkout,
        isCheckingOut,
      };
    }
  },
  [MutationTypes.ORDER_CHECKED_OUT](
    state,
    { response, id } = { response: {} }
  ) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].checkout = {
        ...state.orders[id].checkout,
        response: { ...response },
        clientConfirmationKey: null,
        async: false,
      };
    }
  },
  [MutationTypes.ORDER_CHECKED_OUT_ASYNC](
    state,
    { response, id, isAsync = true, pollingRequiredInSec } = {
      response: {},
    }
  ) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].checkout = {
        ...state.orders[id].checkout,
        response: { ...response },
        async: isAsync,
        maxpollRetry: 10,
        timeoutId: null,
        pollingRequiredInSec,
      };
    }
  },
  [MutationTypes.ORDER_CHECK_OUT_TIMEOUT_ID](state, { timeoutId, id }) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].checkout = {
        ...state.orders[id].checkout,
        maxpollRetry: state.orders[id].checkout.maxpollRetry - 1,
        timeoutId: timeoutId,
      };
    }
  },
  [MutationTypes.ORDER_CHECK_OUT_ERROR](
    state,
    {
      error = 'Error: Unknown error in order check out',
      id,
      fatalError = false,
    } = {
      error: 'Error: Unknown error in order check out',
    }
  ) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].checkout = {
        ...state.orders[id].checkout,
        response: null,
        clientConfirmationKey: null,
        error: error,
        fatalError,
      };
    }
  },
  [MutationTypes.ORDER_CHECK_OUT_REQUIRES_CONFIRMATION](
    state,
    { clientConfirmationKey = '', id } = { clientConfirmationKey: '' }
  ) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id].checkout = {
        ...state.orderCheckout,
        response: null,
        error: null,
        isCheckingOut: false,
        clientConfirmationKey: clientConfirmationKey,
      };
    }
  },

  [MutationTypes.ORDER_SET_ORDER_REQUEST_OBJ](state, { id, orderRequest }) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id] = {
        ...state.orders[id],
        orderRequest,
      };
    }
  },
  [MutationTypes.ORDER_SET_PRODCT_ID](state, { id, productId }) {
    const order = state.orders[id];
    if (!!order) {
      state.orders[id] = {
        ...state.orders[id],
        productId,
      };
    }
  },
  [MutationTypes.ORDER_UPDATE_DISCOUNT](state, { id, response }) {
    const order = state.orders[id];
    if (!!order) {
      order.creation.response = response;
    }
  },
  [MutationTypes.ORDER_REMOVE_DISCOUNT](state, { id }) {
    const order = state.orders[id];
    if (!!order) {
      const creation = order.creation.response;
      creation.amount.value = creation.amount.subTotal;
      creation.amount.discountTotal = null;
      creation.discount = null;
    }
  },
};
