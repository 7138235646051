export const state = () => ({
  searchTimestamp: null,
  transportBookingContent: {
    value: null,
    total: null,
    error: null,
    isLoading: false,
    startLocation: null,
    endLocation: null,
    startTime: null,
    passengers: null,
    polling: null,
    minPrice: null,
    maxPrice: null,
    currency: null,
    vehicleSize: null,
  },
  fromSearch: {
    result: null,
    userLocation: null,
    error: null,
    isLoading: false,
  },
  toSearch: {
    result: null,
    userLocation: null,
    error: null,
    isLoading: false,
  },
});
