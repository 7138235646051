<script>
export default {
  name: 'DiscoveryPageMixins',
  methods: {
    loadCatalogGroups(
      {
        city = this.cityName,
        flightIds,
        latitude = this.latitude,
        longitude = this.longitude,
        travelerId,
      } = {
        flightIds: null,
        latitude: this.latitude,
        longitude: this.longitude,
      }
    ) {
      this.$store.dispatch(`discovery/loadCatalogGroups`, {
        city: city,
        latitude: latitude,
        longitude: longitude,
        flightIds: flightIds,
        travelerId,
      });
    },

    loadNoFlightCatalogGroupsGroups(cities) {
      this.$store.dispatch(`discovery/loadNoFlightCatalogGroups`, cities);
    },
  },

  computed: {
    noFlightCatalogGroups() {
      return this.$store.getters['discovery/noFlightCatalogGroups'];
    },

    catalogGroups() {
      return this.$store.state['discovery'].catalogGroups;
    },

    hasCatalogGroups() {
      return this.$store.getters[`discovery/hasCatalogGroups`];
    },

    hasCatalogGroupsError() {
      return this.$store.getters[`discovery/hasCatalogGroupsError`];
    },

    catalogGroupsError() {
      return !!this.hasCatalogGroupsError
        ? this.catalogGroups.errorResponse
        : null;
    },

    cityName() {
      return this.$route.query.city;
    },

    savedCityName() {
      return this.$store.state.discovery.catalogGroups.city;
    },

    longitude() {
      return this.$route.query.lng;
    },

    savedLongitude() {
      return this.$store.state.discovery.catalogGroups.longitude;
    },

    latitude() {
      return this.$route.query.lat;
    },

    savedLatitude() {
      return this.$store.state.discovery.catalogGroups.latitude;
    },

    partnerName() {
      return this.$store.state.configs.title;
    },

    isLoading() {
      return this.catalogGroups.isLoading || this.error !== null;
    },

    catalogsList() {
      return this.hasCatalogGroups && this.catalogGroups.value !== null
        ? this.catalogGroups.value.groups
        : null;
    },

    displayedCatalogs() {
      return this.catalogsList
        ? this.catalogsList.filter(
            (catalog) => catalog.items.length && catalog.type !== 'WebItem'
          )
        : [];
    },
  },
};
</script>
