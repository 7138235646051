import * as mutations from './mutation-types';
import { v4 as uuidv4 } from 'uuid';
import { i18n } from '@/plugins/i18n';
import { RepoFactory } from '@/core/repositories';
import OrderCreation from '../models/OrderCreation';

export const actions = {
  setRedirectPathFromOrderConfirmationPage(
    { commit },
    { redirectPathFromConfirmation }
  ) {
    commit(mutations.REDIRECT_PATH_FROM_CONFIRMATION_SET, {
      redirectPathFromConfirmation,
    });
  },
  /**
   *
   * Creates a new object to handle order creation
   * returns a local orderID to manage order in this store
   * and allows this action to be composed with in other actions
   *
   */
  prepareOrder({ commit }) {
    const localOrderId = uuidv4();
    commit(mutations.ORDER_PREPARE, { id: localOrderId });
    return { localOrderId };
  },
  setOrderProductId({ commit }, { id, productId }) {
    commit(mutations.ORDER_SET_ORDER_REQUEST_OBJ, {
      id,
      productId,
    });
  },
  async createOrderRequest({ commit }, { id, order }) {
    if (!order instanceof OrderCreation)
      throw new Error('order must be instanceof OrderCreation');
    commit(mutations.ORDER_SET_ORDER_REQUEST_OBJ, {
      id,
      orderRequest: order,
    });
    commit(mutations.ORDER_CREATING, { isCreating: true, id });
    commit(mutations.ORDER_CREATION_ERROR, { error: null, id });
    const orderService = RepoFactory.get('order');
    try {
      const response = await orderService.createOrder(
        OrderCreation.toRequestObject(order)
      );
      commit(mutations.ORDER_CREATED, { response: response.data, id });
    } catch (error) {
      const { response } = error;
      let message;
      if (response && response.data) {
        const { errorCode, errorMessage } = response.data;
        message = `${errorCode}: ${errorMessage}`;
      } else {
        message = error.toString();
      }

      commit(mutations.ORDER_CREATION_ERROR, { error: message, id });
    } finally {
      commit(mutations.ORDER_CREATING, { isCreating: false, id });
    }
  },
  updateOrderCreationError({ commit }, { id, error }) {
    commit(mutations.ORDER_CREATION_ERROR, { id, error });
  },
  clearOrderCreation({ commit }, { id }) {
    commit(mutations.ORDER_CREATION_CLEAR, { id });
  },
  clearOrderCheckout({ commit }, { id }) {
    commit(mutations.ORDER_CHECK_OUT_CLEAR, { id });
  },
  evaluateOrderStatus({ commit, dispatch }, { order, id, orderId }) {
    const { status } = order;
    const stopStatusConditions = ['Confirmed', 'Deferred'];
    if (status === 'Failed') {
      commit(mutations.ORDER_CHECK_OUT_ERROR, {
        id,
        error: i18n.t('shop.errors.could_not_complete'),
        fatalError: true,
      });
    } else if (status === 'Declined') {
      commit(mutations.ORDER_CHECK_OUT_ERROR, {
        id,
        error: i18n.t('shop.errors.could_not_confirm'),
      });
    } else if (stopStatusConditions.indexOf(status) > -1) {
      commit(mutations.ORDER_CHECKED_OUT, {
        response: order,
        id,
      });
    } else {
      // update response, and schedule a new polling
      commit(mutations.ORDER_CHECKED_OUT, {
        response: order,
        id,
      });
      dispatch('startCheckOutOrderpolling', { id, orderId });
    }
  },
  async startCheckOutOrderpolling(
    { commit, state, dispatch },
    { id, orderId }
  ) {
    const orderService = RepoFactory.get('order', {
      resourceId: orderId,
    });
    const order = state.orders[id];
    let timeoutId;
    const pollOrder = async () => {
      try {
        const { data } = await orderService.pollOrder();
        // check the order status should reschedule?
        if (!!data && !!data.order) {
          dispatch('evaluateOrderStatus', {
            id,
            order: data.order,
            orderId,
          });
        }
      } catch (error) {
        let errorMessage =
          !!error.response && !!error.response.data
            ? error.response.data.errorMessage
            : error.message;
        commit(mutations.ORDER_CHECK_OUT_ERROR, {
          id,
          error: errorMessage,
        });
      }
    };
    if (!!order) {
      const { checkout } = order;
      if (checkout.maxpollRetry > 0 && checkout.pollingRequiredInSec > 0) {
        timeoutId = setTimeout(() => {
          pollOrder();
        }, checkout.pollingRequiredInSec * 1000);

        commit(mutations.ORDER_CHECK_OUT_TIMEOUT_ID, {
          id,
          timeoutId: timeoutId,
        });
      } else if (checkout.maxpollRetry < 1) {
        commit(mutations.ORDER_CHECK_OUT_ERROR, {
          id,
          error: i18n.t('shop.errors.could_not_confirm'),
        });
      }
    }
  },
  async checkOutOrder(
    { commit },
    {
      orderId,
      paymentMethodId,
      save,
      id,
      discountToken,
      sourceId,
      paymentMethodType,
      asyncCall,
    }
  ) {
    const orderService = RepoFactory.get('order', {
      resourceId: orderId,
    });
    commit(mutations.ORDER_CHECK_OUT_ERROR, {
      id,
      error: null,
    });
    commit(mutations.ORDER_CHECKING_OUT, {
      isCheckingOut: true,
      id,
    });
    try {
      const response = await orderService.checkOutOrder({
        paymentMethodId,
        save,
        discountToken,
        sourceId,
        paymentMethodType,
        asyncCall,
      });
      if (asyncCall && response.order) {
        commit(mutations.ORDER_CHECKED_OUT_ASYNC, {
          response: response.order.order,
          pollingRequiredInSec: response.order.pollingRequiredInSec,
          id,
        });
      } else if (response.order && response.clientConfirmationKey === null) {
        commit(mutations.ORDER_CHECKED_OUT, {
          response: response.order,
          id,
        });
      } else {
        commit(mutations.ORDER_CHECK_OUT_REQUIRES_CONFIRMATION, {
          id,
          clientConfirmationKey: response.clientConfirmationKey,
        });
      }
    } catch (error) {
      let errorMessage = '';
      const errorData =
        !!error.response && !!error.response.data ? error.response.data : null;
      if (errorData) {
        errorMessage = '' + errorData.errorMessage;
        if (!!errorData.details) errorMessage += ': ' + errorData.details;
      } else {
        errorMessage = !!error.message ? error.message : error.toString();
      }
      commit(mutations.ORDER_CHECK_OUT_ERROR, {
        id,
        error: errorMessage,
      });
    } finally {
      commit(mutations.ORDER_CHECKING_OUT, {
        isCheckingOut: false,
        id,
      });
    }
  },
  updateDiscount({ commit }, { orderId, response }) {
    commit(mutations.ORDER_UPDATE_DISCOUNT, { id: orderId, response });
  },
  removeDiscount({ commit }, { orderId }) {
    commit(mutations.ORDER_REMOVE_DISCOUNT, { id: orderId });
  },
};
