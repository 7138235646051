<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <HeaderComponent />
        <template v-if="showflightManagementFF">
          <FlightTriggerComponent
            dark
            :hasFlights="hasPreviouslyAddedFlights"
            :triggerInfo="flightTriggerText"
            :triggerImage="flightTriggerImage"
            @show="toggleModal()"
          >
            <FlightPopOverModal
              @hide="toggleModal()"
              :isModalOpen="isModalActive"
              @update="onFlightCreate"
            />
          </FlightTriggerComponent>
        </template>

        <CategoryListComponent />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HomePageMixins from '../mixins/HomePageMixins';
import HeaderComponent from './HeaderComponent';
import CategoryListComponent from './CategoryListComponent';
import FlightTriggerComponent from './FlightTriggerComponent';
import FlightPopOverModal from '../../flights/features/FlightPopOverModal';
import FlightsMixins from '../../flights/mixins/FlightsMixins';
import OrderMixins from '../../orders/mixins/OrderMixins';

export default {
  name: 'home',
  mixins: [HomePageMixins, FlightsMixins, OrderMixins],
  components: {
    HeaderComponent,
    CategoryListComponent,
    FlightTriggerComponent,
    FlightPopOverModal,
  },

  data() {
    return {
      isModalActive: false,
      showflightManagementFF: false,
    };
  },

  created() {
    this.checkFlightManagementFF();
  },

  methods: {
    async checkFlightManagementFF() {
      this.showflightManagementFF = await this.$ldclient.variation(
        'flight-trigger',
        false,
        true
      );
      this.$ldclient.$on('change:flight-trigger', (value) => {
        this.showflightManagementFF = value;
      });
    },

    toggleModal() {
      this.isModalActive = !this.isModalActive;
    },

    onFlightCreate() {
      this.toggleModal();
      this.$router.push({ name: 'flight-offering' });
    },
  },

  computed: {
    hasPreviouslyAddedFlights() {
      return this.hasFlightBookings || this.hasContextFlights;
    },
  },
};
</script>
