<template>
  <v-container class="pa-0 add-flight-container" data-cy="add-flight-popup">
    <v-row no-gutters>
      <v-col cols="12">
        <p class="add-flight-title" data-cy="add-flight-container-title">
          <template
            v-if="showFlightsInContextOnLandingPageFF && hasContextFlights"
          >
            <v-icon class="mr-2" @click="onBackClick"> mdi-arrow-left </v-icon>
          </template>

          {{ $t('flights.add_flight') }}
        </p>
        <v-form
          ref="form"
          @submit.prevent="handleSubmit"
          role="form"
          lazy-validation
        >
          <v-row
            no-gutters
            justify="space-between"
            align="end"
            class="mb-4 flex-row"
          >
            <v-col cols="6" class="pr-2" id="flightNumber">
              <p class="flightNumber-title mb-2">
                {{ $t('flights.flight_no') }}
              </p>
              <v-text-field
                data-cy="flight-number-field"
                autofocus
                outlined
                dense
                required
                :rules="requiredFieldRules"
                :placeholder="loadAirlineExampleFlight"
                v-model.trim="flightNumber"
                hide-details
                height="44px"
                class="flight-number data-hj-allow"
                tabindex="0"
                label=""
                aria-labelledby="flightNumber"
                aria-selected="true"
                @keydown="resetSearchResults"
              />
            </v-col>
            <v-col cols="6" class="pl-2">
              <DateTimeSelector
                data-cy="flight-date-field"
                inputHeight="44px"
                @input="onDateTimeChange"
                :timeTab="false"
                :allowPastDates="false"
                :dateTitle="$t('flights.departure_date')"
                :value="dateTime"
                :isHideDetails="true"
                :inputPlaceholder="$t('select_date')"
                :hasTitle="true"
                :allowTimeSelection="false"
              />
            </v-col>
          </v-row>
          <div class="text-right">
            <v-btn
              data-cy="search-button"
              tabindex="0"
              class="search-btn"
              block
              large
              depressed
              type="submit"
              color="primary"
              height="44px"
              width="82px"
              :loading="fligtsSearchLoading"
            >
              {{ $t('search') }}
            </v-btn>
          </div>
          <template v-if="fligtsSearchError">
            <p class="no-results ma-0">
              {{ flightSearchResultsError || fligtsSearchError }}
            </p>
          </template>
          <template v-else-if="noSearchResults">
            <p class="no-results ma-0">
              {{ $t('flights.no_flights') }}
            </p>
          </template>

          <template v-else>
            <div v-for="(flight, index) in fligtsSearchResults" :key="index">
              <FlightListItem
                @submitFlight="onAddClick"
                :flight="flight"
                :divider="index !== fligtsSearchResults.length - 1"
                class="ma-1"
              />
            </div>
          </template>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateTimeSelector from '@/core/components/DateTimeSelector.vue';
import moment from 'moment';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import FlightsSearchMixins from '@/modules/flights/mixins/FlightsSearchMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import FlightListItem from '@/modules/flights/features/FlightsList/FlightListItem';
import FlightsFFMixins from '@/modules/flights/mixins/FlightsFFMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'AddFlightContainer',
  mixins: [
    FlightsMixins,
    FlightsSearchMixins,
    GoogleTagManagerMixins,
    FlightsFFMixins,
    HotJarMixins,
  ],

  components: {
    FlightListItem,
    DateTimeSelector,
  },

  created() {
    this.clearFlights();
  },

  data() {
    return {
      flightNumber: null,
      dateTime: null,
    };
  },

  computed: {
    noSearchResults() {
      return (
        !this.fligtsSearchLoading &&
        this.fligtsSearchResults &&
        Object.keys(this.fligtsSearchResults).length === 0
      );
    },

    loadAirlineExampleFlight() {
      let placeholderFlight =
        this.$store.state.configs && this.$store.state.configs['flight_trigger']
          ? this.$store.state.configs['flight_trigger'].placeholder_flight
          : null;
      return !!placeholderFlight ? placeholderFlight : 'A10999';
    },

    searchParams() {
      return {
        flightNumber: this.flightNumber,
        departureDate: this.datetime || null,
      };
    },

    requiredFieldRules() {
      return [(v) => !!v || this.$t('required')];
    },
  },

  watch: {
    fligtsSearchLoading(val) {
      this.$emit('startSearch', val);
    },

    flightSearchResultsError(val) {
      if (!!val) {
        this.pushFailedSearchFlight(val, {
          flight_number: this.flightNumber,
          departure_date: this.dateTime,
        });
      }
    },

    fligtsSearchResults: {
      deep: true,
      handler(val) {
        const snackbar = val && val[0].isCodeShare && val[0].codeShares.length;
        this.$store.dispatch('flights/setCodeShareAlert', snackbar);
        this.$store.dispatch('flights/setCodeShareInfo', this.codeShareDetails);
      },
    },
  },

  methods: {
    handleSubmit() {
      if (this.$refs.form.validate()) {
        const flightAnalytics = {
          flight_number: this.flightNumber.toUpperCase(),
          departure_date: this.dateTime,
        };
        this.pushSearchFlight(flightAnalytics);
        this.pushHotJarAnalytics('search_flight');
        this.flightNumber = this.flightNumber.replace(/ /g, '');
        this.searchForFlight({
          flightNumber: this.flightNumber.toUpperCase(),
          departureDate: this.dateTime,
          withFlightPreorderAvailability: this.showFlightPreOrder,
          searchWithCodeShareFlightNumber: this.searchFlightByCodeShareNumber,
        });
      } else if (this.fligtsSearchError) {
        this.clearFlights();
      }
    },

    onAddClick(flight) {
      this.pushSelectFlight(flight);
      this.pushHotJarAnalytics('select_flight');
      if (this.$route.query && this.$route.query.addFlightModal) {
        let query = Object.assign({}, this.$route.query);
        delete query['addFlightModal'];
        this.$router.replace({ query }).catch((err) => {});
      }
      this.addFlightsToContext([flight]);
      this.setSelectedFlight(flight);
      this.$store.dispatch('flights/setCodeShareAlert', false);
      this.$store.dispatch('flights/setCodeShareInfo', {
        codeShareAirlineName: '',
        airlineName: '',
      });
    },

    onDateTimeChange(dateTime) {
      this.resetSearchResults();
      if (!!dateTime) {
        this.dateTime = moment(dateTime).format('YYYY-MM-DD HH:mm');
      }
    },

    onBackClick() {
      this.$emit('onReturn');
      this.setShowAddFlight(false);
    },

    resetSearchResults() {
      this.fligtsSearchError = false;
      this.fligtsSearchLoading = false;
      this.fligtsSearchResults = null;
    },
  },
};
</script>

<style scoped lang="scss">
.add-flight {
  &-container {
    color: var(--v-grey8-base);
  }

  &-title {
    color: black;
    display: flex;
    align-items: center;
    @include font-size(16, 150, 600);
  }
}

.search-btn {
  font-weight: 600;
}

::v-deep .subtitle-label {
  @include font-size(14, 171.4287, 600);
  margin-bottom: 5px;
  display: block;
}

::v-deep .v-input fieldset {
  border: 1px solid #cfd5e0;
  border-radius: 8px;
}

.no-results {
  @include font-size(16, 162.5);
  text-align: center;
  color: var(--v-grey8-base);
  padding: 27px 0;
}

::v-deep .flight-number .v-input__slot {
  padding: 0 10px !important;
}

.flightNumber-title {
  @include font-size(14, 150, 600);
}
</style>
