export const NotFoundRoute = {
  path: '/404',
  name: '404',
  component: () =>
    import(
      /* webpackChunkName: "error-404" */ '@/modules/exception/NotFoundPage.vue'
    ),
};

export const InternalServerError = {
  path: '/500',
  name: '500',
  component: () =>
    import(
      /* webpackChunkName: "error-500" */ '@/modules/exception/InternalServerErrorPage'
    ),
};
