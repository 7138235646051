<template>
  <v-card width="420" :class="{ 'start-search': !isStartSearch }">
    <v-card-text>
      <AddFlightContainer
        @startSearch="startSearch"
        data-cy="add-flight-container"
        analytics-origin="page"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import AddFlightContainer from '@/modules/flights/features/AddFlight/AddFlightContainer';
export default {
  name: 'AddFlightCta',
  components: { AddFlightContainer },
  data() {
    return {
      isStartSearch: false,
    };
  },
  methods: {
    startSearch(val) {
      this.isStartSearch = val;
    },
  },
  watch: {
    hasSelectedContextFlight(val) {
      if (!!val) {
        this.$router.push({ name: 'concierge' });
      }
    },
  },
};
</script>

<style scoped>
.start-search {
  margin-bottom: 80px;
}
</style>
