import TextTruncate from '@/core/components/TextTruncate';
import EnvBanner from '@/core/components/EnvBanner';
import VueMarkdown from 'vue-markdown';
import { SimpleSVG } from 'vue-simple-svg';
import { LaunchDarklyPlugin } from './LaunchDarklyPlugin';
import { PathPrefixPlugin } from '@/plugins/path-prefix';

const GlobalComponents = {
  install(vue) {
    vue.component('vue-markdown', VueMarkdown);
    vue.component('simple-svg', SimpleSVG);
    vue.component('glx-text-truncate', TextTruncate);
    vue.component('glx-env-banner', EnvBanner);
    vue.use(LaunchDarklyPlugin);
    vue.use(PathPrefixPlugin); // This MUST load after LaunchDarkly as it relies on it
  },
};

export default GlobalComponents;
