export const state = () => ({
  conciergeModuleContent: {
    error: null,
    isLoading: true,
    value: null,
  },
  conciergeStages: {
    error: null,
    isLoading: false,
    stages: null,
  },
  selectedStage: {
    id: null,
    stage: 0,
    subtitle: '',
    title: '',
  },
});
