import store from '../../store';
import HomePage from '../home/HomePage.vue';
const homeRoute = {
  path: '/booking',
  name: 'home',
  component: HomePage,
  props: (route) => {
    return {
      flightIds: store.getters['flights/userFlightsIds'],
      flightNumber: route.query.flightNumber,
      flightDate: route.query.flightDate,
      hasErrorInLoadingFlight: store.getters['flights/hasUserFlightError'],
    };
  },
  beforeEnter: (to, from, next) => {
    if (
      !!to.query.flightNumber &&
      !!to.query.flightDate &&
      !!!store.getters['flights/hasUserFlights']
    ) {
      store.dispatch('flights/loadUserFlightByNumberAndDate', {
        flightNumber: to.query.flightNumber,
        departureDate: to.query.flightDate,
      });
    }
    next();
  },
};

export default homeRoute;
