export const state = () => ({
  flights: {
    error: null,
    isLoading: false,
    value: null,
  },
  invalidFlights: [],
  userFlights: {
    error: null,
    isLoading: false,
    value: null,
  },
  flightBookings: {
    result: null,
    error: null,
    isLoading: false,
  },
  contextFlights: [],
  recentlyAddedFlights: [],
  archivedFlights: [],
  flightCleaningTimeStamp: null,
  duplicateFlight: {
    error: null,
    value: false,
  },
  selectedContextFlight: null,
  addedFlights: {
    flights: [],
    error: null,
    isAdding: false,
  },
  flightDeletion: {
    isDeleting: false,
    error: null,
    deleted: false,
  },
  pastFlights: [],
  upcomingFlights: [],
  showAddFlight: false,
  invalidFlightAlert: false,
  codeShareAlert: false,
  codeShareInfo: {
    codeShareAirlineName: '',
    airlineName: '',
  },
});
