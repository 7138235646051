<script>
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'FlightRedirectMixins',
  mixins: [FlightsMixins, ConfigsMixins],
  beforeRouteEnter(to, from, next) {
    if (to.path.includes('information')) {
      next();
    } else {
      next(async (vm) => {
        if (vm.shopOnlyMode) {
          if (
            vm.selectedContextFlight?.isStoreCatalogAvailableNow ||
            vm.contextFlights.some(
              (flight) => flight.isStoreCatalogAvailableNow
            )
          ) {
            return next();
          }
        }

        if (vm.hasSelectedContextFlight) {
          return next();
        }

        if (await vm.enableFlightsLanding) {
          // redirect to /flights instead
          return next({
            name: 'flights',
          });
        } else {
          return next();
        }
      });
    }
  },
  computed: {
    enableFlightsLanding() {
      return this.$ldclient.variation('flights-landing-page', true, true);
    },
  },
};
</script>
