import * as mutationTypes from './mutation-types';

export const mutations = {
  [mutationTypes.ORDER_CONFIRMATION_LOADED](state, payload = true) {
    state.emailConfirmation = {
      ...state.emailConfirmation,
      value: payload,
    };
  },
  [mutationTypes.ORDER_CONFIRMATION_EMAIL_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.emailConfirmation = {
      ...state.emailConfirmation,
      error: error,
    };
  },

  [mutationTypes.ORDER_CONFIRMATION_EMAIL_LOADING](state, payload = true) {
    state.emailConfirmation = {
      ...state.emailConfirmation,
      isLoading: payload,
    };
  },

  [mutationTypes.ORDERS_LOADED](state, { orders } = { orders: [] }) {
    state.pastOrders = { ...state.pastOrders, orders };
  },
  [mutationTypes.ORDERS_LOADING](state, payload = true) {
    state.pastOrders = {
      ...state.pastOrders,
      isLoading: payload,
    };
  },
  [mutationTypes.ORDERS_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.pastOrders = {
      ...state.pastOrders,
      error: error,
    };
  },
};
