export const state = () => ({
  informationModuleContent: {
    error: null,
    isLoading: false,
    value: null,
  },
  articles: {
    error: null,
    isLoading: false,
    value: null,
  },
  articleProducts: {
    value: null,
    error: null,
    isLoading: false,
  },
  details: {
    value: null,
    error: null,
    isLoading: false,
  },
  citySearch: {
    result: null,
    error: null,
    isLoading: false,
  },
  reverseGeocode: {
    result: null,
    error: null,
    isLoading: false,
  },
});
