<template>
  <v-row justify="center">
    <v-dialog
      v-on:click:outside="hideRewardInfoModal"
      :value="isModalOpen"
      max-width="420"
      content-class="gt-refresh-modal"
    >
      <v-card>
        <v-container class="pt-4">
          <v-row no-gutters justify="center">
            <v-col cols="auto">
              <p class="title text-center">
                Collect Miles for your future trip!
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col cols="auto">
              <p class="px-3">
                For every dollar you spend on experience you earn points.
              </p>
              <p class="px-3">
                When you make a purchase, your points will go into a pending
                status. Upon completion of travel and processing of payment, the
                points will be added to your account.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'PointsRewardModal',
  props: {
    isModalOpen: Boolean,
  },
  methods: {
    hideRewardInfoModal() {
      this.$emit('hide');
    },
  },
};
</script>

<style lang="scss" scoped>
.gt-refresh-modal .v-card.v-sheet {
  border-radius: 8px;
}
</style>
