import { RepoFactory } from '@/core/repositories';
import * as mutations from './mutation-types';

export const actions = {
  async loadSavedPaymentMethods({ commit }, { travelerId }) {
    const travelerService = await RepoFactory.get('traveler');
    commit(mutations.SAVED_PAYMENT_METHODS_ERROR, { error: null });
    commit(mutations.SAVED_PAYMENT_METHODS_LOADING, true);
    try {
      const { data } = await travelerService.getSavedCreditCards(travelerId);
      commit(mutations.SAVED_PAYMENT_METHODS_LOADED, { cards: data });
    } catch (error) {
      commit(mutations.SAVED_PAYMENT_METHODS_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.SAVED_PAYMENT_METHODS_LOADING, false);
    }
  },
};
