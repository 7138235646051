import { actions } from './actions';
import { mutations } from './mutations';
import { state } from './state';
import { getters } from './getters';

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: state,
  mutations,
  actions,
  getters,
};
