export const INFORMATION_MODULE_CONTENT_LOADING =
  'INFORMATION_MODULE_ITEMS_LOADING';
export const INFORMATION_MODULE_CONTENT_LOADED =
  'INFORMATION_MODULE_ITEMS_LOADED';
export const INFORMATION_MODULE_CONTENT_ERROR =
  'INFORMATION_MODULE_ITEMS_ERROR';
export const ARTICLES_LOADING = 'ARTICLES_LOADING';
export const ARTICLES_LOADED = 'ARTICLES_LOADED';
export const ARTICLES_ERROR = 'ARTICLES_ERROR';

export const ARTICLE_PRODUCTS_LOADED = 'ARTICLE_PRODUCTS_LOADED';
export const ARTICLE_PRODUCTS_ERROR = 'ARTICLE_PRODUCTS_ERROR';
export const ARTICLE_PRODUCTS_LOADING = 'ARTICLE_PRODUCTS_LOADING';

export const CITY_SEARCH_ERROR = 'CITY_SEARCH_ERROR';
export const CITY_SEARCH_LOADING = 'CITY_SEARCH_LOADING';
export const CITY_SEARCH_COMPLETED = 'CITY_SEARCH_COMPLETED';

export const REVERSE_GEOCODE_ERROR = 'REVERSE_GEOCODE_ERROR';
export const REVERSE_GEOCODE_LOADING = 'REVERSE_GEOCODE_LOADING';
export const REVERSE_GEOCODE_COMPLETED = 'REVERSE_GEOCODE_COMPLETED';
