import * as mutationTypes from './mutation-types';

export const mutations = {
  [mutationTypes.FLIGHTS_LOADED](
    state,
    { flights, flightNumber, departureDate } = {
      flights: [],
      flightNumber: '',
      departureDate: '',
    }
  ) {
    state.flights = {
      ...state.flights,
      flightNumber,
      departureDate,
      value: flights,
    };
  },

  [mutationTypes.FLIGHTS_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.flights = {
      ...state.flights,
      error: error,
    };
  },

  [mutationTypes.FLIGHTS_LOADING](state, payload = true) {
    state.flights = {
      ...state.flights,
      isLoading: payload,
    };
  },

  [mutationTypes.FLIGHTS_SEARCH_CLEAR_RESULTS](state) {
    state.flights = {
      ...state.flights,
      flightNumber: null,
      departureDate: null,
      value: null,
      error: null,
    };
  },

  [mutationTypes.USER_FLIGHTS_SET](state, { flights } = { flights: [] }) {
    state.userFlights = {
      ...state.userFlights,
      value:
        !!state.userFlights.value && !!state.userFlights.value.length
          ? [...state.userFlights.value, ...flights]
          : flights,
    };
  },

  [mutationTypes.USER_FLIGHTS_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.userFlights = {
      ...state.userFlights,
      error: error,
    };
  },

  [mutationTypes.USER_FLIGHTS_LOADING](state, payload = true) {
    state.userFlights = {
      ...state.userFlights,
      isLoading: payload,
    };
  },

  [mutationTypes.FLIGHT_BOOKINGS_LOADED](state, { result } = { result: [] }) {
    state.flightBookings = { ...state.flightBookings, result };
  },
  [mutationTypes.FLIGHT_BOOKINGS_LOADING](state, payload = true) {
    state.flightBookings = { ...state.flightBookings, isLoading: payload };
  },
  [mutationTypes.FLIGHT_BOOKINGS_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.flightBookings = {
      ...state.flightBookings,
      result: null,
      error: error,
    };
  },
  [mutationTypes.USER_FLIGHTS_ADDED](state, { flights } = { flights: [] }) {
    state.addedFlights = { ...state.addedFlights, flights };
  },
  [mutationTypes.ADD_FLIGHT_BOOKINGS_LOADING](state, payload = true) {
    state.addedFlights = { ...state.addedFlights, isAdding: payload };
  },
  [mutationTypes.ADD_FLIGHT_BOOKINGS_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.addedFlights = {
      ...state.addedFlights,
      flights: null,
      error: error,
    };
  },

  [mutationTypes.DELETE_FLIGHT_BOOKING_LOADING](state, payload = true) {
    state.flightDeletion = { ...state.flightDeletion, isDeleting: payload };
  },
  [mutationTypes.DELETE_FLIGHT_BOOKING_ERROR](
    state,
    { error } = { error: 'Error: Unknown Error' }
  ) {
    state.flightDeletion = {
      ...state.flightDeletion,
      error: error,
    };
  },
  [mutationTypes.FLIGHT_DELETED](state, payload = true) {
    state.flightDeletion = { ...state.flightDeletion, deleted: payload };
  },
  [mutationTypes.CLEAR_FLIGHT_CREATION_ERROR](state, payload = false) {
    state.addedFlights = { ...state.addedFlights, error: payload };
  },
  [mutationTypes.FLIGHT_CONTEXT_APPEND_ALL](state, flightModels) {
    const notEmptyFlightModels = flightModels.filter(
      (flight) => flight != null
    );
    // make sure we don't duplicate flights in context array
    const newFlightsIds = notEmptyFlightModels.map((flight) => flight.id);
    const currentUniqueFlights = state.contextFlights.filter(
      (flight) => newFlightsIds.indexOf(flight.id) < 0
    );
    const flightsList = [...currentUniqueFlights, ...notEmptyFlightModels].sort(
      (a, b) => new Date(a.departureTime) - new Date(b.departureTime)
    );
    state.recentlyAddedFlights = [...notEmptyFlightModels];
    state.contextFlights = [...flightsList];
  },
  [mutationTypes.FLIGHT_SELECTED_CONTEXT_SET](state, flightModel) {
    state.selectedContextFlight = { ...flightModel };
  },
  [mutationTypes.FLIGHT_SELECTED_CONTEXT_CLEAR](state) {
    state.selectedContextFlight = null;
  },
  [mutationTypes.FLIGHT_CONTEXT_CLEAR](state) {
    state.contextFlights = [];
    state.recentlyAddedFlights = [];
  },
  [mutationTypes.FLIGHT_RECENTLY_ADDED_FLIGHTS](state) {
    state.recentlyAddedFlights = [];
  },
  [mutationTypes.CLEAR_FLIGHT_DELETION](state) {
    state.flightDeletion = {
      isDeleting: false,
      error: null,
      deleted: false,
    };
  },
  [mutationTypes.FLIGHT_DUPLICATE_ERROR](state) {
    state.duplicateFlight = {
      ...state.duplicateFlight,
      value: true,
      error: 'Flight already exists',
    };
  },
  [mutationTypes.CLEAR_FLIGHT_DUPLICATE_ERROR](state) {
    state.duplicateFlight = {
      ...state.duplicateFlight,
      value: false,
      error: null,
    };
  },
  [mutationTypes.SET_PAST_FLIGHTS](state, flights) {
    state.pastFlights = [...state.pastFlights, ...flights];
  },
  [mutationTypes.SET_UPCOMING_FLIGHTS](state, flights) {
    state.upcomingFlights = [...state.upcomingFlights, ...flights];
  },
  [mutationTypes.PAST_FLIGHTS_CLEAR](state) {
    state.pastFlights = [];
  },
  [mutationTypes.UPCOMING_FLIGHTS_CLEAR](state) {
    state.upcomingFlights = [];
  },
  [mutationTypes.FLIGHT_CONTEXT_REMOVE](state, flights) {
    state.contextFlights = state.contextFlights.filter((flight) => {
      return !flights.includes(flight.id);
    });
  },
  [mutationTypes.SET_SHOW_ADD_FLIGHT](state, payload = true) {
    state.showAddFlight = payload;
  },
  [mutationTypes.FLIGHT_ARCHIVE_LIST_UPDATE](state, flights) {
    state.archivedFlights = flights;
  },
  [mutationTypes.FLIGHT_CONTEXT_LIST_UPDATE](state, flights) {
    state.contextFlights = flights.filter((flight) => flight != null);
  },
  [mutationTypes.FLIGHT_CLEANING_TIMESTAMP_UPDATE](state, timestamp) {
    state.flightCleaningTimeStamp = timestamp;
  },
  [mutationTypes.SET_INVALID_FLIGHT_ALERT](state, status) {
    state.invalidFlightAlert = status;
  },
  [mutationTypes.CLEAR_INVALID_FLIGHT_ALERT](state) {
    state.invalidFlightAlert = false;
  },
  [mutationTypes.SET_INVALID_FLIGHTS](state, flights) {
    state.invalidFlights = flights;
  },
  [mutationTypes.SET_CODE_SHARE_ALERT](state, status) {
    state.codeShareAlert = status;
  },
  [mutationTypes.SET_CODE_SHARE_INFO](state, codeShareInfo) {
    state.codeShareInfo = codeShareInfo;
  },
};
