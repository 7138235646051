import Vue from 'vue';
import VueRouter from 'vue-router';
import ProductRoutes from '../modules/product/route';
import HomeRoute from '../modules/home/route';
import ThemeifyRoute from '../modules/themeify/route';
import DiscoveryRoute from '../modules/discovery/route';
import { InternalServerError, NotFoundRoute } from '../modules/exception/route';
import FlightsRoute from '../modules/flights/route';
import WebItemsRoute from '../modules/web-items/route';
import ConciergeRoute from '../modules/concierge/route';
import InformationRoute from '../modules/information/route';
import WelcomeRoutes from '../modules/welcome/route';
import { authGuard } from '../core/auth/AuthGuards';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../modules/root/RootPage'),
    meta: {
      hideAppBar: true,
    },
  },
  HomeRoute,
  ...ProductRoutes,
  ThemeifyRoute,
  DiscoveryRoute,
  FlightsRoute,
  NotFoundRoute,
  InternalServerError,
  WebItemsRoute,
  ConciergeRoute,
  InformationRoute,
  WelcomeRoutes,
  {
    path: '*',
    component: () => import('@/modules/common/components/ErrorComponent'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash || (to.params && to.params.id && to.query.offeringId)) {
      return {
        selector: to.hash || to.params.id,
        behavior: 'smooth',
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(authGuard);

export default router;
