var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{ref:"drawer",attrs:{"width":_vm.getDrawerWidth,"right":"","app":"","temporary":"","touchless":""},model:{value:(_vm.isDrawerOpen),callback:function ($$v) {_vm.isDrawerOpen=$$v},expression:"isDrawerOpen"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"active-item"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-row',{staticClass:"ma-0 justify-space-between pb-8 fill-height align-center"},[_c('v-col',{staticClass:"logo-icon align-center fill-height pa-0",attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":{ name: 'concierge' }}},[_c('v-img',{attrs:{"data-cy":"navigation-drawer-airline-logo","alt":"logo","contain":"","src":_vm.logo,"transition":"scale-transition","max-height":"60","max-width":_vm.getIconWidth,"position":"center center"}})],1)],1),_c('v-col',{staticClass:"px-0 d-flex justify-end",attrs:{"data-cy":"navigation-drawer-close-button","cols":"2"},on:{"click":_vm.close}},[_c('v-icon',{staticClass:"close-icon"},[_vm._v("mdi-close")])],1)],1),_vm._l((_vm.hamburgerMenuItems),function(hamburgerItem){return _c('div',{key:hamburgerItem.title},[(hamburgerItem.links.length > 1)?[_c('div',{staticClass:"pl-11 py-3 navigation-drawer-list-category grey--text",attrs:{"data-cy":"navigation-drawer-list-category"}},[_vm._v(" "+_vm._s(hamburgerItem.title)+" ")])]:_vm._e(),_vm._l((hamburgerItem.links),function(menuItem){return _c('v-list-item',{key:menuItem.id,class:hamburgerItem.links.length === 1 ? 'mt-1' : 'mt-0',attrs:{"data-cy":"navigation-drawer-list-item","target":!!menuItem.link && !!menuItem.link.href && !!menuItem.enable
              ? '_blank'
              : '',"href":!!menuItem.external && !!menuItem.link && !!menuItem.link.to
              ? menuItem.link.to
              : !!menuItem.link && !!menuItem.link.href && !!menuItem.enable
              ? menuItem.link.href
              : '',"to":!!menuItem.external === false &&
            !!menuItem.link &&
            !!menuItem.link.to &&
            !!menuItem.enable
              ? menuItem.link.to
              : ''}},[(!!menuItem.icon && menuItem.icon.startsWith('mdi'))?[_c('v-icon',{attrs:{"disabled":!menuItem.enable,"color":"black","size":"20px"}},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])]:[_c('v-img',{attrs:{"disabled":!menuItem.enable,"contain":"","height":"20px","width":"20px","src":menuItem.icon}})],(!menuItem.enable)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"pl-4 size16-weight400 text--disabled"},'v-list-item-title',attrs,false),on),[_vm._v(" "+_vm._s(menuItem.text)+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"size":"16"}},[_vm._v(" mdi-help-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(menuItem.disableTooltip))])])]:[_c('v-list-item-title',{staticClass:"pl-4 size16-weight400"},[_vm._v(" "+_vm._s(menuItem.text)+" "),(
                  !!menuItem.link && !!menuItem.link.href && !!menuItem.enable
                )?[_c('v-icon',{staticClass:"ml-1",attrs:{"size":"16"}},[_vm._v(" mdi-open-in-new ")])]:_vm._e()],2)]],2)}),_c('v-divider',{staticClass:"mx-2"})],2)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }