export const state = () => ({
  bookingContent: {
    city: null,
    latitude: null,
    longitude: null,
    searchText: null,
    minAvailabilityDate: null,
    maxAvailabilityDate: null,
    minPrice: null,
    maxPrice: null,
    subCategories: null,
    items: null,
    error: null,
    isLoading: false,
    total: null,
  },
  allCategories: {
    items: null,
    error: null,
    isLoading: false,
  },
  citySearch: {
    result: null,
    error: null,
    isLoading: false,
  },
  reverseGeocode: {
    result: null,
    error: null,
    isLoading: false,
  },
});
