export const state = () => ({
  catalogGroups: {
    value: null,
    error: null,
    errorResponse: null,
    isLoading: false,
    city: null,
    latitude: null,
    longitude: null,
  },
  noFlightCatalogGroups: [],
});
