import * as mutations from './mutation-types';
import { RepoFactory } from '@/core/repositories';

export const actions = {
  async loadDetails({ commit }, { productId }) {
    const healthService = RepoFactory.get('advica', {
      resourceId: productId,
    });
    commit(mutations.DETAILS_ERROR, { error: null });
    commit(mutations.DETAILS_LOADING, true);
    try {
      const { data } = await healthService.getDetails({});
      commit(mutations.DETAILS_LOADED, { product: data });
    } catch (error) {
      let usefulError = {
        message: error.toString(),
        code: 500,
        status: error.response.status,
      };

      if (!!error.response && !!error.response.data) {
        usefulError.message = error.response.data.errorMessage;
        usefulError.code = error.response.data.errorCode;
      }

      commit(mutations.DETAILS_ERROR, { error: usefulError });
    } finally {
      commit(mutations.DETAILS_LOADING, false);
    }
  },
  setUserSelectedDateRange({ commit }, dateRange) {
    commit(mutations.USERFORM_DATERANGE_SET, dateRange);
  },
  removeUserSelectedDateRange({ commit }) {
    commit(mutations.USERFORM_DATERANGE_REMOVE);
  },
  setUserPasses({ commit }, passes) {
    commit(mutations.USERFORM_PASSES_SET, passes);
  },
  setUserContacts({ commit }, contacts) {
    commit(mutations.USERFORM_CONTACTS_SET, contacts);
  },
  setUserUSDestinationConfirmed({ commit }, isConfirmed) {
    commit(mutations.USERFORM_US_DEST_CONFIRM_SET, isConfirmed);
  },
};
