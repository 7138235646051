import { namedPaymentRoute } from '@/modules/product/features/Payment/route.js';

const advicaRoutes = {
  path: '/health/:id',
  component: () => import('@/modules/product/advica/AdvicaPage'),
  children: [
    {
      path: '',
      name: 'health-details',
      component: () => import('@/modules/product/advica/AdvicaDetailsPage'),
    },
    {
      path: 'checkout',
      component: () => import('@/modules/product/advica/AdvicaCheckoutPage'),
      children: [
        {
          path: '',
          name: 'advica-forms',
          component: () => import('@/modules/product/advica/AdvicaFormPage'),
        },
        namedPaymentRoute('advica-payment', {
          fallbackPathName: 'health-details',
          confirmationTypeOverride: 'HealthOrderConfirmation',
          discoverMoreCtaText: 'Discover more',
        }),
      ],
    },
  ],
};

export default advicaRoutes;
