import * as mutations from './mutation-types';
import { mapboxService } from '@/core/services/MapboxService';

export const actions = {
  async performCitySearch({ commit }, cityName) {
    commit(mutations.CITY_SEARCH_ERROR, { error: null });
    commit(mutations.CITY_SEARCH_LOADING, true);
    try {
      const data = await mapboxService.getCities(cityName);
      commit(mutations.CITY_SEARCH_COMPLETED, { result: data });
    } catch (error) {
      commit(mutations.CITY_SEARCH_ERROR, { error: error.toString() });
    } finally {
      commit(mutations.CITY_SEARCH_LOADING, false);
    }
  },

  async performReverseGeocode({ commit }, { lat, lng }) {
    commit(mutations.REVERSE_GEOCODE_ERROR, { error: null });
    commit(mutations.REVERSE_GEOCODE_LOADING, true);
    try {
      const data = await mapboxService.reverseGeocode({ lat: lat, lng: lng });
      commit(mutations.REVERSE_GEOCODE_COMPLETED, { result: data });
    } catch (error) {
      commit(mutations.REVERSE_GEOCODE_ERROR, { error: error.toString() });
    } finally {
      commit(mutations.REVERSE_GEOCODE_LOADING, false);
    }
  },
};
