<template>
  <v-row no-gutters>
    <v-virtual-scroll :items="matchingResults" :item-height="50" height="400">
      <template v-slot="{ item }">
        <v-row
          no-gutters
          @click="onItemClick(item)"
          class="item-wrapper pt-3"
          justify="space-between"
          ><v-col cols="11"
            ><span class="truncate text-no-wrap"
              >{{ item.name }}-{{ item.code }}</span
            ></v-col
          ><v-col cols="1"><v-icon>mdi-chevron-right</v-icon></v-col></v-row
        >
        <v-row no-gutters class="py-3"><v-divider></v-divider></v-row>
      </template>
    </v-virtual-scroll>
  </v-row>
</template>

<script>
export default {
  name: 'AirlineOptions',
  props: {
    queryKeyword: {
      type: String,
      default: '',
    },
    allAirlines: Array,
  },
  methods: {
    filterResults(query) {
      return this.allAirlines.filter((airline) => {
        const regex = new RegExp(query, 'gi');
        return airline.name.match(regex) || airline.code.match(regex);
      });
    },
    onItemClick(item) {
      this.$emit('select', item);
    },
  },
  computed: {
    matchingResults() {
      return this.queryKeyword == ''
        ? this.allAirlines
        : this.filterResults(this.queryKeyword);
    },
  },
};
</script>
<style lang="scss" scoped>
.item-wrapper {
  cursor: pointer;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
