<template>
  <v-dialog v-model="dialog" fullscreen :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="btn-open-flight-list text-left align-center btn-hover px-0"
        v-bind="attrs"
        v-on="on"
        text
        height="36"
      >
        <span class="pl-1 pr-2 d-flex flex-column">
          {{ selectedFlightNumber }}
          <span class="flight-airport-codes flex-nowrap overflow-auto">
            {{ flightAirportCodes }}
          </span>
        </span>
        <v-icon small class="fill-height justify-end flight-chevron">
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>

    <template v-if="!displayRemoveFlights">
      <FlightsSelectionCard
        @onEditClick="showRemoveFlightCard"
        :show="dialog"
        @addFlight:change="isAddFlightShowing = $event"
        @onClose="dialog = false"
        :isAddFlightShowing="isAddFlightShowing"
        @select="dialog = false"
      />
    </template>

    <template v-else>
      <RemoveFlightCard
        @click="hideRemoveFlightCard"
        @close="onRemoveCardClose"
        @return="displayRemoveFlights = false"
      />
    </template>
  </v-dialog>
</template>

<script>
import FlightsHelperMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import FlightsSelectionCard from '@/modules/flights/features/FlightSelection/FlightsSelectionCard';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import RemoveFlightCard from '@/modules/flights/features/FlightSelection/RemoveFlightCard';

export default {
  name: 'FlightsSelectionDialogV2',
  components: { FlightsSelectionCard, RemoveFlightCard },
  mixins: [FlightsHelperMixins, GoogleTagManagerMixins],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
      isAddFlightShowing: false,
      displayRemoveFlights: false,
    };
  },

  computed: {
    title() {
      return this.hasArrived
        ? this.$t('flights.arrived')
        : this.hasDeparted
        ? this.$t('flights.landing_in')
        : this.$t('flights.departing_in');
    },
  },

  methods: {
    showRemoveFlightCard() {
      this.displayRemoveFlights = true;
    },

    hideRemoveFlightCard() {
      this.displayRemoveFlights = false;
      this.pushRemoveFlight();
      if (!!this.nextValidFlight) {
        this.setSelectedFlight(this.nextValidFlight);
      } else {
        this.clearSelectedFlight();
      }
    },

    onRemoveCardClose() {
      this.displayRemoveFlights = false;
      this.dialog = false;
    },
  },

  watch: {
    dialog(newVal, oldVal) {
      if (!newVal) this.isAddFlightShowing = false;
    },

    selectedContextFlight(val) {
      if (!!val) {
        this.isAddFlightShowing = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn.tertiary {
  font-size: 11px;
}

.btn-hover:hover {
  background: #f6f6f6;
}

.flight-airport-codes {
  @include font-size(12, 150, 500);
}

.btn-open-flight-list {
  @include font-size(14, 150, 500);
  border: 1px solid var(--v-grey1-darken1);
}
.flight-chevron {
  color: var(--v-grey1-darken4) !important;
}
</style>
