const DiningResultsPage = () =>
  import('@/modules/product/dining/DiningResultsPage.vue');
const MapComponent = () => import('@/modules/product/dining/MapComponent.vue');

const DiningRoute = {
  path: '/dining',
  alias: '/place',
  component: () =>
    import(
      /* webpackChunkName: "Dining" */ '@/modules/product/dining/DiningPage.vue'
    ),
  children: [
    {
      path: '',
      name: 'dining-landing',
      meta: {
        showStagesFooter: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "dining-landing" */ '@/modules/product/dining/DiningLandingPage.vue'
        ),
      children: [
        {
          path: '',
          name: 'dining-results',
          meta: {
            showStagesFooter: false,
          },
          props: {
            results: (route) => ({
              city: route.query.city,
              searchQuery: route.query.q,
              lng: route.query.lng || route.query.long,
              lat: route.query.lat,
              minPriceScale: route.query['min-price-scale'],
              maxPriceScale: route.query['max-price-scale'],
              minRating: route.query['min-rating'],
              radius: route.query.radius,
            }),
          },
          components: {
            results: DiningResultsPage,
          },
        },
        {
          path: 'map',
          name: 'dining-map',
          props: {
            'results-map': (route) => ({
              city: route.query.city,
              searchQuery: route.query.q,
              lng: route.query.lng,
              lat: route.query.lat,
              minPriceScale: route.query['min-price-scale'],
              maxPriceScale: route.query['max-price-scale'],
              minRating: route.query['min-rating'],
              radius: route.query.radius,
            }),
            map: (route) => ({
              city: route.query.city,
              searchQuery: route.query.q,
              lng: route.query.lng,
              lat: route.query.lat,
              minPriceScale: route.query['min-price-scale'],
              maxPriceScale: route.query['max-price-scale'],
              minRating: route.query['min-rating'],
              radius: route.query.radius,
            }),
          },
          components: {
            'results-map': DiningResultsPage,
            map: MapComponent,
          },
        },
      ],
    },
    {
      path: ':id',
      props: (route) => ({
        lng: route.query.lng,
        lat: route.query.lat,
      }),
      component: () =>
        import(
          /* webpackChunkName: "dining-details" */ '@/modules/product/dining/DiningProduct.vue'
        ),
      children: [
        {
          path: '',
          name: 'dining-details',
          component: () =>
            import(
              /* webpackChunkName: "dining-details" */ '@/modules/product/dining/DiningDetailsPage.vue'
            ),
        },
      ],
    },
    {
      path: '*',
      redirect: '/dining',
    },
  ],
};

export default DiningRoute;
