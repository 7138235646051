import { RepoFactory } from '@/core/repositories';
import * as mutations from './mutation-types';
import { mapboxService } from '@/core/services/MapboxService';

export const actions = {
  async loadInformationModuleItems({ commit }, id) {
    commit(mutations.INFORMATION_MODULE_CONTENT_LOADING, true);
    commit(mutations.INFORMATION_MODULE_CONTENT_ERROR, { error: null });
    const informationRepo = RepoFactory.get('information');

    try {
      const response = await informationRepo.getInformationModuleItems(id);

      if (!!response && !!response.data) {
        commit(mutations.INFORMATION_MODULE_CONTENT_LOADED, response.data);
      } else {
        commit(mutations.INFORMATION_MODULE_CONTENT_ERROR, {
          error: `No content found`,
        });
      }
    } catch (e) {
      let errorObject = {
        message: e.toString(),
        status: e && e.response ? e.response.status : null,
      };
      commit(mutations.INFORMATION_MODULE_CONTENT_ERROR, {
        error: errorObject,
      });
    } finally {
      commit(mutations.INFORMATION_MODULE_CONTENT_LOADING, false);
    }
  },

  async clearError({ commit }) {
    commit(mutations.INFORMATION_MODULE_CONTENT_ERROR, { error: null });
  },

  async loadArticles({ commit }, queryString) {
    commit(mutations.ARTICLES_LOADING, true);
    commit(mutations.ARTICLES_ERROR, { error: null });
    const informationRepo = RepoFactory.get('information');

    try {
      const response = await informationRepo.getArticles(queryString);
      if (!!response && !!response.data) {
        commit(mutations.ARTICLES_LOADED, response.data);
      } else {
        commit(mutations.ARTICLES_ERROR, {
          error: `No content found`,
        });
      }
    } catch (e) {
      commit(mutations.ARTICLES_ERROR, {
        error: e.response,
      });
    } finally {
      commit(mutations.ARTICLES_LOADING, false);
    }
  },
  async loadArictleProducts({ commit }, { productIds }) {
    const catalogGroupService = RepoFactory.get('catalogGroup');
    commit(mutations.ARTICLE_PRODUCTS_ERROR, { error: null });
    commit(mutations.ARTICLE_PRODUCTS_LOADING, true);
    try {
      const filterData = (data) => {
        if (!data) {
          throw 'Undefined catalogGroups response';
        }
        if (!data.groups) {
          throw "Missing 'groups' object";
        }

        let allowedTypes = ['Item', 'WebItem'];

        let filteredData = data.groups.filter((x) =>
          allowedTypes.includes(x.type)
        );

        return { groups: filteredData };
      };

      const { data } = await catalogGroupService.getCatalog({
        productIds,
      });

      commit(mutations.ARTICLE_PRODUCTS_LOADED, { products: filterData(data) });
    } catch (error) {
      commit(mutations.ARTICLE_PRODUCTS_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.ARTICLE_PRODUCTS_LOADING, false);
    }
  },

  async performCitySearch({ commit }, location) {
    commit(mutations.CITY_SEARCH_ERROR, { error: null });
    commit(mutations.CITY_SEARCH_LOADING, true);
    try {
      const data = await mapboxService.getCities(location);
      commit(mutations.CITY_SEARCH_COMPLETED, {
        result: data,
      });
    } catch (error) {
      commit(mutations.CITY_SEARCH_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.CITY_SEARCH_LOADING, false);
    }
  },

  async performReverseGeocode({ commit }, { lat, lng }) {
    commit(mutations.REVERSE_GEOCODE_ERROR, { error: null });
    commit(mutations.REVERSE_GEOCODE_LOADING, true);
    try {
      const data = await mapboxService.reverseGeocode({ lat: lat, lng: lng });
      commit(mutations.REVERSE_GEOCODE_COMPLETED, { result: data });
    } catch (error) {
      commit(mutations.REVERSE_GEOCODE_ERROR, { error: error.toString() });
    } finally {
      commit(mutations.REVERSE_GEOCODE_LOADING, false);
    }
  },
};
