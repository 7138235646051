<template>
  <v-sheet
    flat
    color="white"
    class="navigation-bar"
    data-cy="top-navigation-bar"
    :class="{
      'no-sticky': hideMenu,
    }"
  >
    <template v-if="hideMenu">
      <div class="logo-icon__wrapper d-flex flex-row align-center">
        <v-img
          alt="logo"
          contain
          :src="logo"
          transition="scale-transition"
          height="60"
          position="center center"
        />
      </div>
    </template>

    <template v-else>
      <div class="fill-height">
        <v-row justify="center" class="py-0 px-0 fill-height" no-gutters>
          <v-col cols="12" class="px-3">
            <v-row
              justify="space-between"
              class="fill-height align-center full-width ma-0"
            >
              <v-col
                cols="auto"
                class="px-0 logo-icon__wrapper d-flex flex-row align-center"
              >
                <router-link :to="{ name: 'concierge' }">
                  <v-img
                    data-cy="navigation-bar-airline-logo"
                    class="logo-icon"
                    alt="logo"
                    contain
                    :src="logo"
                    transition="scale-transition"
                    max-height="60"
                    :max-width="getIconMobileWidth"
                    position="center center"
                  />
                </router-link>
              </v-col>
              <v-col class="px-2 ml-3">
                <template v-if="hasSelectedContextFlight">
                  <FlightSelectionV2 />
                </template>

                <template v-else>
                  <v-menu
                    v-model="isAddFlightShowing"
                    attach
                    :close-on-content-click="false"
                    :nudge-bottom="10"
                    offset-y
                    tabindex="0"
                    max-width="420"
                    role="button"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        data-cy="navigation-bar-add-flight-button"
                        class="py-3 ml-2 ml-md-4 btn-hover add-flight-btn"
                        depressed
                        text
                        outlined
                        rounded
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="18">mdi-plus</v-icon>
                        {{ $t('flights.add_a_flight') }}
                      </v-btn>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp">
                      <v-card width="420" rounded>
                        <v-row no-gutters class="pt-5 px-4 pb-4">
                          <v-col>
                            <AddFlightContainer
                              class="add-flight-component"
                              @onReturn="closeMenu"
                              @onClose="closeMenu"
                              :has-return-button="true"
                              analytics-origin="menu"
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </template>

                    <template v-else>
                      <AddFlightModal
                        :isOpen="isAddFlightShowing"
                        @closeMenu="closeMenu"
                      />
                    </template>
                  </v-menu>
                </template>
              </v-col>
              <v-col cols="2" md="6" class="d-flex justify-end pr-0">
                <template v-if="$vuetify.breakpoint.lgAndUp">
                  <v-btn
                    data-cy="navigation-bar-module-hook-buttons"
                    v-for="menuItem in enabledMenuBarItems"
                    :key="menuItem.id"
                    type="plain"
                    depressed
                    outlined
                    class="px-3 btn-hover white navigation-button"
                    :href="
                      !!menuItem.external &&
                      !!menuItem.link &&
                      !!menuItem.link.to
                        ? menuItem.link.to
                        : !!menuItem.link && !!menuItem.link.href
                        ? menuItem.link.href
                        : ''
                    "
                    :to="
                      !!menuItem.external === false &&
                      !!menuItem.link &&
                      !!menuItem.link.to
                        ? menuItem.link.to
                        : ''
                    "
                    :target="
                      !!menuItem.link &&
                      !!menuItem.link.href &&
                      !!menuItem.enable
                        ? '_blank'
                        : ''
                    "
                  >
                    {{ menuItem.text }}

                    <template
                      v-if="
                        !!menuItem.link &&
                          !!menuItem.link.href &&
                          !!menuItem.enable
                      "
                    >
                      <v-icon size="16" class="ml-1"> mdi-open-in-new </v-icon>
                    </template>
                  </v-btn>

                  <template
                    v-if="internationlizationFF && hasMultipleLanguages"
                  >
                    <v-divider
                      vertical
                      :color="$vuetify.theme.themes.light.grey3"
                      class="ml-2"
                    />
                    <div class="mx-2">
                      <LanguageSwitcher :languages="airlineLanguages" />
                    </div>
                  </template>
                  <v-app-bar-nav-icon
                    data-cy="navigation-bar-nav-drawer-button"
                    class="btn-hover mr-n2"
                    @click.stop="switchNavState"
                  />
                </template>
                <template v-else>
                  <template
                    v-if="internationlizationFF && hasMultipleLanguages"
                  >
                    <LanguageSwitcher :languages="airlineLanguages" />
                  </template>

                  <v-app-bar-nav-icon
                    class="btn-hover"
                    @click.stop="switchNavState"
                  />
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-sheet>
</template>

<script>
import LanguageSwitcher from '@/core/components/LanguageSwitcher';
import FlightSelectionV2 from '@/modules/flights/features/FlightSelection/FlightSelectionV2';
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';
import NavigationMenuConfigMixins from '@/core/mixins/NavigationMenuConfigMixins';
import AddFlightContainer from '@/modules/flights/features/AddFlight/AddFlightContainer';
import AddFlightModal from '@/modules/common/components/AddFlightModal';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'NavigationBar',
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddFlightModal,
    AddFlightContainer,
    FlightSelectionV2,
    LanguageSwitcher,
  },
  mixins: [ConciergeHomeMixins, NavigationMenuConfigMixins, ConfigsMixins],

  data() {
    return {
      menu: false,
      isAddFlightShowing: false,
      internationlizationFF: false,
    };
  },

  created() {
    this.checkInternationalizationFF();
  },

  computed: {
    enabledMenuBarItems() {
      if (this.menuBarItems) {
        return this.menuBarItems.filter((item) => item.enable);
      } else {
        return [];
      }
    },

    getIconMobileWidth() {
      if (this.$vuetify.breakpoint.smAndDown) return 85;
      else if (this.$vuetify.breakpoint.md) return 160;
      else return null;
    },
  },

  methods: {
    closeMenu() {
      this.isAddFlightShowing = false;
    },

    async checkInternationalizationFF() {
      this.internationlizationFF = await this.$ldclient.variation(
        'internationalization',
        false,
        true
      );
      this.$ldclient.$on('change:internationalization', (value) => {
        this.internationlizationFF = value;
      });
    },

    switchNavState() {
      this.$emit('openNavDrawer');
    },
  },

  watch: {
    hasSelectedContextFlight(newVal) {
      if (!!newVal) {
        this.isAddFlightShowing = false;
      }

      if (!this.hasContextFlights) {
        this.$store.dispatch('setFlightSelectorStatus', newVal);
      }
    },

    isAddFlightShowing(val) {
      this.$store.dispatch('setFlightSelectorStatus', val);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.navigation-bar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 6;
  height: 80px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05);

  &.no-sticky {
    position: static;
    pointer-events: none;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-icon__wrapper {
      max-width: 200px;

      @media (min-width: map-get($grid-breakpoints, md)) {
        max-width: unset;
      }
    }
  }

  .logo-icon__wrapper {
    cursor: pointer;
  }
}

.add-flight-component {
  background: white;
}

.v-btn:before {
  background: transparent;
}

.btn-hover:hover {
  background: #f6f6f6 !important;
}

.add-flight-btn {
  @include font-size(14, 150, 500);
  padding-left: 10px !important;
  padding-right: 14px !important;

  @media (min-width: map-get($grid-breakpoints, md)) {
    padding-left: 14px !important;
    padding-right: 18px !important;
  }
}

.v-btn--active {
  background: #f6f6f6 !important;

  &:hover {
    background: #f6f6f6 !important;
    opacity: 1 !important;

    &:before {
      background: #f6f6f6 !important;
    }
  }
}

.logo-icon {
  max-width: 80px;
  max-height: 60px;

  @media (min-width: map-get($grid-breakpoints, xs)) {
    max-width: 160px;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    max-width: 180px;
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    max-width: 200px;
  }

  &__wrapper {
    max-width: 80px;
    max-height: 60px;

    @media (min-width: map-get($grid-breakpoints, xs)) {
      max-width: 160px;
    }

    @media (min-width: map-get($grid-breakpoints, md)) {
      height: 60px;
      max-width: unset;
    }
  }
}

.navigation-button {
  @include font-size(14, 140, 400);

  @media (min-width: map-get($grid-breakpoints, lg)) {
    @include font-size(16, 150, 400);
  }
}
</style>
