const InformationDetailsPage = () => import('./InformationDetailsPage');
const InformationPage = () => import('./InformationPage');
const InformationLandingPage = () => import('./InformationLandingPage');

export default {
  path: '/information',
  component: InformationPage,
  children: [
    {
      path: '',
      name: 'information-landing',
      component: InformationLandingPage,
    },
    {
      path: ':id',
      name: 'information-details',
      component: InformationDetailsPage,
    },
  ],
};
