export const FLIGHTS_LOADING = 'FLIGHTS_LOADING';
export const FLIGHTS_LOADED = 'FLIGHTS_LOADED';
export const FLIGHTS_ERROR = 'FLIGHTS_ERROR';
export const FLIGHTS_SEARCH_CLEAR_RESULTS = 'FLIGHTS_SEARCH_CLEAR_RESULTS';
export const USER_FLIGHTS_LOADING = 'USER_FLIGHTS_LOADING';
export const USER_FLIGHTS_SET = 'USER_FLIGHTS_SET';
export const USER_FLIGHTS_ERROR = 'USER_FLIGHTS_ERROR';
export const FLIGHT_BOOKINGS_ERROR = 'FLIGHT_BOOKINGS_ERROR';
export const FLIGHT_BOOKINGS_LOADING = 'FLIGHT_BOOKINGS_LOADING';
export const FLIGHT_BOOKINGS_LOADED = 'FLIGHT_BOOKINGS_LOADED';
export const FLIGHT_CONTEXT_CLEAR = 'FLIGHT_CONTEXT_CLEAR';
export const FLIGHT_CONTEXT_APPEND_ALL = 'FLIGHT_CONTEXT_APPEND_ALL';
export const FLIGHT_CONTEXT_LIST_UPDATE = 'FLIGHT_CONTEXT_LIST_UPDATE';
export const FLIGHT_ARCHIVE_LIST_UPDATE = 'FLIGHT_ARCHIVE_LIST_UPDATE';
export const FLIGHT_CLEANING_TIMESTAMP_UPDATE =
  'FLIGHT_CLEANING_TIMESTAMP_UPDATE';
export const FLIGHT_RECENTLY_ADDED_FLIGHTS = 'FLIGHT_RECENTLY_ADDED_FLIGHTS';
export const FLIGHT_CONTEXT_REMOVE = 'FLIGHT_CONTEXT_REMOVE';
export const FLIGHT_SELECTED_CONTEXT_SET = 'FLIGHT_CONTEXT_SET';
export const FLIGHT_SELECTED_CONTEXT_CLEAR = 'FLIGHT_SELECTED_CONTEXT_CLEAR';
export const ADD_FLIGHT_BOOKINGS_ERROR = 'ADD_FLIGHT_BOOKINGS_ERROR';
export const ADD_FLIGHT_BOOKINGS_LOADING = 'ADD_FLIGHT_BOOKINGS_LOADING';
export const USER_FLIGHTS_ADDED = 'USER_FLIGHTS_ADDED';
export const CLEAR_FLIGHT_CREATION_ERROR = 'CLEAR_FLIGHT_CREATION_ERROR';
export const DELETE_FLIGHT_BOOKING_ERROR = 'DELETE_FLIGHT_BOOKING_ERROR';
export const DELETE_FLIGHT_BOOKING_LOADING = 'DELETE_FLIGHT_BOOKING_LOADING';
export const FLIGHT_DELETED = 'FLIGHT_DELETED';
export const CLEAR_FLIGHT_DELETION = 'CLEAR_FLIGHT_DELETION';
export const FLIGHT_DUPLICATE_ERROR = 'FLIGHT_DUPLICATE_ERROR';
export const CLEAR_FLIGHT_DUPLICATE_ERROR = 'CLEAR_FLIGHT_DUPLICATE_ERROR';
export const SET_PAST_FLIGHTS = 'SET_PAST_FLIGHTS';
export const SET_UPCOMING_FLIGHTS = 'SET_UPCOMING_FLIGHTS';
export const PAST_FLIGHTS_CLEAR = 'PAST_FLIGHTS_CLEAR';
export const UPCOMING_FLIGHTS_CLEAR = 'UPCOMING_FLIGHTS_CLEAR';
export const CLEAR_FLIGHTS = 'CLEAR_FLIGHTS';
export const SET_SHOW_ADD_FLIGHT = 'SET_SHOW_ADD_FLIGHT';
export const SET_INVALID_FLIGHT_ALERT = 'SET_INVALID_FLIGHT_ALERT';
export const CLEAR_INVALID_FLIGHT_ALERT = 'CLEAR_INVALID_FLIGHT_ALERT';
export const SET_INVALID_FLIGHTS = 'SET_INVALID_FLIGHTS';
export const SET_CODE_SHARE_ALERT = 'SET_CODE_SHARE_ALERT';
export const SET_CODE_SHARE_INFO = 'SET_CODE_SHARE_INFO';
