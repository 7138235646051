<template>
  <v-container fluid class="py-0">
    <v-row no-gutters>
      <v-dialog
        v-on:click:outside="onHide"
        :max-width="448"
        :overlay-opacity="0.8"
        :value="isModalOpen"
      >
        <v-card height="580">
          <v-container class="px-0 fill-height">
            <v-row no-gutters class="fill-height">
              <FlightSearchForm
                v-if="showForm"
                :airline="selectedAirline"
                :value="selectedFlight"
                :departure="selectedDepartureDate"
                :selectedOptions="selected"
                @hide="onHide"
                @showAirlineOptions="showOptions"
                @submitForm="loadSearchContent"
              >
              </FlightSearchForm>
              <FlightAirlineSearch
                v-if="displayAirlineOptions"
                @hideOptions="hideAirlineOptions"
                :currentAirline="landingPageTitle"
                @update="updateAirlineSelection"
              >
              </FlightAirlineSearch>
              <FlightSearchResults
                v-if="showResult"
                @click="reset"
                @submit="handleSubmit"
                :searchInfo="searchParams"
              >
              </FlightSearchResults>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import FlightSearchForm from './FlightSearchForm.vue';
import FlightAirlineSearch from './FlightAirlineSearch';
import FlightsMixins from '../mixins/FlightsMixins.vue';
import HomePageMixins from '../../home/mixins/HomePageMixins';
import FlightSearchResults from './FlightSearchResults.vue';
import TravelerMixins from '@/modules/common/mixins/TravelerMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
export default {
  name: 'FlightPopOverModal',
  components: { FlightSearchForm, FlightAirlineSearch, FlightSearchResults },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    FlightsMixins,
    HomePageMixins,
    TravelerMixins,
    GoogleTagManagerMixins,
  ],
  data() {
    return {
      dialog: false,
      showForm: true,
      showResult: false,
      displayAirlineOptions: false,
      waitForUserFlight: false,
      searchParams: null,
      selected: { departureDateSelected: null, flightNumberSelected: null },
      selectedAirline: { name: 'Airline', code: '1' },
      flightInfo: null,
    };
  },
  methods: {
    reset() {
      this.dialog = false;
      this.showResult = false;
      this.showForm = true;
      this.displayAirlineOptions = false;
      this.searchParams = null;
      this.selected = {
        departureDateSelected: null,
        flightNumberSelected: null,
      };
      this.selectedAirline = { name: 'Airline', code: '1' };
    },
    hideAirlineOptions() {
      this.displayAirlineOptions = false;
      this.showForm = true;
    },
    updateAirlineSelection(airlineInfo) {
      this.displayAirlineOptions = false;
      this.selectedAirline = airlineInfo || '';
    },
    loadSearchContent(payload, flight) {
      const { flightNumber, departureDate } = payload;
      this.searchParams = payload;
      this.selected = {
        flightNumberSelected: flight,
        departureDateSelected: departureDate,
      };
      this.loadFlights({
        flightNumber,
        departureDate,
      });
      this.showForm = false;
      this.showResult = true;
    },
    showOptions(flightNumberSelected, departureDateSelected) {
      this.searchParams = null;
      this.showForm = false;
      this.displayAirlineOptions = true;
      this.selected = {
        flightNumberSelected,
        departureDateSelected,
      };
    },
    handleSubmit(flights) {
      if (!!flights.length) {
        if (this.hasFlightAddedError || this.hasFlightDuplicateError) {
          this.clearFlightCreateError();
          this.clearFlightDuplicateError();
          this.reset();
          return;
        }
        this.addFlightBookings(flights);
      } else this.reset();
    },
    onHide() {
      this.$emit('hide');
      this.reset();
    },

    addFlightBookings(flights) {
      this.flightInfo = flights;
      if (!this.isFlightDuplicate(flights)) {
        this.addFlightsToContext(flights);
        if (this.travelerId) {
          this.addUserFlightBookings(this.travelerId, flights);
          this.waitForUserFlight = true;
        } else {
          this.$emit('update');
          this.reset();
        }
      } else {
        this.setFlightDuplicateError();
      }
    },
    formatFlights(flights) {
      return flights.map((flight) => {
        return {
          origin: flight.origin.iata,
          destination: flight.destination.iata,
          departure: flight.departureTime,
        };
      });
    },
    isFlightDuplicate(flights) {
      if (this.contextFlights.length > 0) {
        const formattedContextFlights = this.formatFlights(this.contextFlights);
        const formattedFlightsToAdd = this.formatFlights(flights);
        return formattedContextFlights.some((flight) =>
          formattedFlightsToAdd.find(
            (x) =>
              x.origin === flight.origin &&
              x.destination === flight.destination &&
              x.departure === flight.departure
          )
        );
      } else return false;
    },
  },
  computed: {
    selectedFlight() {
      return this.flightSearchResults && this.flightSearchResults.flightNumber
        ? this.flightSearchResults.flightNumber.slice(2)
        : null;
    },
    selectedDepartureDate() {
      return this.flightSearchResults
        ? this.flightSearchResults.departureDate
        : null;
    },
  },
  watch: {
    hasAddedFlight(newVal) {
      if (newVal && this.waitForUserFlight) {
        this.$emit('update');
        this.reset();
        this.waitForUserFlight = false;
        this.pushAddFlightAnalytics(this.flightInfo, 'list', 'Add Flight');
      }
    },
    hasContextFlights(newVal) {
      if (newVal) {
        this.pushAddFlightAnalytics(this.flightInfo, 'list', 'Add Flight');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.fill-height {
  height: 100%;
}
</style>
