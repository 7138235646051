import store from '../../index';

export const waitForConfigs = () => {
  return new Promise((resolve, reject) => {
    if (store.getters['configsReady']) {
      resolve(store);
    } else {
      store.watch(
        (state) => state.configs,
        (value) => {
          resolve(store);
        }
      );
    }
  });
};
