<template>
  <v-container class="pb-0 pt-5" fluid>
    <carousel
      class="mx-0"
      :scrollPerPage="$vuetify.breakpoint.lgAndUp"
      :perPage="carouselSlideSize"
      :navigationEnabled="$vuetify.breakpoint.lgAndUp"
      :paginationEnabled="false"
      :touchDrag="true"
      :mouseDrag="true"
      :navigationNextLabel="navigationNext"
      :navigationPrevLabel="navigationPrev"
    >
      <slide
        v-for="(item, index) in items"
        :key="index"
        data-cy="category-item"
        class="slide pa-1 pa-md-3"
      >
        <template v-if="!isItemCatalogType">
          <WebItemComponent
            :title="item.title"
            :subTitle="item.subTitle"
            :imageUrl="item.thumbnail"
            :linkUrl="item.url"
            @click="onProductClick(item)"
          />
        </template>

        <template v-else>
          <ProductItemContainer
            @click="onProductClick(item)"
            :item="item"
            :aspectRatio="dynamicAspectRatio"
          />
        </template>
      </slide>
    </carousel>
  </v-container>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import ProductItemContainer from './ProductItemContainer';
import WebItemComponent from './WebItemComponent';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'CarouselComponent',
  mixins: [GoogleTagManagerMixins],
  components: {
    Carousel,
    Slide,
    ProductItemContainer,
    WebItemComponent,
  },

  props: {
    displayPage: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    catalogType: {
      type: String,
      default: 'Item',
      validator: (value) =>
        ['Item', 'WebItem', 'QueryItem'].indexOf(value) !== -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: Number,
      default: 220,
    },
  },

  data() {
    return {
      navigationNext:
        '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle id="circle1" opacity="0.8" cx="24" cy="24" r="24" fill="white"/><path d="M23 30L29 24L23 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      navigationPrev:
        '<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle id="circle1" opacity="0.8" cx="24" cy="24" r="24" fill="white"/><path d="M25 30L19 24L25 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    };
  },

  methods: {
    onProductClick(product) {
      this.pushProductClickAnalytics(
        product,
        this.title,
        'list',
        this.displayPage
      );
    },
  },

  computed: {
    isItemCatalogType() {
      return this.catalogType === 'Item';
    },

    carouselSlideSize() {
      return this.isItemCatalogType
        ? this.carouselSlideSizeForItem
        : this.carouselSlideSizeForWebOrQueryItem;
    },

    carouselSlideSizeForWebOrQueryItem() {
      let pageSize = 3;
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 1.1;
      }
      return this.items.length >= pageSize ? pageSize : 3;
    },

    carouselSlideSizeForItem() {
      let pageSize = 6;
      if (this.$vuetify.breakpoint.mdAndDown) {
        pageSize = 3;
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 2.1;
      }
      return this.items.length >= pageSize ? pageSize : 3;
    },

    dynamicAspectRatio() {
      let ratio = 0.92;
      if (this.$vuetify.breakpoint.lgAndUp && this.carouselSlideSize < 4) {
        ratio = this.carouselSlideSize === 3 ? 4 / 3 : 16 / 9;
      }
      return ratio;
    },
  },
};
</script>

<style scoped lang="scss">
.slide {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.slide:first-child {
  padding-left: 0 !important;
}

.slide:last-child {
  padding-right: 0 !important;
}

.link {
  text-decoration: none;
}

::v-deep .VueCarousel-navigation-next {
  transform: translateY(-100%) translateX(-30%) !important;
}

::v-deep .VueCarousel-navigation-prev {
  transform: translateY(-100%) translateX(30%) !important;
}

::v-deep #circle1:hover {
  cursor: pointer;
  opacity: 1.5;
}
</style>
