import bookingRoutes from './booking/route';
import gtRoutes from './transportation/route';
import diningRoutes from './dining/route';
import advicaRoutes from './advica/route';
import shopRoutes from './shop/route';

export default [
  bookingRoutes,
  ...shopRoutes,
  gtRoutes,
  diningRoutes,
  advicaRoutes,
  {
    path: '/product/not-found',
    name: 'product-not-found',
    component: () =>
      import(
        /* webpackChunkName: "product-not-found" */ '@/modules/product/features/ProductNotFound/ProductNotFound.vue'
      ),
  },
];
