import { namedPaymentRoute } from '@/modules/product/features/Payment/route.js';
const productRoutes = {
  path: '/booking/:id',
  component: () =>
    import(
      /* webpackChunkName: "product-booking" */ '@/modules/product/booking/ProductPage.vue'
    ),
  children: [
    {
      path: '',
      name: 'booking-details',
      component: () =>
        import(
          /* webpackChunkName: "product-booking" */ '@/modules/product/booking/ProductDetailsPage.vue'
        ),
    },
    {
      path: 'checkout',
      component: () =>
        import(
          /* webpackChunkName: "product-booking" */ '@/modules/product/booking/ProductCheckoutPage.vue'
        ),
      children: [
        {
          path: '',
          name: 'booking-form',
          component: () =>
            import(
              /* webpackChunkName: "product-booking-traveler" */ '@/modules/product/booking/BookingFormPage.vue'
            ),
        },
        namedPaymentRoute('booking-payment', {
          fallbackPathName: 'booking-details',
          storeModuleNameFn: (id) => `product/${id}`,
        }),
      ],
    },
    {
      path: '*',
      redirect: '/booking/:id',
    },
  ],
};

export default productRoutes;
