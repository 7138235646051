<template>
  <v-footer
    absolute
    padless
    class="app-footer"
    :class="{
      'pb-15': needsPadding && $vuetify.breakpoint.smAndDown,
      'is-product-page': isProductPage,
    }"
  >
    <v-container class="app-footer-links py-0 py-md-3" fluid>
      <v-row justify="space-between" align="center">
        <v-col cols="8" sm="7" class="pr-4 pl-7 py-6">
          <v-row no-gutters>
            <v-col cols="12">
              <a
                class="footer-links"
                :href="termsAndConditionsUrl"
                target="_blank"
                >{{ $t('shell.terms') }}
              </a>
              <a class="footer-links" :href="privacyPolicyUrl" target="_blank"
                >{{ $t('shell.privacy') }}
              </a>
              <a class="footer-links" :href="helpAndFeedbackUrl" target="_blank"
                >{{ $t('shell.support') }}
              </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="4"
          sm="5"
          class="
            pa-0
            pr-7
            pa-sm-3
            d-flex
            justify-center justify-sm-end
            align-center
          "
        >
          <span class="footer-logo">
            {{ $t('shell.powered_by') }}
            <a href="https://www.guestlogix.com" target="_blank">
              Guestlogix
            </a>
          </span>
          <svg
            width="21"
            height="32"
            viewBox="0 0 21 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.3811 2.9134C20.3854 1.43692 19.2814 0.192089 17.815 0.0199968C16.3486 -0.152095 14.9864 0.803311 14.6488 2.24069C10.5615 0.252219 5.65158 1.21098 2.61266 4.59096C-0.426267 7.97094 -0.85934 12.9549 1.55104 16.8083C3.96142 20.6618 8.63242 22.4531 13.0014 21.1994C17.3703 19.9458 20.3808 15.9503 20.3811 11.405V3.1842C20.3754 3.08728 20.3811 3.00177 20.3811 2.9134ZM10.1934 15.7805C7.77693 15.7805 5.81795 13.8215 5.81795 11.405C5.81795 8.98848 7.77693 7.02951 10.1934 7.02951C12.61 7.02951 14.5689 8.98848 14.5689 11.405C14.5689 12.566 14.1076 13.6793 13.2864 14.5C12.4652 15.3206 11.3515 15.7813 10.1906 15.7805H10.1934Z"
              fill="var(--v-grey7-base)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.191 26.1932C8.43289 26.1936 6.84533 25.1415 6.16041 23.5223C5.74822 22.499 4.76081 21.8243 3.65768 21.812H3.28427C2.36714 21.8093 1.50871 22.2628 0.994182 23.0221C0.479658 23.7813 0.376443 24.7467 0.718831 25.5975C2.2682 29.4647 6.01497 31.9999 10.181 31.9999C14.3471 31.9999 18.0939 29.4647 19.6432 25.5975C19.9856 24.7467 19.8824 23.7813 19.3679 23.0221C18.8533 22.2628 17.9949 21.8093 17.0778 21.812H16.7044C15.6012 21.8243 14.6138 22.499 14.2016 23.5223C13.5196 25.1349 11.9419 26.1856 10.191 26.1932Z"
              fill="var(--v-grey7-base)"
            />
          </svg>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import ConfigsMixins from '@/core/mixins/ConfigsMixins';

export default {
  name: 'AppFooter',
  mixins: [ConfigsMixins],

  data() {
    return {
      needsPadding: false,
      internationlizationFF: false,
    };
  },

  created() {
    this.checkInternationalizationFF();
  },

  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (
          (!!val && val.name === 'dining-details') ||
          (!!val && val.name === 'transportation-details') ||
          (!!val && val.name === 'booking-details')
        ) {
          this.needsPadding = true;
        } else {
          this.needsPadding = false;
        }
      },
    },
  },

  methods: {
    async checkInternationalizationFF() {
      this.internationlizationFF = await this.$ldclient.variation(
        'internationalization',
        false,
        true
      );
      this.$ldclient.$on('change:internationalization', (value) => {
        this.internationlizationFF = value;
      });
    },
  },

  computed: {
    isProductPage() {
      return this.$route?.name === 'shop-product-details';
    },
  },
};
</script>

<style scoped lang="scss">
.app-footer {
  background-color: white;
}

.app-footer-links {
  color: var(--v-grey6-base);
  font-size: 14px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    font-size: 16px;
  }
  a {
    color: var(--v-primary-base);
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 14px;

      @media (min-width: map-get($grid-breakpoints, md)) {
        margin-right: 37px;
      }
    }
  }
}

.footer-logo {
  color: var(--v-grey7-base);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  max-width: 90px;
  margin-right: 10px;
  font-size: 10px;

  @media (min-width: map-get($grid-breakpoints, sm)) {
    flex-direction: row;
    max-width: 100%;
    align-items: center;
    font-size: 16px;
  }

  a {
    font-size: 14px;
    margin: 0;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      font-size: 16px;
      margin-left: 4px;
    }
  }
}

.is-product-page {
  margin-bottom: 110px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    margin-bottom: 0;
  }
}
</style>
