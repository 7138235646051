var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pb-0 pt-3 px-0",attrs:{"fluid":"","data-cy":"my-flights-container-list-rows"}},[_c('AddFlightPreOrderWarningModal',{attrs:{"open":_vm.isModalOpen,"flight":_vm.flight},on:{"close":_vm.closePreOrderWarningModal}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-5 mt-sm-2 flight-info",attrs:{"cols":"10"}},[_c('span',{class:['flight-info-city', { isActive: _vm.isActive }],attrs:{"data-cy":"my-flights-container-list-flight-city"}},[_vm._v(" "+_vm._s(_vm.getArrivalCityTitle)+" "),_c('v-icon',{attrs:{"size":"14px","color":"#000"}},[_vm._v("mdi-arrow-right")]),_vm._v(" "+_vm._s(_vm.getDepartureCityTitle)+" ")],1),_c('p',{class:['pt-2 mb-2 flight-info-more', { isActive: _vm.isActive }],attrs:{"data-cy":"my-flights-container-list-flight-spec"}},[_vm._v(" "+_vm._s(_vm.flight.flightNumber)+" · "+_vm._s(_vm.formatFlightDateAndTime)+" ")]),(
          _vm.showFlightPreOrder &&
            _vm.hasPreorderAvailabilityStatusNotification(_vm.flight)
        )?[_c('p',{staticClass:"flight-info-pre-order",attrs:{"data-cy":"my-flights-container-list-pre-order-status"}},[(
              _vm.isPreorderUnavailable(_vm.flight) ||
                _vm.currentPreOrderStates.unavailable
            )?[_c('span',[_vm._v(" "+_vm._s(_vm.$t('flights.title_preorder_unavailable'))+" ")]),_c('v-icon',{staticClass:"ml-3",attrs:{"data-cy":"open-pre-order-modal"},on:{"click":function($event){return _vm.openPreOrderWarningModal()}}},[_vm._v(" mdi-help-circle-outline ")])]:(
              _vm.hasPreorderEnded(_vm.flight) || _vm.currentPreOrderStates.ended
            )?[_c('span',[_vm._v(" "+_vm._s(_vm.$t('flights.title_preorder_ended'))+" ")]),_c('v-icon',{staticClass:"ml-3",attrs:{"data-cy":"open-pre-order-modal"},on:{"click":function($event){return _vm.openPreOrderWarningModal()}}},[_vm._v(" mdi-help-circle-outline ")])]:(
              _vm.isPreorderAvailableSoon(_vm.flight) ||
                _vm.currentPreOrderStates.availableSoon
            )?[_c('span',[_vm._v(" "+_vm._s(_vm.$t('flights.title_preorder_available_soon'))+" ")]),_c('v-icon',{staticClass:"ml-3",attrs:{"data-cy":"open-pre-order-modal"},on:{"click":function($event){return _vm.openPreOrderWarningModal()}}},[_vm._v(" mdi-help-circle-outline ")])]:_vm._e()],2)]:_vm._e()],2),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"2"}},[(_vm.isActive)?[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"30","color":"primary","data-cy":"flight-selected-icon"}},[_vm._v(" mdi-check-circle-outline ")])]:[_c('v-btn',{class:[
            'mr-2 pr-0 select-flight',
            { 'disabled-btn': _vm.isSelectFlightDisabled },
          ],attrs:{"data-cy":"select-flight-button","tabindex":"0","text":"","color":"primary"},on:{"click":function($event){return _vm.getSelectButtonClickHandler()}}},[_vm._v(" "+_vm._s(_vm.$t('select'))+" ")])]],2)],1),(_vm.divider)?[_c('v-divider')]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }