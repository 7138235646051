<template>
  <v-container class="form-wrapper fill-height py-0">
    <v-form class="fill-height" ref="form" @submit.prevent="handleSubmit">
      <v-row class="fill-height" no-gutters>
        <v-container class="input-wrapper" fluid>
          <v-row class="title" justify="end" no-gutters
            ><v-col cols="11">{{ $t('flights.find_a_flight') }}</v-col>
            <v-col cols="1"
              ><v-btn text small icon @click="dismiss">
                <v-icon>mdi-close</v-icon></v-btn
              ></v-col
            >
          </v-row>
          <v-container>
            <v-row class="pt-12">
              <v-col cols="8" class="py-0">
                <v-row
                  ><p class="px-1 mb-0 flight-number">
                    {{ $t('flights.airline_title') }}
                  </p></v-row
                >
                <v-row class="px-1 btn-wrapper"
                  ><v-text-field
                    @focus="selectAirline"
                    class="airline-field"
                    single-line
                    outlined
                    dense
                    :value="airline.name"
                    :rules="airlineNameRules"
                    :placeholder="airline.name"
                  >
                  </v-text-field>
                </v-row>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-row
                  ><p class="px-1 mb-0 flight-number">
                    {{ $t('flights.flight_no') }}
                  </p></v-row
                >
                <v-row class="px-1">
                  <v-text-field
                    v-model="flightNumber"
                    single-line
                    outlined
                    dense
                    placeholder="e.g. 001"
                    maxlength="5"
                    :rules="[(v) => !!v || 'Required']"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-1">
              <DateTimeSelector
                @input="onDateTimeChange"
                :timeTab="false"
                :allowPastDates="false"
                :value="dateTime"
                :dateTitle="$t('flights.departure_date')"
              ></DateTimeSelector>
            </v-row>
            <v-row class="px-4 btn-wrapper action-btn">
              <v-col cols="12">
                <v-btn
                  type="submit"
                  depressed
                  :class="['primary', 'full-width']"
                  >{{ $t('flights.search_flights') }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import moment from 'moment';
import FlightsMixins from '../mixins/FlightsMixins';
import DateTimeSelector from '@/core/components/DateTimeSelector';

export default {
  name: 'FlightSearchForm',
  components: {
    DateTimeSelector,
  },
  mixins: [FlightsMixins],
  props: {
    value: String,
    airline: {
      type: Object,
      default: () => ({ name: 'Airline', code: '1' }),
    },
    departure: String,
    selectedOptions: Object,
  },

  data() {
    return {
      dateTime:
        this.departure || !!this.selectedOptions
          ? this.selectedOptions.departureDateSelected
          : null,
      flightNumber:
        this.value || !!this.selectedOptions
          ? this.selectedOptions.flightNumberSelected
          : null,
      pickerDate: !!this.departure
        ? moment(this.departure)
            .format('YYYY-MM')
            .toString()
        : new moment().format('YYYY-MM').toString(),
      airlineNameRules: [
        (v) =>
          v !== this.$t('flights.airline_title') ||
          this.$t('flights.select_airline'),
      ],
    };
  },

  computed: {
    searchParams() {
      return {
        flightNumber: `${this.airline.code}${this.flightSelected}` || null,
        departureDate: this.dateTime || null,
      };
    },

    flightSelected() {
      return this.flightNumber;
    },
  },

  methods: {
    onDateTimeChange(dateTime) {
      if (!!dateTime) {
        this.dateTime = moment(dateTime).format('YYYY-MM-DD HH:mm');
      }
    },

    isNumber($event) {
      var charCode = $event.which ? $event.which : $event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        $event.preventDefault();
        return false;
      }
      return true;
    },

    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit('submitForm', this.searchParams, this.flightSelected);
      }
    },

    dismiss() {
      this.$emit('hide');
    },

    selectAirline() {
      this.$emit('showAirlineOptions', this.flightSelected, this.dateTime);
    },
  },
};
</script>

<style lang="scss" scoped>
.flight-number {
  @include font-size(16, 24, 600);
}
.title {
  @include font-size(20, 24, 600);
  text-align: center;
}
.btn-wrapper {
  cursor: pointer;
}
.input-wrapper {
  position: relative;
}
.action-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.airline-field >>> .v-text-field__slot input {
  color: #05090f !important;
  text-align: center !important;
}
</style>
