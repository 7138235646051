<script>
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import moment from 'moment';
import { mapboxService } from '@/core/services/MapboxService';
import { formatDistance } from 'date-fns';

export default {
  name: 'SelectedContextFlightMixins',
  extends: FlightsMixins,
  data() {
    return {
      isCityMatches: false,
      localeTime: '',
    };
  },
  computed: {
    headerFlightTitle() {
      return this.hasArrived
        ? this.$t('flights.arrived')
        : this.hasDeparted
        ? this.$t('flights.landing_in')
        : this.$t('flights.departing_in');
    },
    flightPathOriginDestination() {
      return this.$t('flights.origin_to_destination_codes', {
        origin: this.selectedContextFlight?.origin?.city,
        destination: this.selectedContextFlight?.destination?.city,
      });
    },
    flightAirportCodes() {
      return this.$t('flights.origin_to_destination_codes', {
        origin: this.originFlightCode,
        destination: this.destinationFlightCode,
      });
    },
    originFlightCode() {
      return this.selectedContextFlight && this.selectedContextFlight.origin
        ? this.selectedContextFlight.origin.iata ||
            this.selectedContextFlight.origin.cityCode
        : null;
    },
    destinationFlightCode() {
      return this.selectedContextFlight &&
        this.selectedContextFlight.destination
        ? this.selectedContextFlight.destination.iata ||
            this.selectedContextFlight.destination.cityCode
        : null;
    },
    flightPathOriginDestinationChevron() {
      return `${this.selectedContextFlight.origin.city} > ${this.selectedContextFlight.destination.city}`;
    },
    flightAirportCodesChevron() {
      return `${this.originFlightCode} > ${this.destinationFlightCode}`;
    },
    departureTime() {
      if (
        this.selectedContextFlight &&
        this.selectedContextFlight.departureTime
      ) {
        return this.selectedContextFlight.departureTime;
      }
      return null;
    },
    arrivalTime() {
      if (
        this.selectedContextFlight &&
        this.selectedContextFlight.arrivalTime
      ) {
        return this.selectedContextFlight.arrivalTime;
      }
      return null;
    },
    hasArrived() {
      return this.timeLeftToLand && this.timeLeftToLand.minutes < 0;
    },
    hasDeparted() {
      return this.timeLeftToFlight && this.timeLeftToFlight.minutes < 0;
    },
    timeLeftToLand() {
      if (this.arrivalTime) {
        const arrivalTimeMoment = moment(this.arrivalTime);
        return this.timeDiff(moment(), arrivalTimeMoment);
      } else return null;
    },
    timeLeftToFlight() {
      if (!!this.departureTime) {
        const departureTimeMoment = moment(this.departureTime);
        return this.timeDiff(moment(), departureTimeMoment);
      } else return null;
    },
    timeForFlight() {
      return this.hasDeparted ? this.timeLeftToLand : this.timeLeftToFlight;
    },
    timeForFlightLocale() {
      if (this.hasSelectedContextFlight) {
        return this.hasDeparted
          ? moment(this.arrivalTime)
          : moment(this.departureTime);
      } else {
        return null;
      }
    },
    humanizedTimeForFlight() {
      const times = [];
      const pluralizeText = (number, concatString) => {
        let string = `${number} ${concatString}`;
        if (number > 1) string = `${string}s`;
        return string;
      };

      if (this.timeForFlight && this.timeForFlight.months > 0) {
        const string = pluralizeText(this.timeForFlight.months, 'month');
        times.push(string);
      }
      if (this.timeForFlight && this.timeForFlight.days > 0) {
        const string = pluralizeText(this.timeForFlight.days, 'day');
        times.push(string);
      }
      if (
        (times.length < 2 || this.$vuetify.breakpoint.lgAndUp) &&
        this.timeForFlight &&
        this.timeForFlight.hours > 0
      ) {
        const string = pluralizeText(this.timeForFlight.hours, 'hour');
        times.push(string);
      }
      if (
        ((times.length < 3 && this.$vuetify.breakpoint.lgAndUp) ||
          (times.length < 2 && !this.$vuetify.breakpoint.lgAndUp)) &&
        this.timeForFlight &&
        this.timeForFlight.minutes > 0
      ) {
        const string = pluralizeText(this.timeForFlight.minutes, 'minute');
        times.push(string);
      }
      return times.join(', ').replace(/, ([^,]*)$/, ', $1');
    },
    abbreviatedTimeForFlight() {
      const times = [];

      if (this.timeForFlight && this.timeForFlight.months > 0) {
        const string = `${this.timeForFlight.months}mo`;
        times.push(string);
      }
      if (this.timeForFlight && this.timeForFlight.days > 0) {
        const string = `${this.timeForFlight.days}d`;
        times.push(string);
      }
      if (
        this.timeForFlight &&
        this.timeForFlight.hours > 0 &&
        this.timeForFlight.days < 2 &&
        this.timeForFlight.months === 0
      ) {
        const string = `${this.timeForFlight.hours}h`;
        times.push(string);
      }
      if (
        this.timeForFlight &&
        this.timeForFlight.minutes > 0 &&
        this.timeForFlight.hours < 24 &&
        this.timeForFlight.days === 0 &&
        this.timeForFlight.months === 0
      ) {
        const string = `${this.timeForFlight.minutes}m`;
        times.push(string);
      }
      return times.join(', ').replace(/, ([^,]*)$/, ', $1');
    },
    selectedFlightDestination() {
      return this.selectedContextFlight
        ? this.selectedContextFlight.destination
        : null;
    },
    selectedFlightDeparture() {
      return this.selectedContextFlight
        ? this.selectedContextFlight.origin
        : null;
    },
    selectedFlightNumber() {
      return this.selectedContextFlight?.flightNumber;
    },
  },
  methods: {
    timeDiff(startTime, endTime) {
      const now = startTime;
      const departureDate = moment(endTime);
      const difference = departureDate.diff(now);
      const differenceDuration = moment.duration(difference);
      return {
        months: differenceDuration.months(),
        days: differenceDuration.days(),
        hours: differenceDuration.hours(),
        minutes: differenceDuration.minutes(),
      };
    },
    async matchesContextCityAndSearchCity(oldCity) {
      if (oldCity) {
        let res = await mapboxService.getCities(oldCity.destination.city);
        if (res.features && res.features.length > 0) {
          this.isCityMatches =
            this.$route.query.city === res.features[0].text_en;
        }
      }
    },
    async convertTimeToLocale(locale) {
      if (locale === 'en') {
        this.localeTime = await this.humanizedTime();
        return Promise.resolve();
      }
      return new Promise((resolve, reject) => {
        import(`moment/locale/${locale}`)
          .then(() => this.humanizedTime(locale))
          .then((localeTime) => {
            this.localeTime = localeTime;
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    async humanizedTime(lang = 'en') {
      let locale;
      switch (lang) {
        case 'en':
          locale = await import('date-fns/locale/en-CA').then((l) => l.default);
          break;
        case 'es':
          locale = await import('date-fns/locale/es').then((l) => l.default);
          break;
        case 'fr':
          locale = await import('date-fns/locale/fr').then((l) => l.default);
          break;
        case 'pt':
          locale = await import('date-fns/locale/pt').then((l) => l.default);
          break;
      }

      return this.timeForFlightLocale
        ? formatDistance(
            new Date(this.timeForFlightLocale.toISOString()),
            new Date(),
            { addSuffix: true, locale }
          )
        : null;
    },
  },
};
</script>

<style scoped></style>
