<template>
  <v-container class="py-15">
    <v-row justify="center" align="center">
      <v-card @click.native="onClickTrigger" class="trigger">
        <v-container class="py-0">
          <v-row no-gutters>
            <v-col cols="3">
              <v-img
                :alt="flightTriggerText.title"
                contain
                height="100%"
                width="100%"
                :src="flightTriggerImage"
              />
            </v-col>
            <v-col>
              <v-row
                class="full-height"
                no-gutters
                justify="space-between"
                align="center"
              >
                <v-col cols="auto" sm="8" class="pt-2 pb-3 full-height">
                  <p class="truncate title mb-0 text-no-wrap">
                    {{ flightTriggerText.title }}
                  </p>
                  <p class="truncate description mb-0 text-no-wrap">
                    {{ flightTriggerText.description }}
                  </p>
                </v-col>
                <v-col cols="auto" sm="4" class="px-3 pr-md-6 pl-md-0">
                  <v-btn
                    small
                    :text="$vuetify.breakpoint.smAndDown"
                    :icon="$vuetify.breakpoint.smAndDown"
                    :outlined="$vuetify.breakpoint.mdAndUp"
                    :color="$vuetify.breakpoint.mdAndUp ? 'primary' : ''"
                  >
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                      {{
                        hasFlights
                          ? $t('home.see_offers')
                          : $t('home.add_flight')
                      }}
                    </template>

                    <template v-else>
                      <v-icon>mdi-chevron-right</v-icon>
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: 'FlightTriggerComponent',

  props: {
    hasFlights: {
      type: Boolean,
      default: false,
    },

    triggerInfo: {
      type: Object,
      default: () => {},
    },

    triggerImage: {
      type: String,
      default: '',
    },
  },

  computed: {
    flightTriggerText() {
      if (this.hasFlights) {
        return {
          title: !!this.triggerInfo
            ? this.triggerInfo.view_title
            : this.$t('home.flights_trigger_default_title'),
          description: !!this.triggerInfo
            ? this.triggerInfo.view_description
            : this.$t('home.flights_trigger_default_description'),
        };
      } else {
        return {
          title: !!this.triggerInfo
            ? this.triggerInfo.add_title
            : this.$t('home.no_flight_trigger_title'),
          description: !!this.triggerInfo
            ? this.triggerInfo.add_description
            : this.$t('home.no_flight_trigger_description'),
        };
      }
    },

    flightTriggerImage() {
      return !!this.triggerImage
        ? this.triggerImage
        : require('@/assets/flightcta.png');
    },
  },

  methods: {
    onClickTrigger() {
      if (!this.hasFlights) {
        this.$emit('show');
      } else {
        this.$router.push({ name: 'flight-offering' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height {
  height: 100%;
}
.title {
  @include font-size(20, 160, 600);

  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include font-size(24, 130, 600);
  }
}

.description {
  @include font-size(14, 142.857, 400);

  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include font-size(14, 120);
  }
}

.trigger {
  border: 1px solid #e4e8ef;
  box-shadow: 0px 3px 8px rgba(88, 98, 113, 0.15);
  background: #fff;
  cursor: pointer;
}

.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
