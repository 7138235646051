export const REDIRECT_PATH_FROM_CONFIRMATION_SET =
  'REDIRECT_PATH_FROM_CONFIRMATION_SET';
export const ORDER_PREPARE = 'ORDER_PREPARE';
export const ORDER_CREATING = 'ORDER_CREATING';
export const ORDER_CREATION_ERROR = 'ORDER_CREATION_ERROR';
export const ORDER_CREATED = 'ORDER_CREATED';
export const ORDER_CREATION_CLEAR = 'ORDER_CREATION_CLEAR';
export const ORDER_CHECK_OUT_CLEAR = 'ORDER_CHECK_OUT_CLEAR';
export const ORDER_CHECK_OUT_REQUIRES_CONFIRMATION =
  'ORDER_CHECK_OUT_REQUIRES_CONFIRMATION';
export const ORDER_CHECK_OUT_ERROR = 'ORDER_CHECK_OUT_ERROR';
export const ORDER_CHECKED_OUT = 'ORDER_CHECKED_OUT';
export const ORDER_CHECKING_OUT = 'ORDER_CHECKING_OUT';
export const ORDER_CHECKED_OUT_ASYNC = 'ORDER_CHECKED_OUT_ASYNC';
export const ORDER_CHECK_OUT_TIMEOUT_ID = 'ORDER_CHECK_OUT_TIMEOUT_ID';
export const ORDER_SET_ORDER_REQUEST_OBJ = 'ORDER_SET_ORDER_REQUEST_OBJ';
export const ORDER_SET_PRODCT_ID = 'ORDER_SET_PRODCT_ID';
export const ORDER_UPDATE_DISCOUNT = 'ORDER_UPDATE_DISCOUNT';
export const ORDER_REMOVE_DISCOUNT = 'ORDER_REMOVE_DISCOUNT';
