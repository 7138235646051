<template>
  <v-menu
    v-model="menu"
    :disabled="disabled"
    :close-on-content-click="false"
    :nudge-bottom="10"
    offset-y
    max-width="400"
    tabindex="0"
    role="button"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="flight-list-menu-button text-left align-center px-1 btn-hover"
        data-cy="manage-flights-flight-list-menu-button"
        v-bind="attrs"
        v-on="on"
        text
        height="55"
      >
        <v-row no-gutters>
          <v-col class="pr-0 pr-lg-3" data-cy="flight-list-menu-flight-name">
            {{ flightPathOriginDestination }} •
            {{ selectedFlightNumber }}

            <template v-if="localeTime">
              <div
                class="size12-weight400 text-left mt-1"
                data-cy="flight-list-menu-flight-time"
              >
                {{ headerFlightTitle + '  ' + localeTime }}
              </div>
            </template>
          </v-col>
          <v-col class="px-0">
            <v-icon
              small
              class="justify-end fill-height d-inline-block flight-chevron"
            >
              <template v-if="menu"> mdi-chevron-up </template>
              <template v-else> mdi-chevron-down </template>
            </v-icon>
          </v-col>
        </v-row>
      </v-btn>
    </template>

    <template v-if="!displayRemoveFlights">
      <FlightsSelectionCard
        :show="menu"
        @onEditClick="showRemoveFlightCard"
        @addFlight:change="isAddFlightShowing = $event"
        :isAddFlightShowing="isAddFlightShowing"
        scrollable
      />
    </template>

    <template v-else>
      <RemoveFlightCard
        @click="hideRemoveFlightCard"
        @close="onRemoveCardClose"
        @return="displayRemoveFlights = false"
      />
    </template>
  </v-menu>
</template>

<script>
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import RemoveFlightCard from '@/modules/flights/features/FlightSelection/RemoveFlightCard';
import FlightsSelectionCard from '@/modules/flights/features/FlightSelection/FlightsSelectionCard';
import LanguageMixins from '@/core/mixins/LanguageMixins';

export default {
  name: 'FlightsSelectionMenuV2',
  components: { FlightsSelectionCard, RemoveFlightCard },
  mixins: [
    SelectedContextFlightMixins,
    FlightsMixins,
    LanguageMixins,
    GoogleTagManagerMixins,
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
      isAddFlightShowing: false,
      displayRemoveFlights: false,
    };
  },

  mounted() {
    this.convertTimeToLocale(this.momentLocale);
  },

  computed: {
    momentLocale() {
      return this.userPreferredLanguage
        ? this.userPreferredLanguage?.split('-')[0]
        : 'en';
    },
  },

  methods: {
    showRemoveFlightCard() {
      this.displayRemoveFlights = true;
    },

    hideRemoveFlightCard() {
      this.displayRemoveFlights = false;
      this.pushRemoveFlight();
      if (!!this.nextValidFlight) {
        this.setSelectedFlight(this.nextValidFlight);
      } else {
        this.clearSelectedFlight();
      }
    },

    onRemoveCardClose() {
      this.displayRemoveFlights = false;
      this.dialog = false;
    },
  },

  watch: {
    menu(newVal, oldVal) {
      this.$store.dispatch('setFlightSelectorStatus', newVal);

      if (!newVal) {
        this.displayRemoveFlights = false;
        this.isAddFlightShowing = false;
      }
    },

    selectedContextFlight(val) {
      if (!!val) {
        this.menu = false;
        this.convertTimeToLocale(this.momentLocale);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-hover:hover {
  background: #f6f6f6;
}

.flight-list-menu-button {
  @include font-size(14, 150, 500);
  border: 1px solid var(--v-grey1-darken1);
}

.flight-chevron {
  color: var(--v-grey1-darken4) !important;
}
</style>
