import { render, staticRenderFns } from "./FlightListItem.vue?vue&type=template&id=860b7234&scoped=true&"
import script from "./FlightListItem.vue?vue&type=script&lang=js&"
export * from "./FlightListItem.vue?vue&type=script&lang=js&"
import style0 from "./FlightListItem.vue?vue&type=style&index=0&id=860b7234&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "860b7234",
  null
  
)

export default component.exports