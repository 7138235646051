export const state = () => ({
  citySearch: {
    result: null,
    error: null,
    isLoading: false,
  },
  reverseGeocode: {
    result: null,
    error: null,
    isLoading: false,
  },
});
