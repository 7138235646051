<template>
  <div
    id="shell-top"
    @scroll="onScroll"
    class="app-body"
    :class="{
      'margin-top': !isWelcomePage && !hideAppBar && !isTouchDeviceDetected,
    }"
    :style="{ backgroundImage: `url(${heroBackgroundImage})` }"
  >
    <vue-headful :title="pageTitle" />
    <template v-if="!hideAppBar">
      <template v-if="!isTouchDeviceDetected">
        <NavigationBar
          :hide-menu="$route.name === 'welcome' && !hasSelectedContextFlight"
          @openNavDrawer="openNavDrawer"
          :class="{ 'is-stuck': isStuck }"
        />
        <NavigationDrawer ref="navDrawer" />
      </template>

      <template
        v-if="
          !isConciergeV3 && isConciergeRoute && $vuetify.breakpoint.mdAndDown
        "
      >
        <ConciergeHeaderTitles />
      </template>
    </template>

    <template v-if="invalidFlightAlert">
      <ToastComponent @toastClosed="clearInvalidFlightAlert" />
    </template>
    <CodeShareComponent />
    <v-main
      class="app-content"
      :class="{
        'footer-space': !showStagesFooter,
        'margin-top':
          $route.name === 'welcome' && hasSelectedContextFlight && !hideAppBar,
        'pb-10': needsPadding && $vuetify.breakpoint.smAndDown,
        'is-product-page': isProductPage,
        overlay: isOpenFlightSelector,
      }"
    >
      <router-view></router-view>
    </v-main>
    <template
      v-if="!isConciergeV3 && showStagesFooter && !!hasSelectedContextFlight"
    >
      <FooterConciergeStages :class="{ overlay: isOpenFlightSelector }" />
    </template>

    <template v-if="showFadedFooter">
      <ConciergeFooter :class="{ overlay: isOpenFlightSelector }" />
    </template>

    <template v-else>
      <AppFooter :class="{ overlay: isOpenFlightSelector }" />
    </template>
  </div>
</template>

<script>
import NavigationBar from '@/modules/shell/features/NavigationBar';
import ConciergeHeaderTitles from '@/modules/concierge/features/ConciergeHeaderTitles';
import AppFooter from '@/modules/shell/features/AppFooter';
import FooterConciergeStages from '@/modules/shell/features/FooterConciergeStages';
import ConciergeFooter from '@/modules/shell/features/ConciergeFooter';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import vueHeadful from 'vue-headful';
import NavigationDrawer from '@/modules/shell/features/NavigationDrawer';
import * as Sentry from '@sentry/vue';
import ToastComponent from '@/core/components/ToastComponent.vue';
import CodeShareComponent from '@/core/components/CodeShareComponent.vue';

export default {
  name: 'Shell',
  components: {
    NavigationDrawer,
    FooterConciergeStages,
    AppFooter,
    ConciergeHeaderTitles,
    NavigationBar,
    ConciergeFooter,
    vueHeadful,
    ToastComponent,
    CodeShareComponent,
  },
  mixins: [ConfigsMixins, FlightsMixins],

  props: {
    isTouchDeviceDetected: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hideHeaderEmbeddedFF: false,
      hideStagesSelectorFF: false,
      isStuck: false,
      showFlightLandingFF: false,
      needsPadding: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.$store.dispatch('setFlightSelectorStatus', false);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },

  created() {
    this.checkHeaderEmbeddedFF();
    this.checkHideStagesSelectorFF();
  },

  computed: {
    isProductPage() {
      return this.$route?.name === 'shop-product-details';
    },

    isConciergeV3() {
      return this.$store.getters['checkConciergeV3'];
    },

    isWelcomePage() {
      return this.$route?.name === 'welcome';
    },

    showStagesFooter() {
      return (
        Boolean(this.$route.meta?.showStagesFooter) &&
        !this.hideStagesSelectorFF
      );
    },

    isEmbedded() {
      return this.$store.getters['isEmbedded'];
    },

    shouldHideHeader() {
      return this.isEmbedded && this.hideHeaderEmbeddedFF;
    },

    showBackgroundHeroImage() {
      return this.$route.meta.showBackgroundHeroImage;
    },

    showFadedFooter() {
      return this.$route.meta.showFadedFooter;
    },

    isConciergeRoute() {
      return this.$route.name?.includes('concierge');
    },

    hideAppBar() {
      return this.$route.meta.hideAppBar;
    },

    selectedContextFlight() {
      return this.$store.state['flights'].selectedContextFlight;
    },

    pageTitle() {
      return this.$store.state.configs && this.$store.state.configs.title
        ? this.$store.state.configs.title
        : 'Guestlogix';
    },
  },

  watch: {
    selectedContextFlight: {
      immediate: true,
      handler(val, oldVal) {
        Sentry.setContext('flights', {
          contextFlights: this.contextFlights,
          selectedContextFlight: val,
        });

        this.checkFlightLandingFF();
        if (!val && !!oldVal && !!this.showFlightLandingFF) {
          if (!this.hasContextFlights) {
            this.$store.dispatch('setFlightSelectorStatus', false);
          }
          this.$router.push({ name: 'flights' });
        }
      },
    },

    $route: {
      immediate: true,
      handler(val) {
        if (val.name === 'transportation-details') {
          this.needsPadding = true;
        }
      },
    },

    invalidFlights: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== undefined && !!val && val.length > 0) {
          this.setInvalidFlightAlert();
        }
      },
    },
  },

  methods: {
    openNavDrawer() {
      this.$refs.navDrawer.open();
    },

    async checkFlightLandingFF() {
      this.showFlightLandingFF = await this.$ldclient.variation(
        'flights-landing-page',
        false,
        true
      );
      this.$ldclient.$on('change:flights-landing-page', (value) => {
        this.showFlightLandingFF = value;
      });
    },

    redirect(newRoute) {
      this.$router.push(newRoute).catch((e) => {}); //Just to catch the duplicate navigation error so chrome console doesn't complain
    },

    onScroll(e) {
      const el = document.querySelector('.global-toolbar');
      if (
        this.$vuetify.breakpoint.mdAndDown &&
        window.top.scrollY >= (this.$vuetify.breakpoint.smAndDown ? 290 : 360)
      ) {
        this.isStuck = true;
      } else {
        this.isStuck = false;
      }
    },

    goToConcierge() {
      if (this.$route.name !== 'concierge' && !this.isWelcomePage) {
        this.$router.push({
          name: 'concierge',
        });
      }
    },

    async checkHeaderEmbeddedFF() {
      this.hideHeaderEmbeddedFF = await this.$ldclient.variation(
        'hide-header-embedded',
        false,
        true
      );
      this.$ldclient.$on('change:hide-header-embedded', (value) => {
        this.hideHeaderEmbeddedFF = value;
      });
    },

    async checkHideStagesSelectorFF() {
      this.hideStagesSelectorFF = await this.$ldclient.variation(
        'hide-stages-selector',
        false,
        true
      );
      this.$ldclient.$on('change:hide-stages-selector', (value) => {
        this.hideStagesSelectorFF = value;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.app-body {
  min-height: 100vh;
  overflow-x: hidden;
  background-size: cover;
  background-position: center;

  &.margin-top {
    margin-top: 80px;
  }
}

.app-content {
  background: transparent;
  @media (min-width: map-get($grid-breakpoints, md)) {
    padding-bottom: 125px !important;
  }

  &.margin-top {
    margin-top: 130px;
    @media (max-width: map-get($grid-breakpoints, md)) {
      margin-top: 100px;
    }
  }
}

a.link {
  text-decoration: none;
}

.footer-space {
  margin-bottom: 100px;

  &.is-product-page {
    margin-bottom: 210px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-bottom: 100px;
    }
  }
}

::v-deep {
  .v-snack--absolute {
    z-index: 20;
  }
}

.overlay:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba($color: #000, $alpha: 0.5);
  backdrop-filter: blur(0.5px);
  z-index: 2;
}
</style>
