import { render, staticRenderFns } from "./CategoryListComponent.vue?vue&type=template&id=f8b6101c&scoped=true&"
import script from "./CategoryListComponent.vue?vue&type=script&lang=js&"
export * from "./CategoryListComponent.vue?vue&type=script&lang=js&"
import style0 from "./CategoryListComponent.vue?vue&type=style&index=0&id=f8b6101c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8b6101c",
  null
  
)

export default component.exports