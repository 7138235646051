export default {
  airlines: [
    {
      name: 'Blue Air',
      code: '0B',
    },
    {
      name: 'Ak Bars Aero',
      code: '2B',
    },
    {
      name: 'SNCF',
      code: '2C',
    },
    {
      name: 'Star Peru',
      code: '2I',
    },
    {
      name: 'Air Burkina',
      code: '2J',
    },
    {
      name: 'Avianca Ecuador',
      code: '2K',
    },
    {
      name: 'Helvetic Airways',
      code: '2L',
    },
    {
      name: 'PAL Express',
      code: '2P',
    },
    {
      name: 'Air Inuit',
      code: '3H',
    },
    {
      name: 'Air Arabia Maroc',
      code: '3O',
    },
    {
      name: 'Air Antilles Express',
      code: '3S',
    },
    {
      name: 'Caribbean Air Export Import Inc.',
      code: 'CB',
    },
    {
      name: 'Moldavian Airlines',
      code: '2M',
    },
    {
      name: 'Welcome Air',
      code: '2W',
    },
    {
      name: 'Jetstar Asia',
      code: '3K',
    },
    {
      name: 'Tiara Air Aruba',
      code: '3P',
    },
    {
      name: 'Sichuan Airlines',
      code: '3U',
    },
    {
      name: "Warbelow's Air Ventures",
      code: '4W',
    },
    {
      name: 'Nextjet',
      code: '2N',
    },
    {
      name: 'Air Choice One',
      code: '3E',
    },
    {
      name: 'Intersky Luftfahrt',
      code: '3L',
    },
    {
      name: 'Avior Airlines',
      code: '3R',
    },
    {
      name: 'Air Sinai',
      code: '4D',
    },
    {
      name: 'LATAM Chile',
      code: 'LA',
    },
    {
      name: 'Gazpromavia',
      code: '4G',
    },
    {
      name: 'HOP!',
      code: 'A5',
    },
    {
      name: 'Okay Airways',
      code: 'BK',
    },
    {
      name: 'Air Botswana',
      code: 'BP',
    },
    {
      name: 'Cyprus Airways',
      code: 'CY',
    },
    {
      name: 'Etihad Airways',
      code: 'EY',
    },
    {
      name: 'FlyDamas',
      code: '4J',
    },
    {
      name: 'LATAM Argentina',
      code: '4M',
    },
    {
      name: 'Interjet',
      code: '4O',
    },
    {
      name: 'Safi Airways',
      code: '4Q',
    },
    {
      name: 'Belair Airlines',
      code: '4T',
    },
    {
      name: 'Germanwings',
      code: '4U',
    },
    {
      name: 'Nature Air',
      code: '5C',
    },
    {
      name: 'Air Algerie',
      code: 'AH',
    },
    {
      name: 'British Airways',
      code: 'BA',
    },
    {
      name: 'airBaltic',
      code: 'BT',
    },
    {
      name: 'Air Jet',
      code: '7A',
    },
    {
      name: 'Fly540',
      code: '5H',
    },
    {
      name: 'ASL Airlines France',
      code: '5O',
    },
    {
      name: 'Israir Airlines',
      code: '6H',
    },
    {
      name: 'SmartLynx Airlines',
      code: '6Y',
    },
    {
      name: 'First Air',
      code: '7F',
    },
    {
      name: 'Nouvelair',
      code: 'BJ',
    },
    {
      name: 'Cebu Pacific',
      code: '5J',
    },
    {
      name: 'Tigerair Australia',
      code: 'TT',
    },
    {
      name: 'Canadian North',
      code: '5T',
    },
    {
      name: 'IndiGo',
      code: '6E',
    },
    {
      name: 'Alrosa',
      code: '6R',
    },
    {
      name: 'Tajik Air',
      code: '7J',
    },
    {
      name: 'Maluti Sky',
      code: '7D',
    },
    {
      name: 'Insel Air',
      code: '7I',
    },
    {
      name: 'Aerocaribbean',
      code: '7L',
    },
    {
      name: 'Windrose Airlines',
      code: '7W',
    },
    {
      name: 'Wright Air Service',
      code: '8V',
    },
    {
      name: 'Jeju Air',
      code: '7C',
    },
    {
      name: 'Ravn Alaska',
      code: '7H',
    },
    {
      name: 'MetroJet',
      code: '7K',
    },
    {
      name: 'Federal Air',
      code: '7V',
    },
    {
      name: 'Lucky Air',
      code: '8L',
    },
    {
      name: 'Onur Air',
      code: '8Q',
    },
    {
      name: 'RusLine',
      code: '7R',
    },
    {
      name: 'Bering Air',
      code: '8E',
    },
    {
      name: 'Pacific Coastal Airlines',
      code: '8P',
    },
    {
      name: 'Afriqiyah Airways',
      code: '8U',
    },
    {
      name: 'Eurostar',
      code: '9F',
    },
    {
      name: 'Astra Airlines',
      code: 'A2',
    },
    {
      name: 'Myanmar Airways International',
      code: '8M',
    },
    {
      name: 'Sol Lineas Aereas',
      code: '8R',
    },
    {
      name: 'AccesRail',
      code: '9B',
    },
    {
      name: 'Cape Air',
      code: '9K',
    },
    {
      name: 'Air Moldova',
      code: '9U',
    },
    {
      name: 'Eastar Jet',
      code: 'ZE',
    },
    {
      name: 'Chang An Airlines',
      code: '9H',
    },
    {
      name: 'Southern Air',
      code: '9R',
    },
    {
      name: 'Jet Airways',
      code: '9W',
    },
    {
      name: 'Aeromexico',
      code: 'AM',
    },
    {
      name: 'Alitalia',
      code: 'AZ',
    },
    {
      name: 'Major Airline',
      code: 'XX',
    },
    {
      name: 'Central Mountain Air',
      code: '9M',
    },
    {
      name: 'Avior Airlines',
      code: '9V',
    },
    {
      name: 'Aegean Airlines',
      code: 'A3',
    },
    {
      name: 'Azimut',
      code: 'A4',
    },
    {
      name: 'Air Alps Aviation',
      code: 'A6',
    },
    {
      name: 'Air Berlin',
      code: 'AB',
    },
    {
      name: 'Georgian Airways',
      code: 'A9',
    },
    {
      name: 'American Airlines',
      code: 'AA',
    },
    {
      name: 'Air One',
      code: 'AP',
    },
    {
      name: 'Aerolineas Argentinas',
      code: 'AR',
    },
    {
      name: 'Royal Air Maroc',
      code: 'AT',
    },
    {
      name: 'Armenia Aircompany',
      code: 'RM',
    },
    {
      name: 'Level',
      code: 'VK',
    },
    {
      name: 'SkyUp Airlines',
      code: 'PQ',
    },
    {
      name: 'Linear Air',
      code: 'O2',
    },
    {
      name: 'Air Canada',
      code: 'AC',
    },
    {
      name: 'Belavia',
      code: 'B2',
    },
    {
      name: 'Uni Air',
      code: 'B7',
    },
    {
      name: 'TAAG Angola Airlines',
      code: 'DT',
    },
    {
      name: 'Norwegian Air Shuttle',
      code: 'DY',
    },
    {
      name: 'Thai Express Air Co. Ltd',
      code: 'EX',
    },
    {
      name: 'Azul Brazilian Airlines',
      code: 'AD',
    },
    {
      name: 'East African Safari Air',
      code: 'B5',
    },
    {
      name: 'Eritrean Airlines',
      code: 'B8',
    },
    {
      name: 'Trade Air',
      code: 'C3',
    },
    {
      name: 'Grand China Air',
      code: 'CN',
    },
    {
      name: 'Bringer Air Cargo',
      code: 'E6',
    },
    {
      name: 'Mandarin Airlines',
      code: 'AE',
    },
    {
      name: 'Air France',
      code: 'AF',
    },
    {
      name: 'Air India',
      code: 'AI',
    },
    {
      name: 'AirAsia',
      code: 'AK',
    },
    {
      name: 'Alaska Airlines',
      code: 'AS',
    },
    {
      name: 'Austral Lineas Aereas',
      code: 'AU',
    },
    {
      name: 'Avianca',
      code: 'AV',
    },
    {
      name: 'Finnair',
      code: 'AY',
    },
    {
      name: 'Flybe',
      code: 'BE',
    },
    {
      name: 'Royal Brunei',
      code: 'BI',
    },
    {
      name: 'Inter Air',
      code: 'D6',
    },
    {
      name: 'Caribbean Airlines',
      code: 'BW',
    },
    {
      name: 'Cobalt Air',
      code: 'CO',
    },
    {
      name: 'Aer Lingus',
      code: 'EI',
    },
    {
      name: 'Pegas Fly',
      code: 'EO',
    },
    {
      name: 'Airtran Airways',
      code: 'FL',
    },
    {
      name: 'Seaborne Airlines',
      code: 'BB',
    },
    {
      name: 'Hawkair',
      code: 'BH',
    },
    {
      name: 'Blue Panorama Airlines',
      code: 'BV',
    },
    {
      name: 'Airlines PNG',
      code: 'CG',
    },
    {
      name: 'China Southern Airlines',
      code: 'CZ',
    },
    {
      name: 'JSC Aircompany ATMA',
      code: 'IM',
    },
    {
      name: 'Jetstar Pacific Airlines',
      code: 'BL',
    },
    {
      name: 'Cubana de Aviación',
      code: 'CU',
    },
    {
      name: 'Danish Air Transport',
      code: 'DX',
    },
    {
      name: 'Hong Kong Airlines',
      code: 'HX',
    },
    {
      name: 'Qazaq Air',
      code: 'IQ',
    },
    {
      name: 'Bahrain Air',
      code: 'BN',
    },
    {
      name: 'China Airlines',
      code: 'CI',
    },
    {
      name: 'OpenSkies',
      code: 'EC',
    },
    {
      name: 'Eurowings',
      code: 'EW',
    },
    {
      name: 'Wizz Air',
      code: 'W9',
    },
    {
      name: 'Thai AirAsia',
      code: 'FD',
    },
    {
      name: 'Eva Air',
      code: 'BR',
    },
    {
      name: 'Air China',
      code: 'CA',
    },
    {
      name: 'Cathay Pacific',
      code: 'CX',
    },
    {
      name: 'Severstal Air',
      code: 'D2',
    },
    {
      name: 'Donavia',
      code: 'D9',
    },
    {
      name: 'Eurolot',
      code: 'K2',
    },
    {
      name: 'Air Busan',
      code: 'BX',
    },
    {
      name: 'Chalair Aviation',
      code: 'CE',
    },
    {
      name: 'Adria Airways',
      code: 'JP',
    },
    {
      name: 'Korean Air',
      code: 'KE',
    },
    {
      name: 'China United Airlines',
      code: 'KN',
    },
    {
      name: 'Helijet',
      code: 'JB',
    },
    {
      name: 'BA Cityflyer',
      code: 'CJ',
    },
    {
      name: 'Norwegian Air',
      code: 'DN',
    },
    {
      name: 'SalamAir',
      code: 'OV',
    },
    {
      name: 'Air Dolomiti',
      code: 'EN',
    },
    {
      name: 'Aurigny Air Services',
      code: 'GR',
    },
    {
      name: 'Sky Airline',
      code: 'H2',
    },
    {
      name: 'Lufthansa CityLine',
      code: 'CL',
    },
    {
      name: 'Transasia Airways',
      code: 'GE',
    },
    {
      name: 'Air Greenland',
      code: 'GL',
    },
    {
      name: 'Air Seychelles',
      code: 'HM',
    },
    {
      name: 'Héli Sécurité Hélicopter Airline',
      code: 'HS',
    },
    {
      name: 'Zapways, Inc.',
      code: 'R1',
    },
    {
      name: 'Copa Airlines',
      code: 'CM',
    },
    {
      name: 'Joy Air',
      code: 'JR',
    },
    {
      name: 'Bearskin Airlines',
      code: 'JV',
    },
    {
      name: 'Cambodia Angkor Air',
      code: 'K6',
    },
    {
      name: 'MyWay Airlines',
      code: 'MJ',
    },
    {
      name: 'Peach',
      code: 'MM',
    },
    {
      name: 'AirAsia X',
      code: 'D7',
    },
    {
      name: 'Braathens Regional',
      code: 'DC',
    },
    {
      name: 'AirAsia Japan',
      code: 'DJ',
    },
    {
      name: 'Ethiopian Airlines',
      code: 'ET',
    },
    {
      name: 'Globus Airlines',
      code: 'GH',
    },
    {
      name: 'Air Caledonie',
      code: 'TY',
    },
    {
      name: 'Brit Air',
      code: 'DB',
    },
    {
      name: 'Tigerair Philippines',
      code: 'DG',
    },
    {
      name: 'Emirates',
      code: 'EK',
    },
    {
      name: 'TAME Linea Aerea del Ecuador',
      code: 'EQ',
    },
    {
      name: 'Interstate Airlines',
      code: 'I4',
    },
    {
      name: 'Kyrgyz Air',
      code: 'KH',
    },
    {
      name: 'Condor',
      code: 'DE',
    },
    {
      name: 'Sun-Air of Scandinavia',
      code: 'EZ',
    },
    {
      name: 'Bulgaria Air',
      code: 'FB',
    },
    {
      name: 'Fiji Airways',
      code: 'FJ',
    },
    {
      name: 'Servicios Aéreos de los Andes',
      code: 'FP',
    },
    {
      name: 'Kenmore Air',
      code: 'M5',
    },
    {
      name: 'Delta Air Lines',
      code: 'DL',
    },
    {
      name: 'Uganda Airlines',
      code: 'UR',
    },
    {
      name: 'Chengdu Airlines',
      code: 'EU',
    },
    {
      name: 'Allegiant Air',
      code: 'G4',
    },
    {
      name: 'Juneyao Airlines',
      code: 'HO',
    },
    {
      name: 'NAYSA',
      code: 'ZN',
    },
    {
      name: 'Frontier Airlines',
      code: 'F9',
    },
    {
      name: 'Icelandair',
      code: 'FI',
    },
    {
      name: 'Shanghai Airlines',
      code: 'FM',
    },
    {
      name: 'Ryanair',
      code: 'FR',
    },
    {
      name: 'Firefly',
      code: 'FY',
    },
    {
      name: 'Cargolux',
      code: 'CV',
    },
    {
      name: 'Brindabella Airlines',
      code: 'FQ',
    },
    {
      name: 'I Fly',
      code: 'F7',
    },
    {
      name: 'Gulf Air',
      code: 'GF',
    },
    {
      name: 'Izhavia',
      code: 'I8',
    },
    {
      name: 'LATAM Brasil',
      code: 'JJ',
    },
    {
      name: 'Marsland Aviation',
      code: 'M7',
    },
    {
      name: 'Syphax Airlines',
      code: 'FS',
    },
    {
      name: 'Flydubai',
      code: 'FZ',
    },
    {
      name: 'Go!',
      code: 'G8',
    },
    {
      name: 'Transavia',
      code: 'HV',
    },
    {
      name: 'AirAsia India',
      code: 'I5',
    },
    {
      name: 'Joon',
      code: 'JN',
    },
    {
      name: 'Gol Transportes Aéreos',
      code: 'G3',
    },
    {
      name: 'Air Arabia',
      code: 'G9',
    },
    {
      name: 'SkyGreece Airlines',
      code: 'GW',
    },
    {
      name: 'Hawaiian Airlines',
      code: 'HA',
    },
    {
      name: 'Aurora',
      code: 'HZ',
    },
    {
      name: 'Tigerair Taiwan',
      code: 'IT',
    },
    {
      name: 'Garuda Indonesia',
      code: 'GA',
    },
    {
      name: 'Air Italy',
      code: 'IG',
    },
    {
      name: 'Cayman Airways',
      code: 'KX',
    },
    {
      name: 'Belle Air Europe',
      code: 'L9',
    },
    {
      name: 'CanJet',
      code: 'C6',
    },
    {
      name: 'Tianjin Airlines',
      code: 'GS',
    },
    {
      name: 'Himalaya Airlines',
      code: 'H9',
    },
    {
      name: 'Niki',
      code: 'HG',
    },
    {
      name: 'Hahn Air',
      code: 'HR',
    },
    {
      name: 'Iberia',
      code: 'IB',
    },
    {
      name: 'Comair',
      code: 'MN',
    },
    {
      name: "Air Côte d'Ivoire",
      code: 'HF',
    },
    {
      name: 'Iran Air',
      code: 'IR',
    },
    {
      name: 'Yemenia',
      code: 'IY',
    },
    {
      name: 'Azerbaijan Airlines',
      code: 'J2',
    },
    {
      name: 'Atlas Global Airlines',
      code: 'KK',
    },
    {
      name: 'Hainan Airlines',
      code: 'HU',
    },
    {
      name: 'Uzbekistan Airways',
      code: 'HY',
    },
    {
      name: 'Berjaya Air',
      code: 'J8',
    },
    {
      name: 'Beijing Capital Airlines',
      code: 'JD',
    },
    {
      name: 'Lion Air',
      code: 'JT',
    },
    {
      name: 'Iberia Express',
      code: 'I2',
    },
    {
      name: 'Solomon Airlines',
      code: 'IE',
    },
    {
      name: 'Jetstar',
      code: 'JQ',
    },
    {
      name: 'Air Serbia',
      code: 'JU',
    },
    {
      name: 'SeaPort Airlines',
      code: 'K5',
    },
    {
      name: 'Druk Air',
      code: 'KB',
    },
    {
      name: 'Air Italy',
      code: 'I9',
    },
    {
      name: 'Japan Airlines',
      code: 'JL',
    },
    {
      name: 'Cathay Dragon',
      code: 'KA',
    },
    {
      name: 'Kuwait Airways',
      code: 'KU',
    },
    {
      name: 'Mauritania Airlines International',
      code: 'L6',
    },
    {
      name: 'Air India Express',
      code: 'IX',
    },
    {
      name: 'Jetlink Express',
      code: 'J0',
    },
    {
      name: 'Afrijet Business Service',
      code: 'J7',
    },
    {
      name: 'B&H Airlines',
      code: 'JA',
    },
    {
      name: 'Air Astana',
      code: 'KC',
    },
    {
      name: 'Komiaviatrans',
      code: 'KO',
    },
    {
      name: 'Arkia',
      code: 'IZ',
    },
    {
      name: 'Northwestern Air',
      code: 'J3',
    },
    {
      name: 'Jazeera Airways',
      code: 'J9',
    },
    {
      name: 'Mango',
      code: 'JE',
    },
    {
      name: 'Belle Air',
      code: 'LZ',
    },
    {
      name: 'Motor Sich Airlines',
      code: 'M9',
    },
    {
      name: 'Blue1',
      code: 'KF',
    },
    {
      name: 'KLM',
      code: 'KL',
    },
    {
      name: 'Penair',
      code: 'KS',
    },
    {
      name: 'Kunming Airlines',
      code: 'KY',
    },
    {
      name: 'Azur Air Ukraine',
      code: 'QU',
    },
    {
      name: 'Liat',
      code: 'LI',
    },
    {
      name: 'Air Malta',
      code: 'KM',
    },
    {
      name: 'Kenya Airways',
      code: 'KQ',
    },
    {
      name: 'Luxair',
      code: 'LG',
    },
    {
      name: 'Avianca Costa Rica',
      code: 'LR',
    },
    {
      name: 'Maya Island Air',
      code: 'MY',
    },
    {
      name: 'Condor',
      code: 'DF',
    },
    {
      name: 'Air Bishkek',
      code: 'KR',
    },
    {
      name: 'Middle East Airlines',
      code: 'ME',
    },
    {
      name: 'Air Macau',
      code: 'NX',
    },
    {
      name: 'Transaero Airlines',
      code: 'UN',
    },
    {
      name: 'Lufthansa',
      code: 'LH',
    },
    {
      name: 'Libyan Airlines',
      code: 'LN',
    },
    {
      name: 'LATAM Paraguay',
      code: 'PZ',
    },
    {
      name: 'Tarom',
      code: 'RO',
    },
    {
      name: 'Santa Barbara Airlines',
      code: 'S3',
    },
    {
      name: 'Hex Air',
      code: 'UD',
    },
    {
      name: 'Jin Air',
      code: 'LJ',
    },
    {
      name: 'Xiamen Airlines',
      code: 'MF',
    },
    {
      name: 'Air Freight NZ',
      code: 'OG',
    },
    {
      name: 'MIAT Mongolian Airlines',
      code: 'OM',
    },
    {
      name: 'Air Kyrgyzstan',
      code: 'QH',
    },
    {
      name: 'TACA Airlines',
      code: 'TA',
    },
    {
      name: 'LOT Polish Airlines',
      code: 'LO',
    },
    {
      name: 'Pacific Wings',
      code: 'LW',
    },
    {
      name: 'Olympic Air',
      code: 'OA',
    },
    {
      name: 'Passaredo Linhas Aereas',
      code: 'P3',
    },
    {
      name: 'Pegasus Airlines',
      code: 'PC',
    },
    {
      name: 'Jet2.com',
      code: 'LS',
    },
    {
      name: 'Malaysia Airlines',
      code: 'MH',
    },
    {
      name: 'Air Mauritius',
      code: 'MK',
    },
    {
      name: 'Sky Wings Airlines',
      code: 'ND',
    },
    {
      name: 'Air New Zealand',
      code: 'NZ',
    },
    {
      name: 'Asian Air',
      code: 'DM',
    },
    {
      name: 'Air Madagascar',
      code: 'MD',
    },
    {
      name: 'Silkair',
      code: 'MI',
    },
    {
      name: 'Air Mediterranee',
      code: 'ML',
    },
    {
      name: 'EgyptAir',
      code: 'MS',
    },
    {
      name: 'Czech Airlines',
      code: 'OK',
    },
    {
      name: 'Calm Air International',
      code: 'MO',
    },
    {
      name: 'Portugália',
      code: 'NI',
    },
    {
      name: 'VIM Airlines',
      code: 'NN',
    },
    {
      name: 'Binter Canarias',
      code: 'NT',
    },
    {
      name: 'Precision Air',
      code: 'PW',
    },
    {
      name: 'Wideroe',
      code: 'WF',
    },
    {
      name: 'China Eastern Airlines',
      code: 'MU',
    },
    {
      name: 'Merpati Nusantara Airlines',
      code: 'MZ',
    },
    {
      name: 'Air Vanuatu',
      code: 'NF',
    },
    {
      name: 'Polynesian Airlines',
      code: 'OL',
    },
    {
      name: 'Austrian Airlines',
      code: 'OS',
    },
    {
      name: 'DHL Aero Expreso S.A.',
      code: 'D5',
    },
    {
      name: 'Mokulele Airlines',
      code: 'MW',
    },
    {
      name: 'Nordwind Airlines',
      code: 'N4',
    },
    {
      name: 'Aero Contractors',
      code: 'NG',
    },
    {
      name: 'Andes Lineas Aereas',
      code: 'OY',
    },
    {
      name: 'Rossiya Airlines',
      code: 'FV',
    },
    {
      name: 'LATAM Ecuador',
      code: 'XL',
    },
    {
      name: 'All Nippon Airways',
      code: 'NH',
    },
    {
      name: 'Manx2',
      code: 'NM',
    },
    {
      name: 'Hebei Airlines',
      code: 'NS',
    },
    {
      name: 'Croatia Airlines',
      code: 'OU',
    },
    {
      name: 'Asiana Airlines',
      code: 'OZ',
    },
    {
      name: 'Farelogix Qatar Airways',
      code: 'F1',
    },
    {
      name: 'Spirit Airlines',
      code: 'NK',
    },
    {
      name: 'Air Japan',
      code: 'NQ',
    },
    {
      name: 'Japan Transocean Air',
      code: 'NU',
    },
    {
      name: 'Shandong Airlines',
      code: 'SC',
    },
    {
      name: 'Sky Lease I, Inc.',
      code: 'GG',
    },
    {
      name: 'Northwest Airlines',
      code: 'NW',
    },
    {
      name: 'SkyWork Airlines',
      code: 'SX',
    },
    {
      name: 'TRIP Linhas Aéreas',
      code: 'T4',
    },
    {
      name: 'Airnorth',
      code: 'TL',
    },
    {
      name: 'Air Transat',
      code: 'TS',
    },
    {
      name: 'Tibet Airlines',
      code: 'TV',
    },
    {
      name: 'TUI Airlines Netherlands',
      code: 'OR',
    },
    {
      name: 'FlyBosnia',
      code: '6W',
    },
    {
      name: 'Indonesia AirAsia',
      code: 'QZ',
    },
    {
      name: 'Aserca Airlines',
      code: 'R7',
    },
    {
      name: 'Atlantic Airways',
      code: 'RC',
    },
    {
      name: 'Air Creebec',
      code: 'YN',
    },
    {
      name: 'Aeropelican Air Services',
      code: 'OT',
    },
    {
      name: 'Provincial Airlines',
      code: 'PB',
    },
    {
      name: "People's Viennaline",
      code: 'PE',
    },
    {
      name: 'Pakistan International Airlines',
      code: 'PK',
    },
    {
      name: 'UIA',
      code: 'PS',
    },
    {
      name: 'Proflight Zambia',
      code: 'P0',
    },
    {
      name: 'SmartWings',
      code: 'QS',
    },
    {
      name: 'Horizon Air',
      code: 'QX',
    },
    {
      name: 'Yakutia Airlines',
      code: 'R3',
    },
    {
      name: 'Royal Flight',
      code: 'RL',
    },
    {
      name: 'Jet Lite',
      code: 'S2',
    },
    {
      name: 'Aerorepublica',
      code: 'P5',
    },
    {
      name: 'Porter Airlines',
      code: 'PD',
    },
    {
      name: 'Air Saint Pierre',
      code: 'PJ',
    },
    {
      name: 'Philippine Airlines',
      code: 'PR',
    },
    {
      name: 'Air Niugini',
      code: 'PX',
    },
    {
      name: 'Stobart Air',
      code: 'RE',
    },
    {
      name: 'Bangkok Airways',
      code: 'PG',
    },
    {
      name: 'China West Air',
      code: 'PN',
    },
    {
      name: 'Qantas',
      code: 'QF',
    },
    {
      name: 'Monacair',
      code: 'QM',
    },
    {
      name: 'BRA',
      code: 'TF',
    },
    {
      name: 'Scoot',
      code: 'TR',
    },
    {
      name: 'Surinam Airways',
      code: 'PY',
    },
    {
      name: 'Camair-Co',
      code: 'QC',
    },
    {
      name: 'UVT Aero',
      code: 'RT',
    },
    {
      name: 'SATA',
      code: 'S4',
    },
    {
      name: 'Saudia',
      code: 'SV',
    },
    {
      name: 'Syrian Arab Airlines',
      code: 'RB',
    },
    {
      name: 'Rotana Jet',
      code: 'RG',
    },
    {
      name: 'South African Airways',
      code: 'SA',
    },
    {
      name: 'Sudan Airways',
      code: 'SD',
    },
    {
      name: 'Swiss International Air Lines',
      code: 'LX',
    },
    {
      name: 'Trans Air Congo',
      code: 'Q8',
    },
    {
      name: 'Scandinavian Airlines',
      code: 'SK',
    },
    {
      name: 'Thai Airways',
      code: 'TG',
    },
    {
      name: 'United Airlines',
      code: 'UA',
    },
    {
      name: 'Srilankan Airlines',
      code: 'UL',
    },
    {
      name: 'Citilink Airlines',
      code: 'QG',
    },
    {
      name: 'Qatar Airways',
      code: 'QR',
    },
    {
      name: 'Lao Airlines',
      code: 'QV',
    },
    {
      name: 'Orenair',
      code: 'R2',
    },
    {
      name: 'Royal Nepal Airlines',
      code: 'RA',
    },
    {
      name: 'Tigerair Mandala',
      code: 'RI',
    },
    {
      name: 'Aircalin',
      code: 'SB',
    },
    {
      name: 'XL Airways France',
      code: 'SE',
    },
    {
      name: 'Aeroflot',
      code: 'SU',
    },
    {
      name: 'Tunisair Express',
      code: 'UG',
    },
    {
      name: 'New England Airlines',
      code: 'EJ',
    },
    {
      name: 'Royal Jordanian',
      code: 'RJ',
    },
    {
      name: 'Tradewind Aviation',
      code: 'TJ',
    },
    {
      name: 'LAM Mozambique Airlines',
      code: 'TM',
    },
    {
      name: 'Alitalia CityLiner',
      code: 'CT',
    },
    {
      name: 'S7 Airlines',
      code: 'S7',
    },
    {
      name: 'Shuttle America',
      code: 'S5',
    },
    {
      name: 'SATA Air Acores',
      code: 'SP',
    },
    {
      name: 'Air Europa',
      code: 'UX',
    },
    {
      name: 'JetBlue Airways',
      code: 'B6',
    },
    {
      name: 'SpiceJet',
      code: 'SG',
    },
    {
      name: 'Conviasa',
      code: 'V0',
    },
    {
      name: 'Vieques Air Link',
      code: 'V4',
    },
    {
      name: 'Virgin Australia',
      code: 'VA',
    },
    {
      name: 'TACV Cabo Verde Airlines',
      code: 'VR',
    },
    {
      name: 'Viva Air',
      code: 'VV',
    },
    {
      name: 'Blue Islands',
      code: 'SI',
    },
    {
      name: 'EasyJet',
      code: 'U2',
    },
    {
      name: 'Tus Airways',
      code: 'U8',
    },
    {
      name: 'Carpatair',
      code: 'V3',
    },
    {
      name: 'Volotea',
      code: 'V7',
    },
    {
      name: 'Valuair',
      code: 'VF',
    },
    {
      name: 'Brussels Airlines',
      code: 'SN',
    },
    {
      name: 'Biman Bangladesh Airlines',
      code: 'BG',
    },
    {
      name: 'Corsair International',
      code: 'SS',
    },
    {
      name: 'Singapore Airlines',
      code: 'SQ',
    },
    {
      name: 'Tyrolean Airways',
      code: 'VO',
    },
    {
      name: 'Amaszonas',
      code: 'Z8',
    },
    {
      name: 'White Airways',
      code: 'WI',
    },
    {
      name: 'South African Express',
      code: 'XZ',
    },
    {
      name: 'Fortune',
      code: 'X1',
    },
    {
      name: 'Air Namibia',
      code: 'SW',
    },
    {
      name: 'Eastern Airways',
      code: 'T3',
    },
    {
      name: 'Twin Jet',
      code: 'T7',
    },
    {
      name: 'Turkish Airlines',
      code: 'TK',
    },
    {
      name: 'Air Tahiti Nui',
      code: 'TN',
    },
    {
      name: 'Iran Aseman Airlines',
      code: 'EP',
    },
    {
      name: 'Turkmenistan Airlines',
      code: 'T5',
    },
    {
      name: 'Atlantis European Airways',
      code: 'TD',
    },
    {
      name: 'Transavia',
      code: 'TO',
    },
    {
      name: 'Edelweiss Air',
      code: 'WK',
    },
    {
      name: 'Island Air',
      code: 'WP',
    },
    {
      name: 'Air East',
      code: 'ER',
    },
    {
      name: 'TAP Portugal',
      code: 'TP',
    },
    {
      name: 'Sun Country Airlines',
      code: 'SY',
    },
    {
      name: 'Air Caraibes',
      code: 'TX',
    },
    {
      name: 'Ural Airlines',
      code: 'U6',
    },
    {
      name: 'Tatarstan Airlines',
      code: 'U9',
    },
    {
      name: 'Tunisair',
      code: 'TU',
    },
    {
      name: 'Scoot',
      code: 'TZ',
    },
    {
      name: 'Air Uganda',
      code: 'U7',
    },
    {
      name: 'El Al Israel Airlines',
      code: 'LY',
    },
    {
      name: 'Air Austral',
      code: 'UU',
    },
    {
      name: 'VLM Airlines',
      code: 'VG',
    },
    {
      name: 'Air Zimbabwe',
      code: 'UM',
    },
    {
      name: 'Level',
      code: 'LV',
    },
    {
      name: 'Hong Kong Express Airways',
      code: 'UO',
    },
    {
      name: 'US Airways',
      code: 'US',
    },
    {
      name: 'Virgin Atlantic',
      code: 'VS',
    },
    {
      name: 'Aeromar',
      code: 'VW',
    },
    {
      name: 'FlexFlight',
      code: 'W2',
    },
    {
      name: 'Worldspan',
      code: '1P',
    },
    {
      name: 'Bahamasair',
      code: 'UP',
    },
    {
      name: 'Utair',
      code: 'UT',
    },
    {
      name: 'Air Tahiti',
      code: 'VT',
    },
    {
      name: 'Virgin America ',
      code: 'VX',
    },
    {
      name: 'Arik Air',
      code: 'W3',
    },
    {
      name: 'KLM Cityhopper',
      code: 'WA',
    },
    {
      name: 'Vision Airlines',
      code: 'V2',
    },
    {
      name: 'Aerovías DAP',
      code: 'V5',
    },
    {
      name: 'C.A.I. Second',
      code: 'VE',
    },
    {
      name: 'Vietnam Airlines',
      code: 'VN',
    },
    {
      name: 'Great Lakes Airlines',
      code: 'ZK',
    },
    {
      name: 'Avion Express',
      code: 'X9',
    },
    {
      name: 'Vueling',
      code: 'VY',
    },
    {
      name: 'Wizz Air',
      code: 'W6',
    },
    {
      name: 'Aigle Azur',
      code: 'ZI',
    },
    {
      name: 'RwandAir',
      code: 'WB',
    },
    {
      name: 'Sun Express',
      code: 'XQ',
    },
    {
      name: 'Nas Air',
      code: 'XY',
    },
    {
      name: 'Yamal Airlines',
      code: 'YC',
    },
    {
      name: 'Montenegro Airlines',
      code: 'YM',
    },
    {
      name: 'HOP!',
      code: 'YS',
    },
    {
      name: 'Webjet Linhas Aereas',
      code: 'WH',
    },
    {
      name: 'Southwest Airlines',
      code: 'WN',
    },
    {
      name: 'Wizz Air Ukraine',
      code: 'WU',
    },
    {
      name: 'TUI fly',
      code: 'X3',
    },
    {
      name: 'Vladivostok Air',
      code: 'XF',
    },
    {
      name: 'South Airlines',
      code: 'YG',
    },
    {
      name: 'Winair',
      code: 'WM',
    },
    {
      name: 'Westjet',
      code: 'WS',
    },
    {
      name: 'Virgin Australia',
      code: 'XR',
    },
    {
      name: 'Volaris',
      code: 'Y4',
    },
    {
      name: 'Dniproavia',
      code: 'Z6',
    },
    {
      name: 'Cityjet',
      code: 'WX',
    },
    {
      name: 'Air Corsica',
      code: 'XK',
    },
    {
      name: 'Shenzhen Airlines',
      code: 'ZH',
    },
    {
      name: 'Regional Express',
      code: 'ZL',
    },
    {
      name: 'Sky Airlines',
      code: 'ZY',
    },
    {
      name: 'Oman Air',
      code: 'WY',
    },
    {
      name: 'KD Air',
      code: 'XC',
    },
    {
      name: 'Air Nostrum',
      code: 'YW',
    },
    {
      name: 'Monarch Airlines',
      code: 'ZB',
    },
    {
      name: 'Equatorial Congo Airlines',
      code: 'LC',
    },
    {
      name: 'Thomson Airways',
      code: 'BY',
    },
    {
      name: 'C.A.I. First',
      code: 'XM',
    },
    {
      name: 'BVI Airways',
      code: 'XV',
    },
    {
      name: 'NordStar',
      code: 'Y7',
    },
    {
      name: 'Avia Traffic Company',
      code: 'YK',
    },
    {
      name: 'Heli Air Monaco',
      code: 'YO',
    },
    {
      name: 'Mesa Airlines',
      code: 'YV',
    },
    {
      name: 'Polet Airlines',
      code: 'YQ',
    },
    {
      name: 'SUN-AIR',
      code: 'ZU',
    },
    {
      name: 'IrAero',
      code: 'IO',
    },
    {
      name: 'Germania',
      code: 'ST',
    },
    {
      name: 'Gambia Bird Airlines',
      code: '3G',
    },
    {
      name: 'BMI Regional',
      code: 'BM',
    },
    {
      name: 'BQB Lineas Aereas',
      code: '5Q',
    },
    {
      name: 'Groznyy Avia',
      code: 'ZG',
    },
    {
      name: 'AirAsia Zest',
      code: 'Z2',
    },
    {
      name: 'Red Wings Airlines',
      code: 'WZ',
    },
    {
      name: 'Republic Airlines',
      code: 'YX',
    },
    {
      name: 'Skippers Aviation',
      code: 'HK',
    },
    {
      name: 'Hunnu Air',
      code: 'MR',
    },
    {
      name: 'ASKY Airlines',
      code: 'KP',
    },
    {
      name: 'Sunwing Airlines',
      code: 'WG',
    },
    {
      name: 'Silver Airways',
      code: '3M',
    },
    {
      name: 'Airlink',
      code: '4Z',
    },
    {
      name: 'Japan Air Commuter',
      code: 'JC',
    },
    {
      name: 'SunExpress Deutschland',
      code: 'XG',
    },
    {
      name: 'HOP!',
      code: 'AN',
    },
    {
      name: 'Jetstar Japan',
      code: 'GK',
    },
    {
      name: 'Wings Air',
      code: 'IW',
    },
    {
      name: 'Kan Air',
      code: 'K8',
    },
    {
      name: 'Norwegian Long Haul',
      code: 'DU',
    },
    {
      name: 'Thai AirAsia X',
      code: 'XJ',
    },
    {
      name: 'Blue Bird Airways',
      code: 'BZ',
    },
    {
      name: 'Batik Air',
      code: 'ID',
    },
    {
      name: 'Sky Express',
      code: 'GQ',
    },
    {
      name: 'Pobeda',
      code: 'DP',
    },
    {
      name: 'Air Leisure',
      code: 'AL',
    },
    {
      name: 'Africa World Airlines',
      code: 'AW',
    },
    {
      name: 'Aerolink Uganda Limited',
      code: 'A8',
    },
    {
      name: 'BB Airways',
      code: 'BO',
    },
    {
      name: 'Cimber',
      code: 'QA',
    },
    {
      name: 'Bek Air',
      code: 'Z9',
    },
    {
      name: 'Insel Air',
      code: '8I',
    },
    {
      name: 'Korongo Airlines',
      code: 'ZC',
    },
    {
      name: 'Orient Thai Airlines',
      code: 'OX',
    },
    {
      name: 'Sriwijaya Air',
      code: 'SJ',
    },
    {
      name: 'Angara Airlines',
      code: '2G',
    },
    {
      name: 'Nok Air',
      code: 'DD',
    },
    {
      name: 'Thai Lion Air',
      code: 'SL',
    },
    {
      name: 'Colorful Guizhou Airlines',
      code: 'GY',
    },
    {
      name: 'Dobrolet',
      code: 'QD',
    },
    {
      name: 'Air Armenia',
      code: 'QN',
    },
    {
      name: 'Air Kiribati Limited',
      code: 'IK',
    },
    {
      name: 'Somon Air',
      code: 'SZ',
    },
    {
      name: 'Aztec Airways',
      code: 'AJ',
    },
    {
      name: '9 Air',
      code: 'AQ',
    },
    {
      name: 'Air Niamey',
      code: 'A7',
    },
    {
      name: 'Aruba Airlines',
      code: 'AG',
    },
    {
      name: 'Australian Airlines',
      code: 'AO',
    },
    {
      name: 'Trans States Airlines',
      code: 'AX',
    },
    {
      name: 'Skymark Airlines',
      code: 'BC',
    },
    {
      name: "Compagnie Africaine d'Aviation",
      code: 'BU',
    },
    {
      name: 'Bhutan Airlines',
      code: 'B3',
    },
    {
      name: 'French Blue',
      code: 'BF',
    },
    {
      name: 'La Compagnie',
      code: 'B0',
    },
    {
      name: 'ZanAir',
      code: 'B4',
    },
    {
      name: 'CM Airlines',
      code: 'CC',
    },
    {
      name: 'Bemidji Airlines',
      code: 'CH',
    },
    {
      name: 'Czech Connect Airlines',
      code: 'CQ',
    },
    {
      name: 'TAB - Transportes Aéreos Bolivianos',
      code: 'B1',
    },
    {
      name: 'Iran Air Tours',
      code: 'B9',
    },
    {
      name: 'Corendon Dutch Airlines',
      code: 'CD',
    },
    {
      name: 'China Cargo Airlines',
      code: 'CK',
    },
    {
      name: 'OAG',
      code: 'CR',
    },
    {
      name: 'Tectimes Sudamerica',
      code: 'C1',
    },
    {
      name: 'China Postal Airlines Ltd.',
      code: 'CF',
    },
    {
      name: 'Compass Airlines',
      code: 'CP',
    },
    {
      name: 'Continental Micronesia, Inc.',
      code: 'CS',
    },
    {
      name: 'CEIBA Intercontinental',
      code: 'C2',
    },
    {
      name: 'Cinnamon Air',
      code: 'C7',
    },
    {
      name: 'Douniah Airlines',
      code: 'DH',
    },
    {
      name: 'CommutAir',
      code: 'C5',
    },
    {
      name: 'Cronos Airlines',
      code: 'C8',
    },
    {
      name: 'Norwegian Air',
      code: 'DI',
    },
    {
      name: 'Discovery Airways',
      code: 'DO',
    },
    {
      name: 'easyJet Switzerland',
      code: 'DS',
    },
    {
      name: 'DHL Air Limited',
      code: 'D0',
    },
    {
      name: 'Thomas Cook Airlines Scandinavia',
      code: 'DK',
    },
    {
      name: 'Delta Connection',
      code: 'DQ',
    },
    {
      name: 'Aero-Charter Ukraine',
      code: 'DW',
    },
    {
      name: 'Daallo Airlines',
      code: 'D3',
    },
    {
      name: 'Norwegian Air International',
      code: 'D8',
    },
    {
      name: 'AirExplore',
      code: 'ED',
    },
    {
      name: 'Ruili Airlines',
      code: 'DR',
    },
    {
      name: 'Donghai Airlines',
      code: 'DZ',
    },
    {
      name: 'Alidaunia',
      code: 'D4',
    },
    {
      name: 'East Horizon Airlines',
      code: 'EA',
    },
    {
      name: 'Fly CamInter',
      code: 'EF',
    },
    {
      name: 'Ellinair',
      code: 'EL',
    },
    {
      name: 'Wamos Air',
      code: 'EB',
    },
    {
      name: 'ANA Wings',
      code: 'EH',
    },
    {
      name: 'Empire Airlines',
      code: 'EM',
    },
    {
      name: 'DHL Aviation EEMEA B.S.C.(c)',
      code: 'ES',
    },
    {
      name: 'Eagle Atlantic Airlines',
      code: 'E2',
    },
    {
      name: 'Equaflight Service',
      code: 'E7',
    },
    {
      name: 'ExpressJet',
      code: 'EV',
    },
    {
      name: 'Air Arabia Egypt',
      code: 'E5',
    },
    {
      name: 'City Airways',
      code: 'E8',
    },
    {
      name: 'Far Eastern Air Transport',
      code: 'FE',
    },
    {
      name: 'Freebird Airlines',
      code: 'FH',
    },
    {
      name: 'Fuzhou Airlines',
      code: 'FU',
    },
    {
      name: 'Evelop Airlines',
      code: 'E9',
    },
    {
      name: 'Airshop B.V.',
      code: 'FF',
    },
    {
      name: 'fastjet',
      code: 'FN',
    },
    {
      name: 'Ibex Airlines',
      code: 'FW',
    },
    {
      name: 'Safarilink Aviation',
      code: 'F2',
    },
    {
      name: 'Flair Airlines Ltd.',
      code: 'F8',
    },
    {
      name: 'Safair',
      code: 'FA',
    },
    {
      name: 'Ariana Afghan Airlines',
      code: 'FG',
    },
    {
      name: 'Felix Airways',
      code: 'FO',
    },
    {
      name: 'FedEx',
      code: 'FX',
    },
    {
      name: 'Flyadeal',
      code: 'F3',
    },
    {
      name: 'ABX Air, Inc.',
      code: 'GB',
    },
    {
      name: 'Air Flamenco',
      code: 'F4',
    },
    {
      name: 'Gambia International Airlines',
      code: 'GC',
    },
    {
      name: 'Loong Air',
      code: 'GJ',
    },
    {
      name: 'Vista Georgia',
      code: 'GT',
    },
    {
      name: 'Air Rarotonga',
      code: 'GZ',
    },
    {
      name: 'Ghadames Air Transport',
      code: 'G6',
    },
    {
      name: 'Itek Air',
      code: 'GI',
    },
    {
      name: 'ULS Airlines Cargo',
      code: 'GO',
    },
    {
      name: 'Grant Aviation, Inc.',
      code: 'GV',
    },
    {
      name: 'China Express Airlines',
      code: 'G5',
    },
    {
      name: 'Asia Atlantic Airlines',
      code: 'HB',
    },
    {
      name: 'Taban Airlines',
      code: 'HH',
    },
    {
      name: 'Germania Flug',
      code: 'GM',
    },
    {
      name: 'Aviateca',
      code: 'GU',
    },
    {
      name: 'Jet Express',
      code: 'G2',
    },
    {
      name: 'GoJet Airlines',
      code: 'G7',
    },
    {
      name: 'LGW Luftfahrtgesellschaft Walter',
      code: 'HE',
    },
    {
      name: 'Hellas Jet',
      code: 'HJ',
    },
    {
      name: 'Air Do',
      code: 'HD',
    },
    {
      name: 'Papillon Airways',
      code: 'HI',
    },
    {
      name: 'Amapola Flyg AB',
      code: 'HP',
    },
    {
      name: 'Hahn Air',
      code: 'H1',
    },
    {
      name: 'Taron Avia LLC',
      code: 'H7',
    },
    {
      name: 'IBC Airways',
      code: 'II',
    },
    {
      name: 'Afghan Jet International',
      code: 'HN',
    },
    {
      name: 'North-Wright Airways',
      code: 'HW',
    },
    {
      name: 'Bulgarian Air Charter',
      code: 'H6',
    },
    {
      name: 'Irtysh Air',
      code: 'IH',
    },
    {
      name: 'PT.Trigana Air Service',
      code: 'IL',
    },
    {
      name: 'Apsara International Air',
      code: 'IP',
    },
    {
      name: 'Thomas Cook Airlines Belgium',
      code: 'HQ',
    },
    {
      name: 'Aero4M',
      code: 'H4',
    },
    {
      name: 'Hesa Airlines',
      code: 'H8',
    },
    {
      name: 'Spring Airlines Japan',
      code: 'IJ',
    },
    {
      name: 'NAM Air',
      code: 'IN',
    },
    {
      name: 'Air Indus',
      code: 'I6',
    },
    {
      name: 'AIS Airlines B.V.',
      code: 'IS',
    },
    {
      name: 'Jet Asia Airways',
      code: 'JF',
    },
    {
      name: 'Meraj Air',
      code: 'JI',
    },
    {
      name: 'Vanilla Air',
      code: 'JW',
    },
    {
      name: 'One Jet, Inc.',
      code: 'J1',
    },
    {
      name: 'Jet Ops',
      code: 'J6',
    },
    {
      name: 'JETGO Australia',
      code: 'JG',
    },
    {
      name: 'Jet Time',
      code: 'JO',
    },
    {
      name: 'InterCaribbean Airways',
      code: 'JY',
    },
    {
      name: 'BADR AIRLINES',
      code: 'J4',
    },
    {
      name: 'PT Kal Star Aviation',
      code: 'KD',
    },
    {
      name: 'Air Incheon',
      code: 'KJ',
    },
    {
      name: 'Fuji Dream Airlines',
      code: 'JH',
    },
    {
      name: 'Air Koryo',
      code: 'JS',
    },
    {
      name: 'Jatayu Airlines',
      code: 'JZ',
    },
    {
      name: 'Alaska Seaplane',
      code: 'J5',
    },
    {
      name: 'Aerogaviota',
      code: 'KG',
    },
    {
      name: 'SiAvia',
      code: 'KT',
    },
    {
      name: 'KrasAvia',
      code: 'KI',
    },
    {
      name: 'Asian Express Airline',
      code: 'KV',
    },
    {
      name: 'Topas Co. Ltd.',
      code: 'K1',
    },
    {
      name: 'Air KBZ',
      code: 'K7',
    },
    {
      name: 'Laparkan Airways, Inc.',
      code: 'LE',
    },
    {
      name: 'Loganair',
      code: 'LM',
    },
    {
      name: 'Kharkiv Airlines',
      code: 'KW',
    },
    {
      name: 'Taquan Air',
      code: 'K3',
    },
    {
      name: 'Bul Air',
      code: 'LB',
    },
    {
      name: 'Lao Skyway',
      code: 'LK',
    },
    {
      name: 'Air Lituanica',
      code: 'LT',
    },
    {
      name: 'E-Savtravel',
      code: 'L1',
    },
    {
      name: 'Nippon Cargo Airlines',
      code: 'KZ',
    },
    {
      name: 'ALS Ltd.',
      code: 'K4',
    },
    {
      name: 'AHK Air Hong Kong Limited',
      code: 'LD',
    },
    {
      name: 'Miami Air International',
      code: 'LL',
    },
    {
      name: 'LAN Express',
      code: 'LU',
    },
    {
      name: 'Lynden Air Cargo, LLC',
      code: 'L2',
    },
    {
      name: 'Lynx Aviation',
      code: 'L3',
    },
    {
      name: 'Afric Aviation',
      code: 'L8',
    },
    {
      name: 'Miami Air Lease, Inc.',
      code: 'MG',
    },
    {
      name: 'Thomas Cook Airlines',
      code: 'MT',
    },
    {
      name: 'North Flying',
      code: 'M3',
    },
    {
      name: 'Air Service Liege',
      code: 'L4',
    },
    {
      name: 'MNG Airlines',
      code: 'MB',
    },
    {
      name: 'Martinair',
      code: 'MP',
    },
    {
      name: 'Mexicana de Aviación',
      code: 'MX',
    },
    {
      name: 'Mistral Air',
      code: 'M4',
    },
    {
      name: 'Nesma Airlines',
      code: 'NA',
    },
    {
      name: 'Linea Aerea Carguera de Colombia S.A.',
      code: 'L7',
    },
    {
      name: 'Air Mobility Command',
      code: 'MC',
    },
    {
      name: 'Envoy Air',
      code: 'MQ',
    },
    {
      name: 'MHS Aviation',
      code: 'M2',
    },
    {
      name: 'Amerijet International Inc.',
      code: 'M6',
    },
    {
      name: 'Skypower Express Airways',
      code: 'NB',
    },
    {
      name: 'SkyJet',
      code: 'M8',
    },
    {
      name: 'Cobham Aviation Services Australia',
      code: 'NC',
    },
    {
      name: 'Shaheen Air',
      code: 'NL',
    },
    {
      name: 'Air Iceland',
      code: 'NY',
    },
    {
      name: 'Nolinor Aviation',
      code: 'N5',
    },
    {
      name: 'Nova Airways',
      code: 'N9',
    },
    {
      name: 'Nesma Airlines',
      code: 'NE',
    },
    {
      name: 'Neos',
      code: 'NO',
    },
    {
      name: 'Trans-Mediterranean Airways',
      code: 'N2',
    },
    {
      name: 'Nordic Regional Airline',
      code: 'N7',
    },
    {
      name: 'Boliviana de Aviación',
      code: 'OB',
    },
    {
      name: 'Laudamotion',
      code: 'OE',
    },
    {
      name: 'Aero VIP',
      code: 'NJ',
    },
    {
      name: 'Nile Air',
      code: 'NP',
    },
    {
      name: 'Aerolíneas Mas',
      code: 'N3',
    },
    {
      name: 'National Airlines',
      code: 'N8',
    },
    {
      name: 'Oriental Air Bridge',
      code: 'OC',
    },
    {
      name: 'Hinterland Aviation',
      code: 'OI',
    },
    {
      name: 'Malindo Air',
      code: 'OD',
    },
    {
      name: 'Fly Jamaica Airways',
      code: 'OJ',
    },
    {
      name: 'Chongqing Airlines',
      code: 'OQ',
    },
    {
      name: 'Avianca Brazil',
      code: 'O6',
    },
    {
      name: 'airblue',
      code: 'PA',
    },
    {
      name: 'Polar Airlines',
      code: 'PI',
    },
    {
      name: 'Nauru Airline',
      code: 'ON',
    },
    {
      name: 'Orange Air, LLCN/A',
      code: 'O3',
    },
    {
      name: 'Siam Air',
      code: 'O8',
    },
    {
      name: 'Primera Air',
      code: 'PF',
    },
    {
      name: 'Southern Air Charter',
      code: 'PL',
    },
    {
      name: 'Jet Aviation Business',
      code: 'PP',
    },
    {
      name: 'SkyWest Airlines',
      code: 'OO',
    },
    {
      name: 'Antrak Air',
      code: 'O4',
    },
    {
      name: 'NOVA Airlines',
      code: 'O9',
    },
    {
      name: 'Transavia Denmark',
      code: 'PH',
    },
    {
      name: 'Canaryfly',
      code: 'PM',
    },
    {
      name: 'St Barth Commuter',
      code: 'PV',
    },
    {
      name: 'Polar Air Cargo Worldwide, Inc.',
      code: 'PO',
    },
    {
      name: 'Public Charters',
      code: 'P1',
    },
    {
      name: 'Pascan Aviation',
      code: 'P6',
    },
    {
      name: 'Peruvian Airlines',
      code: 'P9',
    },
    {
      name: 'Jazz',
      code: 'QK',
    },
    {
      name: 'Airkenya Express',
      code: 'QP',
    },
    {
      name: 'Airkenya Express',
      code: 'P2',
    },
    {
      name: 'Small Planet Airlines',
      code: 'P7',
    },
    {
      name: 'Qeshm Air',
      code: 'QB',
    },
    {
      name: 'LASER Airlines',
      code: 'QL',
    },
    {
      name: 'Alliance Airlines',
      code: 'QQ',
    },
    {
      name: 'European Air Transport',
      code: 'QY',
    },
    {
      name: 'Aerolíneas Sosa',
      code: 'P4',
    },
    {
      name: 'Air Mekong',
      code: 'P8',
    },
    {
      name: 'Jet Airways Inc.',
      code: 'QJ',
    },
    {
      name: 'Quikjet Cargo Airlines Pvt. Ltd',
      code: 'QO',
    },
    {
      name: 'Avianca Cargo',
      code: 'QT',
    },
    {
      name: 'Sqiva Sistem',
      code: 'Q1',
    },
    {
      name: 'Qingdao Airlines',
      code: 'QW',
    },
    {
      name: 'Maldivian',
      code: 'Q2',
    },
    {
      name: '40-Mile Air',
      code: 'Q5',
    },
    {
      name: 'Air Liaison',
      code: 'Q9',
    },
    {
      name: 'PT Republic Express Airlines',
      code: 'RH',
    },
    {
      name: 'Kam Air',
      code: 'RQ',
    },
    {
      name: 'Anguilla Air Services, Ltd',
      code: 'Q3',
    },
    {
      name: 'Skytrans Airlines',
      code: 'Q6',
    },
    {
      name: 'French Armed Forces',
      code: 'RD',
    },
    {
      name: 'Skyview Airways Company Limiteddba Skyview',
      code: 'RK',
    },
    {
      name: 'Royal Air Force',
      code: 'RR',
    },
    {
      name: '8165343 Canada Inc. dba Air Canada rouge',
      code: 'RV',
    },
    {
      name: 'Starlink Aviation',
      code: 'Q4',
    },
    {
      name: 'SkyBahamas Airlines',
      code: 'Q7',
    },
    {
      name: 'Connellan air disaster',
      code: 'RF',
    },
    {
      name: 'Chautauqua Airlines',
      code: 'RP',
    },
    {
      name: 'Sky Regional Airlines',
      code: 'RS',
    },
    {
      name: 'Regent Airways',
      code: 'RX',
    },
    {
      name: 'AirBridgeCargo',
      code: 'RU',
    },
    {
      name: 'Jiangxi Air',
      code: 'RY',
    },
    {
      name: 'RUS Aviation',
      code: 'R4',
    },
    {
      name: 'Tassili Airlines',
      code: 'SF',
    },
    {
      name: 'Lufthansa Systems',
      code: 'S1',
    },
    {
      name: 'Jetairfly',
      code: 'TB',
    },
    {
      name: 'Sansa Airlines',
      code: 'RZ',
    },
    {
      name: 'Jordan Aviation',
      code: 'R5',
    },
    {
      name: 'Sharp Airlines',
      code: 'SH',
    },
    {
      name: 'Sunrise Airways',
      code: 'S6',
    },
    {
      name: 'Air Tanzania',
      code: 'TC',
    },
    {
      name: 'Tailwind Airlines',
      code: 'TI',
    },
    {
      name: 'DOT LT',
      code: 'R6',
    },
    {
      name: 'AIR CAIRO',
      code: 'SM',
    },
    {
      name: 'Starbow',
      code: 'S9',
    },
    {
      name: 'Sky-Taxi',
      code: 'TE',
    },
    {
      name: 'Tandem Aero',
      code: 'TQ',
    },
    {
      name: 'TACA Peru',
      code: 'T0',
    },
    {
      name: 'Raya Airways Sdn. Bhd.dba Raya Airways',
      code: 'TH',
    },
    {
      name: "T'way Air",
      code: 'TW',
    },
    {
      name: 'TIK Systems',
      code: 'T1',
    },
    {
      name: 'Myanmar National Airlines',
      code: 'UB',
    },
    {
      name: 'UM Airlines',
      code: 'UF',
    },
    {
      name: 'Almasria Universal Airlines',
      code: 'UJ',
    },
    {
      name: 'Nakina Air Service',
      code: 'T2',
    },
    {
      name: 'LAN Chile Cargo',
      code: 'UC',
    },
    {
      name: 'Atlasjet Ukraine',
      code: 'UH',
    },
    {
      name: 'Urumqi Air',
      code: 'UQ',
    },
    {
      name: 'Air Caucasus',
      code: 'UY',
    },
    {
      name: 'As Avies',
      code: 'U3',
    },
    {
      name: 'Telair International GmbH',
      code: 'T8',
    },
    {
      name: 'Ultimate Jetcharters',
      code: 'UE',
    },
    {
      name: 'Auric Air Services Limited',
      code: 'UI',
    },
    {
      name: 'Helicópteros del Sureste',
      code: 'UV',
    },
    {
      name: 'Buraq Air Transport (BRQ)',
      code: 'UZ',
    },
    {
      name: 'Karinou Airlines',
      code: 'U5',
    },
    {
      name: 'Uni-Top Airlines',
      code: 'UW',
    },
    {
      name: 'Videcom International Limited',
      code: 'U1',
    },
    {
      name: 'VivaAerobus',
      code: 'VB',
    },
    {
      name: 'Volga-Dnepr Airlines',
      code: 'VI',
    },
    {
      name: 'Villa Air Pvt. Ltd. dba Villa Air',
      code: 'VP',
    },
    {
      name: 'Thai Vietjet Air',
      code: 'VZ',
    },
    {
      name: 'Charter Air Transport, Inc.',
      code: 'VC',
    },
    {
      name: 'VietjetAir',
      code: 'VJ',
    },
    {
      name: 'Novoair',
      code: 'VQ',
    },
    {
      name: 'IBS Software Services Americas, Inc.',
      code: 'V1',
    },
    {
      name: 'Thai Vietjet Air',
      code: 'V9',
    },
    {
      name: 'Labrador Airways Limited',
      code: 'WJ',
    },
    {
      name: 'Aeropostal Alas de Venezuela',
      code: 'VH',
    },
    {
      name: 'Air Via Ltd.',
      code: 'VL',
    },
    {
      name: 'Vuelos Economicos Centroamericanos',
      code: 'VU',
    },
    {
      name: 'Clairmont Holdings Ltd dba VI Air Link',
      code: 'V6',
    },
    {
      name: 'Islena de Inversiones S.A. de C.V.dba Islena Airlines',
      code: 'WC',
    },
    {
      name: 'Swoop',
      code: 'WO',
    },
    {
      name: 'Iliamna Air Taxi Inc.',
      code: 'V8',
    },
    {
      name: 'Thai Smile',
      code: 'WE',
    },
    {
      name: 'Swiftair',
      code: 'WT',
    },
    {
      name: 'World Ticket Ltd',
      code: 'W1',
    },
    {
      name: 'Cargojet Airways Ltd.',
      code: 'W8',
    },
    {
      name: 'Air Transport Association of Americad\\b\\a Airlines for America',
      code: 'XD',
    },
    {
      name: 'Aero VIPCompanhia Transportes Servicos Aereos SA',
      code: 'WV',
    },
    {
      name: 'LC Busre SAC',
      code: 'W4',
    },
    {
      name: 'ARINC(Aeronautical Radio, Inc.)',
      code: 'XA',
    },
    {
      name: 'ExpressJet',
      code: 'XE',
    },
    {
      name: 'South East Asian Airlines (SEAIR)International, Inc.',
      code: 'XO',
    },
    {
      name: 'Indonesia AirAsia Extra, PT',
      code: 'XT',
    },
    {
      name: 'WOW Air ehf',
      code: 'WW',
    },
    {
      name: 'Mahan Airlines',
      code: 'W5',
    },
    {
      name: 'IATA - Clearing HouseInternational Air Transport Association',
      code: 'XB',
    },
    {
      name: 'Special Ground Handling Service -XH',
      code: 'XH',
    },
    {
      name: 'TEM Enterprises',
      code: 'XP',
    },
    {
      name: 'African Express Airways (K)  Limited',
      code: 'XU',
    },
    {
      name: 'Aeronautical Telecommunications Ltd.(AEROTEL)',
      code: 'XI',
    },
    {
      name: 'SITA - Airlines WorldwideTelecommunications and Information Svcs',
      code: 'XS',
    },
    {
      name: 'NokScoot',
      code: 'XW',
    },
    {
      name: 'Exec Air, Inc. of Naples',
      code: 'X7',
    },
    {
      name: 'Department of National Defence',
      code: 'YF',
    },
    {
      name: 'Asian Wings Airways Limited',
      code: 'YJ',
    },
    {
      name: 'Air Excursion, LLC',
      code: 'X4',
    },
    {
      name: 'Airmax S.A.',
      code: 'X8',
    },
    {
      name: 'Sunsplash Aviation, LLC.',
      code: 'YH',
    },
    {
      name: 'Perimeter Aviation Ltd.',
      code: 'YP',
    },
    {
      name: 'EuroAtlantic Airways S.A.',
      code: 'YU',
    },
    {
      name: 'Yangtze River Express',
      code: 'Y8',
    },
    {
      name: 'S.C. Ten Airways S.R.L.',
      code: 'X5',
    },
    {
      name: 'Yan Air Ltd',
      code: 'YE',
    },
    {
      name: 'Air Sunshine Inc.',
      code: 'YI',
    },
    {
      name: 'Grand Canyon Airlines, Inc.dba Scenic Airlines',
      code: 'YR',
    },
    {
      name: 'Travel Technology Interactive SA',
      code: 'Y1',
    },
    {
      name: 'Kish Airlines',
      code: 'Y9',
    },
    {
      name: 'Yeti Airlines Domestic Pvt. Ltd.',

      code: 'YT',
    },
    {
      name: 'Golden Myanmar Airlines Public Co., Ltd',
      code: 'Y5',
    },
    {
      name: 'Skywings Asia Airlines Co., Ltd.',
      code: 'ZA',
    },
    {
      name: 'Air Manasdba Air Manas ltd. Air Company',
      code: 'ZM',
    },
    {
      name: 'JSC Kazaviaspas',
      code: 'ZS',
    },
    {
      name: 'Air Wisconsin Airlines Corporation(AWAC)',
      code: 'ZW',
    },
    {
      name: 'Azur Air',
      code: 'ZF',
    },
    {
      name: 'Silk Way Airlines',
      code: 'ZP',
    },
    {
      name: 'Titan Airways Limited',
      code: 'ZT',
    },
    {
      name: 'Air Georgian Ltd. dba Air Alliance',
      code: 'ZX',
    },
    {
      name: 'NU-Aero (Pvt) Ltdt/a Fly Africa Zimbabwe',
      code: 'Z7',
    },
    {
      name: 'Amadeus IT Group SA',
      code: '1A',
    },
    {
      name: 'Zambezi Airlines',
      code: 'ZJ',
    },
    {
      name: 'Aviacon Zitotrans Air Company JSC',
      code: 'ZR',
    },
    {
      name: 'V Air Corporation',
      code: 'ZV',
    },
    {
      name: 'PM Air LLC',
      code: 'Z3',
    },
    {
      name: 'Premium Jet AG',
      code: '0J',
    },
    {
      name: 'Abacus International Pte. Ltd.',
      code: '1B',
    },
    {
      name: 'Zagros Air Co.',
      code: 'Z4',
    },
    {
      name: 'Hydro - Quebec',
      code: '0Q',
    },
    {
      name: 'Hewlett-Packard (Schweiz) GmbH',
      code: '1C',
    },
    {
      name: 'INFINI Travel Information, Inc.',
      code: '1F',
    },
    {
      name: 'ChallengAir',
      code: '1I',
    },
    {
      name: 'Navitaire Open Skies',
      code: '1L',
    },
    {
      name: 'Radixx Solutions International, Inc.',
      code: '1D',
    },
    {
      name: 'Travelport Global Distribution SystemB.V.',
      code: '1G',
    },
    {
      name: 'Axess International Network Inc.',
      code: '1J',
    },
    {
      name: 'JSC Transport Automated InformationSystems - TAIS',
      code: '1M',
    },
    {
      name: 'Bird Information Systems Private Limited',
      code: '1R',
    },
    {
      name: 'ITA Software Inc.',
      code: '1U',
    },
    {
      name: 'Travelsky Technology Limited',
      code: '1E',
    },
    {
      name: 'JSC "Sirena-Travel"',
      code: '1H',
    },
    {
      name: 'Sutra Inc.',
      code: '1K',
    },
    {
      name: 'Navitaire',
      code: '1N',
    },
    {
      name: 'Sabre Inc.',
      code: '1S',
    },
    {
      name: 'Galileo International',
      code: '1V',
    },
    {
      name: 'Sabre Pacific',
      code: '1Z',
    },
    {
      name: 'PT Aerosystems Indonesia',
      code: '1T',
    },
    {
      name: 'Fly Branson Travel dba Branson AirExpress',
      code: '1X',
    },
    {
      name: 'Deutsche Bahn AG',
      code: '2A',
    },
    {
      name: 'Afrinat International Airlines',
      code: '2F',
    },
    {
      name: 'Air Cargo Carriers, LLC.',
      code: '2Q',
    },
    {
      name: "Sun d'Or International Airlines",
      code: '2U',
    },
    {
      name: 'Electronic Data Systems Corporation',
      code: '1Y',
    },
    {
      name: 'Dynamic Airways, LLC',
      code: '2D',
    },
    {
      name: 'Thalys International',
      code: '2H',
    },
    {
      name: 'VIA Rail Canada Inc.',
      code: '2R',
    },
    {
      name: 'Amtrak',
      code: '2V',
    },
    {
      name: 'Chu Kong Passenger Transport Co. Ltd.',
      code: '3A',
    },
    {
      name: 'Smokey Bay Air, Inc.',
      code: '2E',
    },
    {
      name: 'Redemption Inc.',
      code: '2O',
    },
    {
      name: 'Kinda Airlines',
      code: '2S',
    },
    {
      name: 'PT. My Indo Airlines',
      code: '2Y',
    },
    {
      name: 'Calima Aviacion S.L',
      code: '3C',
    },
    {
      name: 'Cargo Three Inc. dba Pan Air Cargo',
      code: '3J',
    },
    {
      name: 'Passaredo Transportes Aereos S.A.',
      code: '2Z',
    },
    {
      name: 'DoKaSch',
      code: '3D',
    },
    {
      name: 'Air Urga',
      code: '3N',
    },
    {
      name: 'Malawian Airlines',
      code: '3W',
    },
    {
      name: 'Travel Service Polska Sp. z.o.o.',
      code: '3Z',
    },
    {
      name: 'LAN COLOMBIA AIRLINES',
      code: '4C',
    },
    {
      name: 'Pacific Airways Inc.',
      code: '3F',
    },
    {
      name: 'Tarco Air',
      code: '3T',
    },
    {
      name: 'Premier Trans Aire Inc.',
      code: '3X',
    },
    {
      name: 'Aero Transporte S.A.',
      code: '4A',
    },
    {
      name: 'Stabo Air Limited',
      code: '4E',
    },
    {
      name: 'IHY Izmir Havayollari A.S.',
      code: '4I',
    },
    {
      name: 'TNT Airways',
      code: '3V',
    },
    {
      name: 'Kartika Airlines',
      code: '3Y',
    },
    {
      name: 'Boutique Air',
      code: '4B',
    },
    {
      name: 'Air City',
      code: '4F',
    },
    {
      name: 'Kenn Borek Air Ltd.',
      code: '4K',
    },
    {
      name: 'Travel Air',
      code: '4P',
    },
    {
      name: 'United Airways Bangladesh Ltd',
      code: '4H',
    },
    {
      name: 'Palau National Airlines',
      code: '4L',
    },
    {
      name: 'CJSC Royal Flight airlines',
      code: '4R',
    },
    {
      name: 'Mercury Air Cargo, Inc.',
      code: '4X',
    },
    {
      name: 'Bassaka Air Limited',
      code: '5B',
    },
    {
      name: 'Fly One',
      code: '5F',
    },
    {
      name: 'Air North Charter and Training Ltd.',
      code: '4N',
    },
    {
      name: 'Solar Cargo, C.A.',
      code: '4S',
    },
    {
      name: 'Airbus Transport International',
      code: '4Y',
    },
    {
      name: 'Aerolitoral S.A. de C.V.',
      code: '5D',
    },
    {
      name: '540 Ghana Ltd.',
      code: '5G',
    },
    {
      name: 'Hi Fly Ltd',
      code: '5M',
    },
    {
      name: 'Lignes Aeriennes Congolaises',
      code: '4V',
    },
    {
      name: 'Alpine Aviation Inc.',
      code: '5A',
    },
    {
      name: 'Siam Ga Co. Ltd.',
      code: '5E',
    },
    {
      name: 'Alsa Grupo SLU',
      code: '5I',
    },
    {
      name: 'Rutas Aereas, C.A. dba  RUTACA',
      code: '5R',
    },
    {
      name: 'Everts Air Cargo',
      code: '5V',
    },
    {
      name: 'Hi Fly',
      code: '5K',
    },
    {
      name: 'Global Aviation Services Group',
      code: '5S',
    },
    {
      name: '5W-Rail&Shuttle',
      code: '5W',
    },
    {
      name: 'CemAir',
      code: '5Z',
    },
    {
      name: 'Air Timor',
      code: '6C',
    },
    {
      name: 'Air Alsie',
      code: '6I',
    },
    {
      name: 'Transportes Aéreos Regionales',
      code: '5U',
    },
    {
      name: 'UPS',
      code: '5X',
    },
    {
      name: 'Armenia Airways',
      code: '6A',
    },
    {
      name: 'Travel Service Slovensko',
      code: '6D',
    },
    {
      name: 'Solaseed Air',
      code: '6J',
    },
    {
      name: 'Zacarias Moreno',
      code: '6M',
    },
    {
      name: 'Atlas Air',
      code: '5Y',
    },
    {
      name: 'TUIfly Nordic',
      code: '6B',
    },
    {
      name: 'Sun Air Express',
      code: '6G',
    },
    {
      name: 'Kyrgyz Trans Avia',
      code: '6K',
    },
    {
      name: 'Niger Airlines',
      code: '6N',
    },
    {
      name: 'Air Cargo Germany',
      code: '6U',
    },
    {
      name: 'Aklak Air',
      code: '6L',
    },
    {
      name: 'Orbest',
      code: '6O',
    },
    {
      name: 'AVANZA',
      code: '6V',
    },
    {
      name: 'StarFlyer',
      code: '7G',
    },
    {
      name: 'Travel Service',
      code: '7O',
    },
    {
      name: 'Ryan Air Service',
      code: '7S',
    },
    {
      name: 'Air Mandalay',
      code: '6T',
    },
    {
      name: 'Euro-Asia Air',
      code: '6Z',
    },
    {
      name: 'MAYAir',
      code: '7M',
    },
    {
      name: 'Air Panama',
      code: '7P',
    },
    {
      name: 'Trenitalia',
      code: '7T',
    },
    {
      name: 'Halcyonair',
      code: '7Z',
    },
    {
      name: 'Sylt Air GmbH',
      code: '7E',
    },
    {
      name: 'Pan American World Airways',
      code: '7N',
    },
    {
      name: 'Elite Airways',
      code: '7Q',
    },
    {
      name: 'Alpha Air Transport',
      code: '7U',
    },
    {
      name: 'Panama Airways',
      code: '8A',
    },
    {
      name: 'FITS Aviation (Pvt) Ltd',
      code: '8D',
    },
    {
      name: 'Mann Yadanarpon Airlines',
      code: '7Y',
    },
    {
      name: 'Business Air Co Ltd',
      code: '8B',
    },
    {
      name: 'STP Airways',
      code: '8F',
    },
    {
      name: 'EcoJet',
      code: '8J',
    },
    {
      name: 'TurboJET',
      code: '8S',
    },
    {
      name: 'Eaglexpress Air Charter Sdn.Bhd.dbaEaglexpress Air',
      code: '9A',
    },
    {
      name: 'Air Transport International L.L.C.',
      code: '8C',
    },
    {
      name: 'Mid Africa Aviation',
      code: '8G',
    },
    {
      name: 'K-Mile Air Co. Ltd',
      code: '8K',
    },
    {
      name: 'Air Tindi',
      code: '8T',
    },
    {
      name: 'Spring Airlines',
      code: '9C',
    },
    {
      name: 'Alliance Air',
      code: '9I',
    },
    {
      name: 'BH Air',
      code: '8H',
    },
    {
      name: 'Regional Air Services',
      code: '8N',
    },
    {
      name: 'Private Wings',
      code: '8W',
    },
    {
      name: 'Endeavor Air',
      code: '9E',
    },
    {
      name: 'Dana Airlines Limited',
      code: '9J',
    },
    {
      name: 'Air Arabia Jordan',
      code: '9P',
    },
    {
      name: '9G Rail',
      code: '9G',
    },
    {
      name: 'West Link Airlines',
      code: '9L',
    },
    {
      name: 'Caicos Express Airways',
      code: '9Q',
    },
    {
      name: 'Executive Express Aviation LLC dbaSouthern Airways',
      code: '9X',
    },
    {
      name: 'SW Italia s.p.a.',
      code: 'IU',
    },
    {
      name: 'Nomad Aviation (PTY) Ltd.',
      code: 'N6',
    },
    {
      name: 'Tropic Air Limited',
      code: '9N',
    },
    {
      name: 'Southern Air Inc.',
      code: '9S',
    },
    {
      name: 'Vistara',
      code: 'UK',
    },
    {
      name: 'Sounds Air Travel & Tourism Ltd.',
      code: 'S8',
    },
    {
      name: 'FlyCorporate',
      code: 'FC',
    },
    {
      name: 'Guangxi Beibu Gulf Airlines Co., Ltd.',
      code: 'GP',
    },
    {
      name: 'ACT Havayollari A.S.',
      code: '9T',
    },
    {
      name: 'Wings of Lebanon S.a.l',
      code: 'W7',
    },
    {
      name: 'Conquest Air, Inc.',
      code: 'C4',
    },
    {
      name: 'Aerotranscargo',
      code: 'F5',
    },
    {
      name: 'Ningxia Cargo Airlines CO.,LTD',
      code: 'HT',
    },
    {
      name: 'Regional Jet OÜ',
      code: 'EE',
    },
    {
      name: 'ATA Airlines',
      code: 'I3',
    },
    {
      name: 'Major Airline',
      code: 'YY',
    },
    {
      name: 'Russian Railways',
      code: 'R0',
    },
    {
      name: 'JetHunter',
      code: '01',
    },
    {
      name: 'Up Israel',
      code: '86',
    },
    {
      name: 'Buta Airways',
      code: '55',
    },
    {
      name: 'Ernest Airline',
      code: 'EG',
    },
    {
      name: 'SCAT',
      code: 'DV',
    },
    {
      name: 'Smartavia (Nordavia)',
      code: '5N',
    },
    {
      name: 'Jasmin Airways',
      code: 'JO',
    },
  ],
};
