import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    scrollBarWidth: 24,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: '#3470DD',
          darken1: '#124399',
        },
        secondary: '#5193BD',
        accent: '#B0D7ED',
        error: '#CD2026',
        'error-secondary': '#FFCCC7',
        'error-tertiary': '#FFF2F0',
        background: '#faf9f7',
        info: '#0071BC',
        'info-secondary': '#91D5FF',
        'info-tertiary': '#E6F7FF',
        warning: '#FAAD14',
        'warning-secondary': '#FFE58F',
        'warning-tertiary': '#FFFBE6',
        tertiary: '#E3F0FF',
        success: '#2E8540',
        'success-secondary': '#B7EB8F',
        'success-tertiary': '#F6FFED',
        grey1: '#F9F9FB',
        grey2: '#F2F4F7',
        grey3: '#E4E8EF',
        grey4: '#CFD5E0',
        grey5: '#B8C1D1',
        grey6: '#858F9D',
        grey7: '#586271',
        grey8: '#21272F',
        grey9: '#0A0E14',
      },
    },
  },
});
