<script>
export default {
  name: 'FlightsFFMixins',
  created() {
    this.checkShowFlightsInContextOnLandingPageFF();
  },
  data() {
    return {
      showFlightsInContextOnLandingPageFF: false,
    };
  },
  methods: {
    async checkShowFlightsInContextOnLandingPageFF() {
      this.showFlightsInContextOnLandingPageFF = await this.$ldclient.variation(
        'show-flights-in-context-on-landing-page',
        false,
        true
      );
      this.$ldclient.$on(
        'change:show-flights-in-context-on-landing-page',
        (value) => {
          this.showFlightsInContextOnLandingPageFF = value;
        }
      );
    },
  },
};
</script>

<style scoped></style>
