export default class OrderCreation {
  init({ travelerId, amount, products }) {
    if (!!travelerId && !travelerId instanceof String)
      throw new Error('TravelerId must be instanceof String');

    if (!!products && !products instanceof Array)
      throw new Error('products must be instanceof Array');

    this.travelerId = travelerId;
    this.amount = amount;
    this.products = products;
  }

  static toRequestObject(order = this) {
    return {
      amount: order.amount,
      travelerId: order.travelerId,
      products: order.products,
    };
  }
}
