import * as mutationTypes from './mutation-types';

export const mutations = {
  [mutationTypes.ARTICLE_PRODUCTS_LOADED](
    state,
    { products } = { products: {} }
  ) {
    state.articleProducts = {
      ...state.articleProducts,
      value: { ...products },
    };
  },
  [mutationTypes.ARTICLE_PRODUCTS_LOADING](state, payload = true) {
    state.articleProducts = { ...state.articleProducts, isLoading: payload };
  },
  [mutationTypes.ARTICLE_PRODUCTS_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.articleProducts = {
      ...state.details,
      value: null,
      error: error,
    };
  },
  [mutationTypes.INFORMATION_MODULE_CONTENT_LOADED](state, value) {
    state.informationModuleContent = {
      ...state.informationModuleContent,
      value,
    };
  },
  [mutationTypes.INFORMATION_MODULE_CONTENT_LOADING](state, payload = true) {
    state.informationModuleContent = {
      ...state.informationModuleContent,
      isLoading: payload,
    };
  },
  [mutationTypes.INFORMATION_MODULE_CONTENT_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.informationModuleContent = {
      ...state.informationModuleContent,
      value: null,
      error: error,
    };
  },
  [mutationTypes.ARTICLES_LOADED](state, value) {
    state.articles = {
      ...state.articles,
      value,
    };
  },
  [mutationTypes.ARTICLES_LOADING](state, payload = true) {
    state.articles = {
      ...state.articles,
      isLoading: payload,
    };
  },
  [mutationTypes.ARTICLES_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.articles = {
      ...state.articles,
      value: null,
      error: error,
    };
  },

  [mutationTypes.CITY_SEARCH_COMPLETED](state, { result } = { result: {} }) {
    state.citySearch = { ...state.citySearch, result: { ...result } };
  },
  [mutationTypes.CITY_SEARCH_LOADING](state, payload = true) {
    state.citySearch = { ...state.citySearch, isLoading: payload };
  },
  [mutationTypes.CITY_SEARCH_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.citySearch = {
      ...state.citySearch,
      result: null,
      error: error,
    };
  },

  [mutationTypes.REVERSE_GEOCODE_COMPLETED](
    state,
    { result } = { result: {} }
  ) {
    state.reverseGeocode = { ...state.reverseGeocode, result: { ...result } };
  },
  [mutationTypes.REVERSE_GEOCODE_LOADING](state, payload = true) {
    state.reverseGeocode = { ...state.reverseGeocode, isLoading: payload };
  },
  [mutationTypes.REVERSE_GEOCODE_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.reverseGeocode = {
      ...state.reverseGeocode,
      result: null,
      error: error,
    };
  },
};
