export const getters = {
  hasCitySearchResult: (state) =>
    !state.citySearch.isLoading && state.citySearch.result !== null,
  heroImage: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.hero_image
      ? rootState.configs.hero_image
      : null;
  },
  heroImageMobile: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.hero_image_mobile
      ? rootState.configs.hero_image_mobile
      : null;
  },
  heroText: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.header_text
      ? rootState.configs.header_text
      : null;
  },
  heroFlightConfig: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.flight_themes
      ? rootState.configs.flight_themes
      : null;
  },
  landingPageTitle: ({}, {}, rootState) => {
    let title = '';
    if (rootState.configs && rootState.configs.landing_page_title) {
      title = rootState.configs.landing_page_title;
    } else if (rootState.configs && rootState.configs.title) {
      title = rootState.configs.title;
    }
    return title;
  },
  flightTriggerText: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.flight_trigger
      ? rootState.configs.flight_trigger
      : null;
  },
  flightTriggerImage: ({}, {}, rootState) => {
    if (
      rootState.configs &&
      rootState.configs.flight_trigger &&
      !!rootState.configs.flight_trigger.trigger_image
    ) {
      return rootState.configs.flight_trigger.trigger_image;
    }
    return null;
  },
  heroImageMaxSize: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.hero_image_max_height
      ? rootState.configs.hero_image_max_height
      : '640px';
  },
  heroImageMobileMaxSize: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.hero_image_mobile_max_height
      ? rootState.configs.hero_image_mobile_max_height
      : '640px';
  },
  restrictionTitle: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.restriction_title
      ? rootState.configs.restriction_title
      : null;
  },

  restrictionText: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.restriction_text
      ? rootState.configs.restriction_text
      : null;
  },

  pnrTitle: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.pnr_title
      ? rootState.configs.pnr_title
      : null;
  },

  pnrNote: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.pnr_note
      ? rootState.configs.pnr_note
      : null;
  },

  seatHelpText: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.seat_help_text
      ? rootState.configs.seat_help_text
      : null;
  },

  flightsCleanupCriteria: ({}, {}, rootState) => {
    return rootState.configs && rootState.configs.flights_cleanup_criteria
      ? rootState.configs.flights_cleanup_criteria
      : null;
  },

  hamburgerMenuItems: ({}, {}, rootState) => {
    return rootState.configs &&
      rootState.configs.navigation &&
      rootState.configs.navigation.hamburger
      ? rootState.configs.navigation.hamburger
      : null;
  },

  menuBarItems: ({}, {}, rootState) => {
    return rootState.configs &&
      rootState.configs.navigation &&
      rootState.configs.navigation.main
      ? rootState.configs.navigation.main
      : null;
  },
};
