<script>
export default {
  name: 'TravelerMixins',
  computed: {
    travelerId() {
      return this.$store.getters['travelerId'] || null;
    },
  },
};
</script>
