import * as mutations from './mutation-types';
import { RepoFactory } from '@/core/repositories';
import { mapboxService } from '@/core/services/MapboxService';

export const actions = {
  clearRides({ commit }) {
    commit(mutations.RIDES_CLEAR);
  },
  async searchRides(
    { commit },
    {
      startLocation,
      endLocation,
      startTime,
      passengers,
      polling,
      minPrice,
      maxPrice,
      currency,
      vehicleSize,
    }
  ) {
    commit(mutations.SET_SEARCH_TIMESTAMP);
    const transportationService = RepoFactory.get('transportation');
    commit(mutations.RIDES_ERROR, { error: null });
    commit(mutations.RIDES_LOADING, true);

    try {
      const { data } = await transportationService.getPollingInfo({
        startLocation,
        endLocation,
        startTime,
        passengers,
        polling,
        minPrice,
        maxPrice,
        currency,
        vehicleSize,
      });
      const { pollingId, pollingRequiredInSec } = data;
      await new Promise((resolve) => {
        setTimeout(resolve, Math.floor(pollingRequiredInSec * 1000));
      });
      const response = await transportationService.getRides(pollingId, {
        startLocation,
        endLocation,
        startTime,
        passengers,
        polling,
        minPrice,
        maxPrice,
        currency,
        vehicleSize,
      });
      let mutation;
      let mutationArgs;
      mutation = mutations.RIDES_LOADED;
      mutationArgs = {
        rides: response.data.items,
        total: response.data.total,
        startLocation,
        endLocation,
        startTime,
        passengers,
        polling,
        minPrice,
        maxPrice,
        currency,
        vehicleSize,
      };
      commit(mutation, mutationArgs);
    } catch (error) {
      commit(mutations.RIDES_ERROR, {
        error: error.response,
      });
    } finally {
      commit(mutations.RIDES_LOADING, false);
    }
  },

  async performLocationFromSearch({ commit }, location) {
    commit(mutations.GT_LOCATION_FROM_SEARCH_ERROR, { error: null });
    commit(mutations.GT_LOCATION_FROM_SEARCH_LOADING, true);
    try {
      const data = await mapboxService.getLocation(location);
      commit(mutations.GT_LOCATION_FROM_SEARCH_COMPLETED, {
        result: data,
      });
    } catch (error) {
      commit(mutations.GT_LOCATION_FROM_SEARCH_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.GT_LOCATION_FROM_SEARCH_LOADING, false);
    }
  },
  async performLocationToSearch({ commit }, location) {
    commit(mutations.GT_LOCATION_TO_SEARCH_ERROR, { error: null });
    commit(mutations.GT_LOCATION_TO_SEARCH_LOADING, true);
    try {
      const data = await mapboxService.getLocation(location);
      commit(mutations.GT_LOCATION_TO_SEARCH_COMPLETED, {
        result: data,
      });
    } catch (error) {
      commit(mutations.GT_LOCATION_TO_SEARCH_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.GT_LOCATION_TO_SEARCH_LOADING, false);
    }
  },
  async performUserToReverseGeocode({ commit }, { lat, lng }) {
    commit(mutations.USER_TO_REVERSE_GEOCODE_ERROR, { error: null });
    commit(mutations.USER_TO_REVERSE_GEOCODE_LOADING, true);
    try {
      const data = await mapboxService.reverseGeocode({ lat: lat, lng: lng });
      commit(mutations.USER_TO_REVERSE_GEOCODE_COMPLETED, { result: data });
    } catch (error) {
      commit(mutations.USER_TO_REVERSE_GEOCODE_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.USER_TO_REVERSE_GEOCODE_LOADING, false);
    }
  },
  async performUserFromReverseGeocode({ commit }, { lat, lng }) {
    commit(mutations.USER_FROM_REVERSE_GEOCODE_ERROR, { error: null });
    commit(mutations.USER_FROM_REVERSE_GEOCODE_LOADING, true);
    try {
      const data = await mapboxService.reverseGeocode({ lat: lat, lng: lng });
      commit(mutations.USER_FROM_REVERSE_GEOCODE_COMPLETED, { result: data });
    } catch (error) {
      commit(mutations.USER_FROM_REVERSE_GEOCODE_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.USER_FROM_REVERSE_GEOCODE_LOADING, false);
    }
  },
};
