<template>
  <v-card width="420" rounded class="remove-flight-card">
    <v-container class="px-0 py-4">
      <v-row no-gutters>
        <v-col cols="12" class="pl-5 d-flex justify-space-between align-center">
          <span class="top-section">
            <v-btn icon @click="onSubmit" height="20" width="20" class="mr-2">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ $t('flights.remove_flights') }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="px-2 confirm"
                  v-bind="attrs"
                  v-on="on"
                  size="13px"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              {{ $t('flights.remove_flight_info') }}
            </v-tooltip>
            <span @click="onSubmit" class="confirm submit px-2">
              {{ $t('done') }}
            </span>
          </span>

          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-icon
              size="25"
              color="var(--v-grey7-base)"
              @click="$emit('close')"
              class="close-icon mr-4"
            >
              mdi-close
            </v-icon>
          </template>
        </v-col>

        <v-col cols="12">
          <v-list class="remove-flight-card-list pt-0">
            <v-list-item-group :ripple="false">
              <v-list-item
                v-for="flight in contextFlights"
                :key="`flight-${flight.id}`"
                class="px-5 pb-0 pt-3"
              >
                <v-list-item-content class="pa-0 mt-5 mt-sm-2">
                  <v-list-item-title
                    class="mb-0"
                    :class="{
                      'is-selected': checkIfSelectedForDeletion(flight.id),
                    }"
                  >
                    {{ getArrivalCityTitle(flight) }}
                    <v-icon size="14px" color="#000">mdi-arrow-right</v-icon>
                    {{ getDepartureCityTitle(flight) }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="pt-2 mb-2"
                    :class="{
                      'is-selected': checkIfSelectedForDeletion(flight.id),
                    }"
                  >
                    {{ flight.flightNumber }} ·
                    {{ formatFlightDateAndTime(flight.departureTime) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <template v-if="checkIfSelectedForDeletion(flight.id)">
                    <span
                      aria-hidden="true"
                      class="confirm"
                      @click="undoDelete(flight.id)"
                    >
                      {{ $t('undo') }}
                    </span>
                  </template>

                  <template v-else>
                    <v-btn
                      class="item-action"
                      text
                      small
                      icon
                      @click.stop="onDeleteClick(flight.id)"
                      :aria-label="$t('flights.remove_flight_from_list')"
                      :title="$t('flights.remove_flight_from_list')"
                    >
                      <v-icon aria-hidden="true" size="24">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
import moment from 'moment';

export default {
  name: 'RemoveFlightCard',
  mixins: [FlightsMixins],

  data() {
    return {
      flightIdsSelected: [],
      currentFlightSelected: null,
    };
  },

  methods: {
    getDepartureCityTitle(flight) {
      return `${flight.destination.city} ${flight.destination.iata}`;
    },

    getArrivalCityTitle(flight) {
      return `${flight.origin.city} ${flight.origin.iata}`;
    },

    onDeleteClick(id) {
      this.flightIdsSelected = [...this.flightIdsSelected, id];
      this.currentFlightSelected = id;
    },

    undoDelete(id) {
      this.flightIdsSelected = this.flightIdsSelected.filter(
        (flightId) => flightId !== id
      );
    },

    onSubmit() {
      if (this.flightIdsSelected && this.flightIdsSelected.length) {
        this.removeFlightsFromContext(this.flightIdsSelected);
        this.$emit('click');
      } else this.$emit('return');
    },

    checkIfSelectedForDeletion(id) {
      if (this.flightIdsSelected && this.flightIdsSelected.length) {
        return this.flightIdsSelected.includes(id);
      }
    },

    formatFlightDateAndTime(flightTime) {
      return moment.parseZone(flightTime).format('MMM DD, YYYY · HH:mm');
    },
  },
};
</script>

<style scoped lang="scss">
.remove-flight-card {
  overflow-y: hidden;

  &-list {
    overflow-y: auto;
    cursor: initial;
    max-height: calc(100vh - 140px);

    @media (min-width: map-get($grid-breakpoints, sm)) {
      max-height: min(440px, calc(90vh - 260px));
    }
  }
}

.v-list-item__title {
  @include font-size(16, 150, 400);
}

.v-list-item__subtitle {
  @include font-size(14, 121.4287, 400);
}

.close-icon {
  @include font-size(25, 98, 400);
}

.item-action {
  height: 24px;
}

.confirm {
  color: var(--v-primary-base);
  cursor: pointer;

  &.submit {
    @include font-size(14, 150, 400);
  }
}

.top-section {
  @include font-size(18, 150, 600);
}

.v-tooltip__content {
  pointer-events: auto;
}

.is-selected {
  text-decoration: line-through;
}

.theme--light.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  opacity: 0;
}
</style>
