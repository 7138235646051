import {
  DINING_SEARCH_LOADED,
  DINING_SEARCH_ERROR,
  DINING_SEARCH_LOADING,
  DINING_SEARCH_CLEAR,
  DINING_MORE_SEARCH_LOADED,
  CITY_SEARCH_COMPLETED,
  CITY_SEARCH_LOADING,
  CITY_SEARCH_ERROR,
  REVERSE_GEOCODE_ERROR,
  REVERSE_GEOCODE_LOADING,
  REVERSE_GEOCODE_COMPLETED,
  DINING_SEARCH_CENTER_UPDATED,
} from './mutation-types';

export const mutations = {
  [DINING_SEARCH_LOADED](
    state,
    {
      items,
      total,
      lng,
      lat,
      city,
      tags,
      minRating,
      minPriceScale,
      maxPriceScale,
      radius,
    } = {
      items: [],
      total: 0,
      lng: 0,
      lat: 0,
      city: '',
      tags: [],
      minRating: 0,
      minPriceScale: 0,
      maxPriceScale: 0,
      radius: 0,
    }
  ) {
    state.diningSearch = {
      ...state.diningSearch,
      items: items,
      total: total,
      city: city,
      lng: lng,
      lat: lat,
      tags,
      minRating,
      minPriceScale,
      maxPriceScale,
      radius,
    };
  },
  [DINING_MORE_SEARCH_LOADED](state, { items } = { items: [] }) {
    state.diningSearch = {
      ...state.diningSearch,
      items: [...state.diningSearch.items, ...items],
    };
  },
  [DINING_SEARCH_LOADING](state, payload = true) {
    state.diningSearch = {
      ...state.diningSearch,
      isLoading: payload,
    };
  },
  [DINING_SEARCH_CLEAR](state) {
    state.diningSearch = {
      error: null,
      errorResponse: null,
      isLoading: false,
      items: null,
      total: null,
      lng: null,
      lat: null,
      city: null,
    };
  },
  [DINING_SEARCH_ERROR](
    state,
    { error, errorResponse } = {
      error: 'Error: Unknown error',
      errorResponse: 'Error: Unknown error',
    }
  ) {
    state.diningSearch = {
      ...state.diningSearch,
      error: error,
      errorResponse: errorResponse,
    };
  },
  [CITY_SEARCH_COMPLETED](state, { result } = { result: {} }) {
    state.citySearch = { ...state.citySearch, result: { ...result } };
  },
  [CITY_SEARCH_LOADING](state, payload = true) {
    state.citySearch = { ...state.citySearch, isLoading: payload };
  },
  [CITY_SEARCH_ERROR](state, { error } = { error: 'Error: Unknown error' }) {
    state.citySearch = {
      ...state.citySearch,
      result: null,
      error: error,
    };
  },

  [REVERSE_GEOCODE_COMPLETED](state, { result } = { result: {} }) {
    state.reverseGeocode = { ...state.reverseGeocode, result: { ...result } };
  },
  [REVERSE_GEOCODE_LOADING](state, payload = true) {
    state.reverseGeocode = { ...state.reverseGeocode, isLoading: payload };
  },
  [REVERSE_GEOCODE_ERROR](
    state,
    { error } = { error: 'Error: Unknown error' }
  ) {
    state.reverseGeocode = {
      ...state.reverseGeocode,
      result: null,
      error: error,
    };
  },
  [DINING_SEARCH_CENTER_UPDATED](state, coordinates) {
    state.updateCenterCoordinates = {
      ...state.updateCenterCoordinates,
      value: coordinates,
    };
  },
};
