const PaymentPage = () =>
  import('@/modules/product/features/Payment/PaymentPage.vue');
const PaymentMethodsPage = () =>
  import('@/modules/product/features/Payment/PaymentMethodsPage.vue');
const PaymentStatusPage = () =>
  import('@/modules/product/features/Payment/PaymentStatusPage.vue');

const namedPaymentRoute = (
  name,
  {
    fallbackPathName,
    storeModuleNameFn,
    discoverMoreCtaText,
    requiresPoll = false,
    confirmationTypeOverride,
  }
) => ({
  path: 'payment/:localOrderId',
  component: PaymentPage,
  props: true,
  meta: {
    baseName: name,
    fallbackPathName,
    storeModuleNameFn,
    requiresPoll,
  },
  children: [
    {
      name,
      path: '',
      props: true,
      component: PaymentMethodsPage,
      meta: {
        baseName: name,
        fallbackPathName,
        storeModuleNameFn,
        requiresPoll,
      },
    },
    {
      name: `${name}-status`,
      path: 'status',
      props: true,
      component: PaymentStatusPage,
      meta: {
        baseName: name,
        fallbackPathName,
        storeModuleNameFn,
        discoverMoreCtaText,
        confirmationTypeOverride,
      },
    },
  ],
});

export { namedPaymentRoute };
