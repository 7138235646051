import { RepoFactory } from '@/core/repositories';
import * as mutations from './mutation-types';
import moment from 'moment';

export const actions = {
  async loadFlights(
    { commit },
    {
      flightNumber,
      departureDate,
      withFlightPreorderAvailability,
      searchWithCodeShareFlightNumber,
    }
  ) {
    commit(mutations.FLIGHTS_SEARCH_CLEAR_RESULTS);
    const flightRepo = RepoFactory.get('flight');
    commit(mutations.FLIGHTS_ERROR, { error: null });
    commit(mutations.FLIGHTS_LOADING, true);
    try {
      const response = await flightRepo.getFlights({
        flightNumber,
        departureDate,
        withFlightPreorderAvailability,
        searchWithCodeShareFlightNumber,
      });
      let mutationArgs = {
        flights: response.data,
        flightNumber,
        departureDate,
      };
      commit(mutations.FLIGHTS_LOADED, mutationArgs);
    } catch (e) {
      if (!!e.response && !!e.response.data) {
        // translate server error to a generic friendly message
        commit(mutations.FLIGHTS_ERROR, { error: 'No flights found' });
      } else {
        commit(mutations.FLIGHTS_ERROR, { error: e.message });
      }
    } finally {
      commit(mutations.FLIGHTS_LOADING, false);
    }
  },
  async clearFlights({ commit, state }) {
    commit(mutations.FLIGHTS_SEARCH_CLEAR_RESULTS);
    commit(mutations.FLIGHT_CONTEXT_LIST_UPDATE, state.contextFlights);
  },

  async clearRecentlyAddedFlights({ commit }) {
    commit(mutations.FLIGHT_RECENTLY_ADDED_FLIGHTS);
  },
  async loadUserFlightByNumberAndDate(
    { commit },
    { flightNumber, departureDate }
  ) {
    const flightRepo = RepoFactory.get('flight');
    commit(mutations.USER_FLIGHTS_ERROR, { error: null });
    commit(mutations.USER_FLIGHTS_LOADING, true);
    try {
      const response = await flightRepo.getFlights({
        flightNumber,
        departureDate,
      });
      if (!!response.data && response.data.length > 0) {
        commit(mutations.USER_FLIGHTS_SET, { flights: [response.data[0]] });
      } else {
        commit(mutations.USER_FLIGHTS_ERROR, {
          error: `Flight ${flightNumber} not found in date ${departureDate}.`,
        });
      }
    } catch (e) {
      commit(mutations.USER_FLIGHTS_ERROR, { error: e.toString() });
    } finally {
      commit(mutations.USER_FLIGHTS_LOADING, false);
    }
  },

  async loadFlightBookings({ commit }, { travelerId, clearContext = true }) {
    const travelerService = await RepoFactory.get('traveler');
    commit(mutations.FLIGHT_BOOKINGS_ERROR, { error: null });
    commit(mutations.FLIGHT_BOOKINGS_LOADING, true);
    try {
      const { data } = await travelerService.getFlightBookings(travelerId);
      commit(mutations.FLIGHT_BOOKINGS_LOADED, { result: data });
      const flightModels = data.map(
        (flightBooking) => flightBooking.flightModel
      );
      const now = moment();
      const flightsCompare = (flightA, flightB) => {
        const flightATime = moment(flightA.flightModel.departureTime);
        const flightBTime = moment(flightB.flightModel.departureTime);
        return flightATime.diff(flightBTime, 'hours');
      };
      const upcomingFilter = (flight) => {
        const { flightModel } = flight;
        const flightDeparture = moment(flightModel.departureTime);
        return flightDeparture.diff(now, 'hours') > -1;
      };
      const pastFilter = (flight) => {
        const { flightModel } = flight;
        const flightDeparture = moment(flightModel.departureTime);
        return flightDeparture.diff(now, 'hours') <= -1;
      };

      const pastFlights = data.filter(pastFilter).sort(flightsCompare);
      const upcomingFlights = data.filter(upcomingFilter).sort(flightsCompare);
      if (clearContext) {
        commit(mutations.FLIGHT_CONTEXT_CLEAR);
        commit(mutations.FLIGHT_RECENTLY_ADDED_FLIGHTS);
        commit(mutations.PAST_FLIGHTS_CLEAR);
        commit(mutations.UPCOMING_FLIGHTS_CLEAR);
      }
      commit(mutations.FLIGHT_CONTEXT_APPEND_ALL, flightModels);
      commit(mutations.SET_PAST_FLIGHTS, pastFlights);
      commit(mutations.SET_UPCOMING_FLIGHTS, upcomingFlights);
    } catch (error) {
      commit(mutations.FLIGHT_BOOKINGS_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.FLIGHT_BOOKINGS_LOADING, false);
    }
  },

  async addFlightBookings({ commit }, { travelerId, flights }) {
    const travelerService = await RepoFactory.get('traveler', {
      resourceId: travelerId,
    });
    const flightsBody = flights.map((filteredFlight) => {
      return {
        flightNumber: filteredFlight.flightNumber,
        departureIata: filteredFlight.destination.iata,
        arrivalIata: filteredFlight.origin.iata,
        departureDate: filteredFlight.departureTime,
      };
    });
    commit(mutations.ADD_FLIGHT_BOOKINGS_ERROR, { error: null });
    commit(mutations.ADD_FLIGHT_BOOKINGS_LOADING, true);
    try {
      const response = await travelerService.addFlightBookings({
        flights: flightsBody,
      });
      commit(mutations.USER_FLIGHTS_ADDED, {
        flights: response.map((res) => res.data),
      });
    } catch (error) {
      commit(mutations.ADD_FLIGHT_BOOKINGS_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.ADD_FLIGHT_BOOKINGS_LOADING, false);
    }
  },

  async deleteFlightBooking({ commit }, { travelerId, flightId }) {
    const travelerService = await RepoFactory.get('traveler', {
      resourceId: travelerId,
    });
    commit(mutations.DELETE_FLIGHT_BOOKING_ERROR, { error: null });
    commit(mutations.DELETE_FLIGHT_BOOKING_LOADING, true);
    try {
      const response = await travelerService.deleteFlightBooking({
        flightId,
      });
      commit(mutations.FLIGHT_CONTEXT_REMOVE, flightId);
      commit(mutations.FLIGHT_DELETED, true);
    } catch (error) {
      commit(mutations.DELETE_FLIGHT_BOOKING_ERROR, {
        error: error.toString(),
      });
    } finally {
      commit(mutations.DELETE_FLIGHT_BOOKING_LOADING, false);
    }
  },
  appendFlightsToContext({ commit }, flightModels) {
    commit(mutations.FLIGHT_RECENTLY_ADDED_FLIGHTS);
    commit(mutations.FLIGHT_CONTEXT_APPEND_ALL, flightModels);
  },
  setSelectedFlight({ commit }, flightModel) {
    commit(mutations.FLIGHT_SELECTED_CONTEXT_SET, flightModel);
  },
  updateFlightCreationError({ commit }) {
    commit(mutations.CLEAR_FLIGHT_CREATION_ERROR, false);
  },
  clearFlightDeletion({ commit }) {
    commit(mutations.CLEAR_FLIGHT_DELETION);
  },
  setFlightDuplicateError({ commit }) {
    commit(mutations.FLIGHT_DUPLICATE_ERROR);
  },
  clearFlightDuplicateError({ commit }) {
    commit(mutations.CLEAR_FLIGHT_DUPLICATE_ERROR);
  },
  removeFlightsFromContext({ commit }, flights) {
    commit(mutations.FLIGHT_CONTEXT_REMOVE, flights);
  },
  clearSelectedFlight({ commit }) {
    commit(mutations.FLIGHT_SELECTED_CONTEXT_CLEAR);
  },
  setShowAddFlight({ commit }, value) {
    commit(mutations.SET_SHOW_ADD_FLIGHT, value);
  },
  setInvalidFlightAlert({ commit }, value) {
    commit(mutations.SET_INVALID_FLIGHT_ALERT, value);
  },
  clearInvalidFlightAlert({ commit }) {
    commit(mutations.CLEAR_INVALID_FLIGHT_ALERT, false);
  },
  cleanFlightList({ commit, state, dispatch }, { timespan, timeunit }) {
    if (timespan === '0') {
      timeunit = 'minutes';
    }

    //if time unit or span are null don't do anything
    if (!timeunit || !timespan) return;

    // only do the clean up if its been more than  MIN(one day, airline config) from last call
    let diffFromLastTimeCalledInDays = moment()
      .utc()
      .diff(moment(state.flightCleaningTimeStamp), 'day');

    let diffFromLastTimeCalledInAirlineTimeUnit = moment()
      .utc()
      .diff(moment(state.flightCleaningTimeStamp), timeunit);

    let flightsToBeArchived = [];

    if (
      state.flightCleaningTimeStamp != null &&
      diffFromLastTimeCalledInAirlineTimeUnit < timespan &&
      diffFromLastTimeCalledInDays < 1
    ) {
      commit(mutations.FLIGHT_CLEANING_TIMESTAMP_UPDATE, moment().utc());

      // archive flights based on airline config criteria
      flightsToBeArchived = state.contextFlights.filter((flight) => {
        let arrivalInUTC = moment(flight.arrivalTime)
          .utcOffset(flight.destination.utcOffsetHours, true)
          .utc();

        let diffFromNow = moment()
          .utc()
          .diff(arrivalInUTC, timeunit);
        return diffFromNow >= timespan;
      });
      commit(
        mutations.FLIGHT_ARCHIVE_LIST_UPDATE,
        state.archivedFlights.concat(flightsToBeArchived)
      );

      // remove archived flights from context flights list
      let cleanedContextFlightsList = state.contextFlights.filter((flight) => {
        let arrivalInUTC = moment(flight.arrivalTime)
          .utcOffset(flight.destination.utcOffsetHours, true)
          .utc();

        return (
          moment()
            .utc()
            .diff(arrivalInUTC, timeunit) < timespan
        );
      });
      commit(mutations.FLIGHT_CONTEXT_LIST_UPDATE, cleanedContextFlightsList);
      if (cleanedContextFlightsList.length !== state.contextFlights.length) {
        dispatch(`selectNextContextFlight`);
      }
    }
  },
  async updateContextFlightsStoreStatuses({ commit, state, rootGetters }) {
    const flightRepo = RepoFactory.get('flight');
    try {
      const updatedFlights = await flightRepo.getMultiFlights(
        state.contextFlights.map((flight) => ({
          flightNumber: flight.flightNumber,
          departureDate: flight.departureTime,
          withFlightPreorderAvailability:
            rootGetters.showFlightPreorderAvailability,
          searchWithCodeShareFlightNumber:
            rootGetters.searchFlightByCodeShareNumber,
        }))
      );
      commit(mutations.FLIGHT_CONTEXT_LIST_UPDATE, updatedFlights);
    } catch (e) {
      commit(mutations.FLIGHTS_ERROR, e);
    }
  },
  async selectNextContextFlight({ commit, state, rootState }) {
    // if selected context flight was archived or has catalog unavailable, select first upcoming flight
    const isSelectedFlightArchived =
      state.archivedFlights.filter((flight) => {
        return flight.id === state.selectedContextFlight?.id;
      }).length > 0;
    let shopOnlyShouldReselect = false;
    let flights = [...state.contextFlights];
    const updatedContextFlight = flights.find(
      (flight) => flight.id === state.selectedContextFlight?.id
    );
    if (
      rootState.shopOnlyMode &&
      updatedContextFlight &&
      updatedContextFlight.isStoreCatalogAvailableNow === false
    ) {
      flights = flights.filter(
        (flight) => !!flight && flight.isStoreCatalogAvailableNow
      );
      shopOnlyShouldReselect = true;
    }

    if (
      (!!state.selectedContextFlight && isSelectedFlightArchived) ||
      shopOnlyShouldReselect
    ) {
      const now = moment().local();
      const flight = flights.sort((a, b) => {
        let aDate = moment(a.departureTime)
          .utcOffset(a.origin.utcOffsetHours, true)
          .local();
        let bDate = moment(b.departureTime)
          .utcOffset(b.origin.utcOffsetHours, true)
          .local();
        return aDate > now.utcOffset(a.origin.utcOffsetHours, true).local() &&
          bDate > now.utcOffset(a.origin.utcOffsetHours, true).local()
          ? aDate - bDate
          : bDate - aDate;
      })[0];
      if (flight) {
        commit(mutations.FLIGHT_SELECTED_CONTEXT_SET, flight);
      }
    }
  },
  setInvalidFlights({ commit }, flights) {
    commit(mutations.SET_INVALID_FLIGHTS, flights);
  },
  setCodeShareAlert({ commit }, value) {
    commit(mutations.SET_CODE_SHARE_ALERT, value);
  },
  setCodeShareInfo({ commit }, value) {
    commit(mutations.SET_CODE_SHARE_INFO, value);
  },
};
