const prefix = '/v3';

export const prefixPath = (path) => {
  if (path.startsWith(prefix)) {
    return path;
  }
  if (path.startsWith('/')) {
    return prefix + path;
  } else {
    return `${prefix}/${path}`;
  }
};

export const isNuxtPath = (path) => {
  return path.toLowerCase().startsWith(prefix);
};

export const PathPrefixPlugin = {
  async install(Vue) {
    Vue.prototype.$pathPrefix = prefixPath;
    Vue.prototype.$isNuxtPath = isNuxtPath;
  },
};
