var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-body",class:{
    'margin-top': !_vm.isWelcomePage && !_vm.hideAppBar && !_vm.isTouchDeviceDetected,
  },style:({ backgroundImage: `url(${_vm.heroBackgroundImage})` }),attrs:{"id":"shell-top"},on:{"scroll":_vm.onScroll}},[_c('vue-headful',{attrs:{"title":_vm.pageTitle}}),(!_vm.hideAppBar)?[(!_vm.isTouchDeviceDetected)?[_c('NavigationBar',{class:{ 'is-stuck': _vm.isStuck },attrs:{"hide-menu":_vm.$route.name === 'welcome' && !_vm.hasSelectedContextFlight},on:{"openNavDrawer":_vm.openNavDrawer}}),_c('NavigationDrawer',{ref:"navDrawer"})]:_vm._e(),(
        !_vm.isConciergeV3 && _vm.isConciergeRoute && _vm.$vuetify.breakpoint.mdAndDown
      )?[_c('ConciergeHeaderTitles')]:_vm._e()]:_vm._e(),(_vm.invalidFlightAlert)?[_c('ToastComponent',{on:{"toastClosed":_vm.clearInvalidFlightAlert}})]:_vm._e(),_c('CodeShareComponent'),_c('v-main',{staticClass:"app-content",class:{
      'footer-space': !_vm.showStagesFooter,
      'margin-top':
        _vm.$route.name === 'welcome' && _vm.hasSelectedContextFlight && !_vm.hideAppBar,
      'pb-10': _vm.needsPadding && _vm.$vuetify.breakpoint.smAndDown,
      'is-product-page': _vm.isProductPage,
      overlay: _vm.isOpenFlightSelector,
    }},[_c('router-view')],1),(!_vm.isConciergeV3 && _vm.showStagesFooter && !!_vm.hasSelectedContextFlight)?[_c('FooterConciergeStages',{class:{ overlay: _vm.isOpenFlightSelector }})]:_vm._e(),(_vm.showFadedFooter)?[_c('ConciergeFooter',{class:{ overlay: _vm.isOpenFlightSelector }})]:[_c('AppFooter',{class:{ overlay: _vm.isOpenFlightSelector }})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }